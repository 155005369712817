import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useDatedEvidence } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { PasswordReset } from '../../../common/models';
import { PasswordResetFields } from './password-resets-fields';
import { useColumns } from './password-resets-columns';
import { useValidationSchema } from './password-resets-schema';

export function OperatorPasswordResets() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useDatedEvidence<PasswordReset>({
    version: 2,
    identifier: 'PASSWORD_RESETS',
    apiProps: {
      url: '/api/ks/password-resets',
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'KS_O_PASSWORD_RESETS_TABLE_TITLE',
        defaultMessage: 'Žádosti o reset hesla',
      }),
      reportTag: ExportTags.PASSWORD_RESET_GRID,
    },
    detailProps: {
      FieldsComponent: PasswordResetFields,
      validationSchema,
      toolbarProps: {
        showButton: () => false,
      },
    },
  });

  return <Evidence {...evidence} />;
}
