export enum ExportTags {
  TICKET_GRID = 'TICKET_GRID',
  TICKET_DETAIL = 'TICKET_DETAIL',

  FAQ_GRID = 'FAQ_GRID',
  FAQ_DETAIL = 'FAQ_DETAIL',

  ACTIVITY_GRID = 'ACTIVITY_GRID',
  ACTIVITY_DETAIL = 'ACTIVITY_DETAIL',

  NOTICE_GRID = 'NOTICE_GRID',
  NOTICE_DETAIL = 'NOTICE_DETAIL',

  ORGANISATION_TYPE_GRID = 'ORGANISATION_TYPE_GRID',
  ORGANISATION_TYPE_DETAIL = 'ORGANISATION_TYPE_DETAIL',

  PROJECT_PHASE_GRID = 'PROJECT_PHASE_GRID',
  PROJECT_PHASE_DETAIL = 'PROJECT_PHASE_DETAIL',

  REGION_GRID = 'REGION_GRID',
  REGION_DETAIL = 'REGION_DETAIL',

  TAG_GRID = 'TAG_GRID',
  TAG_DETAIL = 'TAG_DETAIL',

  PRIORITY_TARGET_GRID = 'PRIORITY_TARGET_GRID',
  PRIORITY_TARGET_DETAIL = 'PRIORITY_TARGET_DETAIL',

  SPECIFIC_TARGET_GRID = 'SPECIFIC_TARGET_GRID',
  SPECIFIC_TARGET_DETAIL = 'SPECIFIC_TARGET_DETAIL',

  OPERATOR_GRID = 'OPERATOR_GRID',
  OPERATOR_DETAIL = 'OPERATOR_DETAIL',

  REQUESTER_GRID = 'REQUESTER_GRID',
  REQUESTER_DETAIL = 'REQUESTER_DETAIL',

  REGISTRATION_GRID = 'REGISTRATION_GRID',
  REGISTRATION_DETAIL = 'REGISTRATION_DETAIL',

  PASSWORD_RESET_GRID = 'PASSWORD_RESET_GRID',
  PASSWORD_RESET_DETAIL = 'PASSWORD_RESET_DETAIL',

  RULE_GRID = 'RULE_GRID',
  RULE_DETAIL = 'RULE_DETAIL',

  EXPORT_TEMPLATE_GRID = 'EXPORT_TEMPLATE_GRID',
  EXPORT_TEMPLATE_DETAIL = 'EXPORT_TEMPLATE_DETAIL',

  EXPORT_REQUEST_GRID = 'EXPORT_REQUEST_GRID',
  EXPORT_REQUEST_DETAIL = 'EXPORT_REQUEST_DETAIL',

  NOTIFICATION_TEMPLATE_GRID = 'NOTIFICATION_TEMPLATE_GRID',
  NOTIFICATION_TEMPLATE_DETAIL = 'NOTIFICATION_TEMPLATE_DETAIL',

  SEQUENCE_GRID = 'SEQUENCE_GRID',
  SEQUENCE_DETAIL = 'SEQUENCE_DETAIL',

  TRANSLATION_GRID = 'TRANSLATION_GRID',
  TRANSLATION_DETAIL = 'TRANSLATION_DETAIL',

  SCHEDULE_JOBS_GRID = 'SCHEDULE_JOBS_GRID',
  SCHEDULE_JOBS_DETAIL = 'SCHEDULE_JOBS_DETAIL',

  SCHEDULE_RUNS_GRID = 'SCHEDULE_RUNS_GRID',
  SCHEDULE_RUNS_DETAIL = 'SCHEDULE_RUNS_DETAIL',

  EXPORT_ACCESS_RULES_GRID = 'EXPORT_ACCESS_RULES_GRID',
  EXPORT_ACCESS_RULES_DETAIL = 'EXPORT_ACCESS_RULES_DETAIL',
}
