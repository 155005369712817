import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormTextField, FormCheckbox, FormPanel } from '@eas/common-web';

export function PasswordResetFields() {
  const intl = useIntl();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="KS_O_PASSWORD_RESETS_FIELD_PANEL_TITLE"
          defaultMessage="Žádost o reset hesla"
        />
      }
    >
      <FormTextField
        name="email"
        required
        label={
          <FormattedMessage
            id="KS_O_PASSWORD_RESETS_FIELD_LABEL_EMAIL"
            defaultMessage="Email"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_PASSWORD_RESETS_FIELD_HELP_EMAIL',
          defaultMessage: ' ',
        })}
      />
      <FormCheckbox
        name="operator"
        disabled
        label={
          <FormattedMessage
            id="KS_O_PASSWORD_RESETS_FIELD_LABEL_OPERATOR"
            defaultMessage="Operátor"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_PASSWORD_RESETS_FIELD_HELP_OPERATOR',
          defaultMessage: ' ',
        })}
      />
      <FormCheckbox
        name="confirmed"
        disabled
        label={
          <FormattedMessage
            id="KS_O_PASSWORD_RESETS_FIELD_LABEL_CONFIRMED"
            defaultMessage="Potvrzena"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_PASSWORD_RESETS_FIELD_HELP_CONFIRMED',
          defaultMessage: ' ',
        })}
      />
    </FormPanel>
  );
}
