import {
  DomainObject,
  DictionaryObject,
  AuthoredObject,
  DatedObject,
  FileRef,
  AppSettings,
  ExportTemplate,
} from '@eas/common-web';

export type Activity = DictionaryObject;

export type PriorityTarget = DictionaryObject;
export type OrganisationType = DictionaryObject;
export type ProjectPhase = DictionaryObject;
export type Region = DictionaryObject;
export type Tag = DictionaryObject;

export interface SpecificTarget extends DictionaryObject {
  parent?: PriorityTarget;
}

export interface Notice extends DomainObject {
  name: string;
  parent?: SpecificTarget;
}

export interface Operator extends AuthoredObject {
  active?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  phone?: string;
  organisationName?: string;
  organisationIc?: string;
  externalId?: string;
}

export interface Rule extends AuthoredObject {
  order?: number;
  regions?: Region[];
  specificTargets?: SpecificTarget[];
  activities?: Activity[];
  notices?: Notice[];
  assignee: Operator;
}

export interface Faq extends AuthoredObject {
  question: string;
  answer: string;
  projectPhases?: ProjectPhase[];
  regions?: Region[];
  specificTargets?: SpecificTarget[];
  notices?: Notice[];
  activities?: Activity[];
  tags?: Tag[];
  published?: boolean;
  publisher?: Operator;
  publishedDated?: string;
  source?: Ticket;
}

export interface FaqExternalDetail {
  publishedDate: string;
  id: string;
  question: string;
  answer: string;
  projectPhases?: ProjectPhase[];
  regions?: Region[];
  specificTargets?: SpecificTarget[];
  notices?: Notice[];
  activities?: Activity[];
  tags?: Tag[];
}

export enum TicketState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  RESOLVED = 'RESOLVED',
  RETURNED = 'RETURNED',
}

export interface PasswordReset extends DatedObject {
  email?: string;
  secret?: string;
}

export interface Registration extends DatedObject {
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  phone?: string;
  organisationName?: string;
  organisationIc?: string;
  confirmed?: boolean;
  secret?: string;
}

export interface RegistrationExternal {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  organisationName: string;
  organisationIc: string;
  password: string;
  passwordAgain: string;
}

export interface RegistrationDto {
  registration: RegistrationExternal;
  captcha: string;
}

export interface LoginData {
  username: string;
  password: string;
}

export interface Requester extends AuthoredObject {
  active?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  phone?: string | null;
  organisationName?: string;
  organisationIc?: string;
  flag: string;
}

export interface Ticket extends AuthoredObject {
  identifier?: string;
  question?: string;
  note?: string;
  answer?: string;
  resolveDate?: string;
  resolveName?: string;
  organisationType?: OrganisationType;
  projectPhase?: ProjectPhase;
  region?: Region;
  specificTarget?: SpecificTarget;
  notice?: Notice;
  activity?: Activity;
  tags?: Tag[];
  files?: FileRef[];
  parent?: TicketSimple;

  state?: TicketState;
  consulted?: boolean;
  projectNumber?: string;
  projectName?: string;
  place?: string;

  requester?: Requester;
  owner?: Operator;
  consultants?: Operator[];

  formatting: boolean;

  field1?: string | null;
  field2?: string | null;
  field3?: string | null;
  field4?: string | null;
}

export interface TicketExternalList extends AuthoredObject {
  state: TicketState;
  organisationType?: OrganisationType;
  projectPhase?: ProjectPhase;
  region?: Region;
  notice?: Notice;
  specificTarget?: SpecificTarget;
  projectNumber?: string;
  projectName?: string;
  place?: string;
  question?: string;
  plainAnswer?: string;
}

export interface TicketSimple {
  identifier: string;
  question: string;
  answer: string;
  formatting: boolean;
}

export interface TicketExternalCreate {
  question: string;
  projectNumber?: string;
  projectName?: string;
  organisationType?: OrganisationType;
  projectPhase?: ProjectPhase;
  region?: Region;
  place?: string;
  notice?: Notice;
  specificTarget?: SpecificTarget;
  activity?: Activity;
  parent: TicketSimple;

  field1?: string | null;
  field2?: string | null;
  field3?: string | null;
  field4?: string | null;

  formatting?: boolean;
}

export interface TicketExternalDetail extends AuthoredObject {
  state: TicketState;
  question: string;
  projectNumber?: string;
  projectName?: string;
  organisationType?: OrganisationType;
  projectPhase?: ProjectPhase;
  region?: Region;
  place?: string;
  notice?: Notice;
  specificTarget?: SpecificTarget;
  activity?: Activity;
  parent: TicketSimple;

  field1?: string | null;
  field2?: string | null;
  field3?: string | null;
  field4?: string | null;
}

export interface TicketExternalUpdate extends AuthoredObject {
  question: string;
  projectNumber?: string;
  projectName?: string;
  organisationType?: OrganisationType;
  projectPhase?: ProjectPhase;
  place?: string;

  field1?: string | null;
  field2?: string | null;
  field3?: string | null;
  field4?: string | null;
}

export interface TicketComment extends AuthoredObject {
  fromOperator: boolean;
  internal: boolean;
  system: boolean;
  text: string;
}

export interface TicketCommentExternal extends AuthoredObject {
  fromOperator: boolean;
  text: string;
}

export interface Profile extends ProfileUpdate {
  email: string;
}

export interface ProfileUpdate {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  organisationIc?: string;
  organisationName?: string;
}

export interface PasswordChangeDto {
  oldPassword: string;
  newPassword: string;
}

export interface PasswordResetRequest {
  email: string;
  captcha: string;
  operator: boolean;
}

export interface PasswordResetComplete {
  newPassword: string;
  newPasswordAgain: string;
}

export enum NotificationEvent {
  NEW_TICKET = 'NEW_TICKET',
  ASSIGNED_TICKET = 'ASSIGNED_TICKET',
  REMOVED_TICKET = 'REMOVED_TICKET',
  RETURNED_TICKET = 'RETURNED_TICKET',
  AMENDED_TICKET = 'AMENDED_TICKET',
  RESOLVED_TICKET = 'RESOLVED_TICKET',
  RESOLVED_AGAIN_TICKET = 'RESOLVED_AGAIN_TICKET',
  ADD_CONSULTANT = 'ADD_CONSULTANT',
  REMOVE_CONSULTANT = 'REMOVE_CONSULTANT',
  REASSIGNED_TICKET = 'REASSIGNED_TICKET',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
  CONFIRM_REGISTRATION = 'CONFIRM_REGISTRATION',
  COMMENT_ADDED = 'COMMENT_ADDED',
  COMMENT_ADDED_REQUESTER = 'COMMENT_ADDED_REQUESTER',
}

export interface NotificationTemplate extends AuthoredObject {
  name: string;
  subject: string;
  content: string;
  formatted: boolean;
  event: NotificationEvent;
}

interface KsAppSettingsField {
  enabled?: boolean;
  required?: boolean;
  public?: boolean;
  labelId?: string;
  labelDefault?: string;
  helpId?: string;
  helpDefault?: string;
}

export interface KsAppSettings extends AppSettings {
  fields?: Array<KsAppSettingsField | null | undefined>;
  respondDeadline: number; // days
}

export interface KsExportAccessRule extends AuthoredObject {
  template: ExportTemplate;
  permissions: string[];
}
