import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, FormTextField, FormSubmitButton } from '@eas/common-web';
import { Profile } from '../../../common/models';
import { useProfile } from './profile-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { PasswordChangeDialog } from './password-change-dialog';
import { PublicPage } from '../../../components/public-page/public-page';
import { PersonalEvents } from '../../../common/personal-events/personal-events';
import { KsPublicFormItem } from '../../public/components/ks-public-form-item/ks-public-form-item';
import { useStyles } from './profile-styles';

export function RequesterProfilePage() {
  const intl = useIntl();

  const {
    loading,
    editing,
    ref,
    pwdChangeDialog,
    validationSchema,
    handleSubmit,
    handleCancelEditing,
    handleStartEditing,
    handlePwdChange,
  } = useProfile();

  const classes = useStyles();

  return (
    <PublicPage title={'Profil'}>
      <Form<Profile>
        editing={!loading && editing}
        initialValues={{} as any}
        onSubmit={handleSubmit}
        ref={ref}
        validationSchema={validationSchema as any}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="firstName"
              helpLabel={intl.formatMessage({
                id: 'KS_R_PROFILE_FIELD_HELP_FIRST_NAME',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_PROFILE_FIELD_LABEL_FIRST_NAME"
                  defaultMessage="Jméno"
                />
              }
            >
              <FormTextField required name="firstName" />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="lastName"
              helpLabel={intl.formatMessage({
                id: 'KS_R_PROFILE_FIELD_HELP_LAST_NAME',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_PROFILE_FIELD_LABEL_LAST_NAME"
                  defaultMessage="Příjmení"
                />
              }
            >
              <FormTextField required name="lastName" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="organisationName"
              helpLabel={intl.formatMessage({
                id: 'KS_R_PROFILE_FIELD_HELP_ORGANISATION_NAME',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_PROFILE_FIELD_LABEL_ORGANISATION_NAME"
                  defaultMessage="Organizace"
                />
              }
            >
              <FormTextField required name="organisationName" />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="organisationIc"
              helpLabel={intl.formatMessage({
                id: 'KS_R_PROFILE_FIELD_HELP_ORGANISATION_IC',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_PROFILE_FIELD_LABEL_ORGANISATION_IC"
                  defaultMessage="IČO"
                />
              }
            >
              <FormTextField required name="organisationIc" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              name="phone"
              helpLabel={intl.formatMessage({
                id: 'KS_R_PROFILE_FIELD_HELP_PHONE',
                defaultMessage:
                  'Vložte číslo v mezinárodním formátu +420xxxxxxxxx',
              })}
              label={
                <FormattedMessage
                  id="KS_R_PROFILE_FIELD_LABEL_PHONE"
                  defaultMessage="Telefon"
                />
              }
            >
              <FormTextField name="phone" />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              name="email"
              helpLabel={intl.formatMessage({
                id: 'KS_R_PROFILE_FIELD_HELP_EMAIL',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_PROFILE_FIELD_LABEL_EMAIL"
                  defaultMessage="Email"
                />
              }
            >
              <FormTextField disabled name="email" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box display="flex" flexDirection="row-reverse">
              {!editing && (
                <>
                  <Button
                    variant="contained"
                    disabled={loading}
                    onClick={handlePwdChange}
                    className={classes.button}
                    disableElevation
                  >
                    <FormattedMessage
                      id="KS_R_PROFILE_BTN_PW_CHANGE"
                      defaultMessage="Změnit heslo"
                    />
                  </Button>
                  <Box width={10} />
                </>
              )}
              {!editing && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.button}
                    onClick={handleStartEditing}
                    disableElevation
                  >
                    <FormattedMessage
                      id="KS_R_PROFILE_BTN_EDIT"
                      defaultMessage="Upravit"
                    />
                  </Button>
                  <Box width={10} />
                </>
              )}
              {editing && (
                <>
                  <Button
                    variant="contained"
                    disabled={loading}
                    onClick={handleCancelEditing}
                    className={classes.button}
                    disableElevation
                  >
                    <FormattedMessage
                      id="KS_R_PROFILE_BTN_CANCEL"
                      defaultMessage="Zrušit"
                    />
                  </Button>
                  <Box width={10} />
                </>
              )}
              {editing && (
                <>
                  <FormSubmitButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    className={classes.button}
                    startIcon={
                      loading && (
                        <CircularProgress size="20px" color="inherit" />
                      )
                    }
                    disableElevation
                  >
                    <FormattedMessage
                      id="KS_R_PROFILE_BTN_SAVE"
                      defaultMessage="Uložit"
                    />
                  </FormSubmitButton>
                  <Box width={10} />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Form>
      <h2>
        <FormattedMessage id="KS_R_PERSONAL_EVENTS" defaultMessage="Události" />
      </h2>
      <PersonalEvents />

      <PasswordChangeDialog ref={pwdChangeDialog} />
    </PublicPage>
  );
}
