import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormPanel,
  FormTextField,
  FormEditor,
  FormCheckbox,
  FormSelect,
  useFormSelector,
} from '@eas/common-web';
import { useNotificationEvents } from './notification-templates-api';
import { NotificationTemplate } from '../../../common/models';

export function NotificationTemplatesFields() {
  const intl = useIntl();
  const formatted = useFormSelector(
    (data: NotificationTemplate) => data.formatted
  );

  const events = useNotificationEvents();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="KS_O_NOTICFICATION_TEMPLATES_FIELD_PANEL_TITLE"
          defaultMessage="Šablona notifikací"
        />
      }
    >
      <FormTextField
        name="name"
        required
        label={
          <FormattedMessage
            id="KS_O_NOTICFICATION_TEMPLATES_FIELD_LABEL_NAME"
            defaultMessage="Název"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_NOTICFICATION_TEMPLATES_FIELD_HELP_NAME',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="subject"
        required
        label={
          <FormattedMessage
            id="KS_O_NOTICFICATION_TEMPLATES_FIELD_LABEL_SUBJECT"
            defaultMessage="Nadpis"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_NOTICFICATION_TEMPLATES_FIELD_HELP_SUBJECT',
          defaultMessage: ' ',
        })}
      />
      <FormEditor
        name="content"
        required
        language={formatted ? 'html' : 'text'}
        label={
          <FormattedMessage
            id="KS_O_NOTICFICATION_TEMPLATES_FIELD_LABEL_CONTENT"
            defaultMessage="Text"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_NOTICFICATION_TEMPLATES_FIELD_HELP_CONTENT',
          defaultMessage: ' ',
        })}
      />
      <FormCheckbox
        name="formatted"
        required
        label={
          <FormattedMessage
            id="KS_O_NOTICFICATION_TEMPLATES_FIELD_LABEL_FORMATTED"
            defaultMessage="HTML šablona"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_NOTICFICATION_TEMPLATES_FIELD_HELP_FORMATTED',
          defaultMessage: ' ',
        })}
      />
      <FormSelect
        name="event"
        required
        label={
          <FormattedMessage
            id="KS_O_NOTICFICATION_TEMPLATES_FIELD_LABEL_EVENT"
            defaultMessage="Událost"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_NOTICFICATION_TEMPLATES_FIELD_HELP_EVENT',
          defaultMessage: ' ',
        })}
        source={events}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
      />
      <FormCheckbox
        name="active"
        label={
          <FormattedMessage
            id="KS_O_NOTICFICATION_TEMPLATES_FIELD_LABEL_ACTIVE"
            defaultMessage="Aktivní"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_NOTICFICATION_TEMPLATES_FIELD_HELP_ACTIVE',
          defaultMessage: ' ',
        })}
      />
    </FormPanel>
  );
}
