import React, { useContext } from 'react';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { LocaleContext, UserContext } from '@eas/common-web';
import { CommentProp } from '../../tickets-types';
import { useStyles } from '../../tickets-styles';

export function TicketComment(props: CommentProp) {
  const { user } = useContext(UserContext);
  const { locale } = useContext(LocaleContext);
  const classes = useStyles();

  const comment = props.comment;

  const date = comment.created
    ? format(parseISO(comment.created), locale.dateTimeFormat)
    : '';
  const ownComment = comment.createdBy?.id === user?.id;
  const fromOperator = comment.fromOperator;

  const title = (
    <>
      {fromOperator ? (
        <>
          <Box display="inline" fontWeight={ownComment ? 700 : 500}>
            Řešitel
          </Box>
          &nbsp;
        </>
      ) : (
        <>
          <Box display="inline" fontWeight={ownComment ? 700 : 500}>
            {comment.createdBy?.name}
          </Box>
          &nbsp;
        </>
      )}
      <Box display="inline">({date})</Box>
    </>
  );

  return (
    <Grid item xs={12}>
      <Card
        className={clsx(classes.comment, {
          [classes.commentFromOperator]: fromOperator,
        })}
        raised={true}
      >
        <CardHeader
          title={title}
          titleTypographyProps={{ className: classes.commentHeaderText }}
        />
        <CardContent>
          <Typography className={classes.typography}>{comment.text}</Typography>
        </CardContent>
      </Card>
      <Box height="10px" />
    </Grid>
  );
}
