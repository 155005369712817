import React, { RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CrudSource,
  DialogHandle,
  FormSubmitButton,
  ListSource,
} from '@eas/common-web';
import {
  TicketExternalDetail,
  TicketState,
  TicketCommentExternal,
} from '../../../../common/models';
import { RequesterPanel } from '../panels/requester-panel';
import { ProjectPanel } from '../panels/project-panel';
import { QuestionPanel } from '../panels/question-panel';
import { AnswerPanel } from '../panels/answer-panel';
import { FilesPanel } from '../panels/files-panel';
import { StatePanel } from '../panels/state-panel';
import { CommentsPanel } from '../panels/comments-panel';
import { ParentPanel } from '../panels/parent-panel';
import { usePrintSingleHook } from '../tickets-print-hook';

export function RequesterTicketsEditFields({
  id,
  editing,
  loading,
  source,
  commentDialog,
  commentsSource,
  handleCommentClick,
  handleEditClick,
  handleAmendClick,
  handleFollowupClick,
}: {
  id: string;
  editing: boolean;
  loading: boolean;
  source: CrudSource<TicketExternalDetail>;
  commentDialog: RefObject<DialogHandle>;
  commentsSource: ListSource<TicketCommentExternal>;
  handleOrgTypeChange: () => void;
  handleCommentClick: () => void;
  handleEditClick: () => void;
  handleAmendClick: () => void;
  handleFollowupClick: () => void;
}) {
  const { print, printing } = usePrintSingleHook(id);

  return (
    <Box display="flex" flexDirection="column">
      <ParentPanel />
      <StatePanel existing={true} />
      <RequesterPanel />
      <ProjectPanel />
      <QuestionPanel />
      <AnswerPanel />
      <FilesPanel />
      <CommentsPanel
        id={id}
        commentDialog={commentDialog}
        commentsSource={commentsSource}
      />
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12}>
          <Box display="flex" flexDirection="row-reverse">
            {/* upravit dotaz se da, pokud je novy nebo vraceny na doplneni  */}
            {!editing &&
              (source.data?.state === TicketState.NEW ||
                source.data?.state === TicketState.RETURNED) && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditClick}
                    disabled={loading}
                    disableElevation
                    startIcon={
                      loading && (
                        <CircularProgress size="20px" color="inherit" />
                      )
                    }
                  >
                    <FormattedMessage
                      id="KS_R_TICKETS_EDIT_BTN_EDIT"
                      defaultMessage="Upravit"
                    />
                  </Button>
                  <Box width={10} />
                </>
              )}
            {!editing && source.data?.state === TicketState.RETURNED && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleAmendClick}
                  disabled={loading}
                  disableElevation
                  startIcon={
                    loading && <CircularProgress size="20px" color="inherit" />
                  }
                >
                  <FormattedMessage
                    id="KS_R_TICKETS_BTN_AMEND"
                    defaultMessage="Doplnit"
                  />
                </Button>
                <Box width={10} />
              </>
            )}

            {/* ulozit zmeny se da jenom pokud jsme v editacnim rezimu */}
            {editing && (
              <>
                <FormSubmitButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  disabled={loading}
                  startIcon={
                    loading && <CircularProgress size="20px" color="inherit" />
                  }
                >
                  <FormattedMessage
                    id="KS_R_TICKETS_BTN_SAVE"
                    defaultMessage="Uložit"
                  />
                </FormSubmitButton>
                <Box width={10} />
              </>
            )}
            {/* komentovat se da, kdyz nejsme v editacnim rezimu a dotaz neni zodpovezen ani ve stavu vracen k doplneni */}
            {!editing &&
              source.data?.state !== TicketState.RESOLVED &&
              source.data?.state !== TicketState.RETURNED && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCommentClick}
                    disabled={loading}
                    disableElevation
                    startIcon={
                      loading && (
                        <CircularProgress size="20px" color="inherit" />
                      )
                    }
                  >
                    <FormattedMessage
                      id="KS_R_TICKETS_BTN_COMMENT"
                      defaultMessage="Komentovat"
                    />
                  </Button>
                  <Box width={10} />
                </>
              )}
            {/* založit navazující dotaz se dá, kdyz nejsme v editacnim rezimu a dotaz je zodpovezen */}
            {!editing && source.data?.state === TicketState.RESOLVED && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleFollowupClick}
                  disabled={loading}
                  disableElevation
                  startIcon={
                    loading && <CircularProgress size="20px" color="inherit" />
                  }
                >
                  <FormattedMessage
                    id="KS_R_TICKETS_BTN_FOLLOWUP"
                    defaultMessage="Navazující dotaz"
                  />
                </Button>
                <Box width={10} />
              </>
            )}
            {/* export dotazu se dá, kdyz nejsme v editacnim rezimu */}
            {!editing && (
              <>
                <Button
                  variant="contained"
                  onClick={print}
                  disabled={printing}
                  disableElevation
                  startIcon={
                    loading && <CircularProgress size="20px" color="inherit" />
                  }
                >
                  <FormattedMessage
                    id="KS_R_TICKETS_BTN_EXPORT"
                    defaultMessage="Export"
                  />
                </Button>
                <Box width={10} />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
