import React, { forwardRef, useRef, useState, useContext } from 'react';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DialogHandle,
  Dialog,
  useEventCallback,
  Form,
  FormHandle,
  SnackbarVariant,
  SnackbarContext,
  FormTextField,
  FormContext,
  useDefaultValidationMessages,
} from '@eas/common-web';
import { NewRequesterDialogProps } from './tickets-types';
import Grid from '@material-ui/core/Grid';
import { Requester } from '../../../common/models';
import { useStyles } from './tickets-styles';
import { createRequester } from './tickets-api';
import { validateEmail } from '../requesters/requesters-api';

export const NewRequesterDialog = forwardRef<
  DialogHandle,
  NewRequesterDialogProps
>(function NewRequesterDialog({}, dialogRef) {
  const classes = useStyles();
  const intl = useIntl();
  const { meetsFormat, doesNotExist } = useDefaultValidationMessages();
  const { showSnackbar } = useContext(SnackbarContext);
  const { setFieldValue } = useContext(FormContext);

  const ref = useRef<FormHandle<Requester>>(null);
  const [loading, setLoading] = useState(false);

  const onConfirm = useEventCallback(async () => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_O_TICKETS_NEW_REQUESTER_DIALOG_MSG_VALIDATION',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });

        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        setLoading(false);
        return false;
      }

      ref.current.submitForm();
    }
  });

  const handleSubmit = useEventCallback(async (values: Requester) => {
    try {
      setLoading(true);

      const requester: Requester = await createRequester(values).json();
      setFieldValue('requester', requester);

      const message = intl.formatMessage({
        id: 'KS_O_TICKETS_NEW_REQUESTER_DIALOG_MSG_SUCCESS',
        defaultMessage: 'Tazatel byl založen',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
      setLoading(false);
    } catch (err) {
      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_O_TICKETS_NEW_REQUESTER_DIALOG_MSG_ERROR',
            defaultMessage: 'Chyba založení tazatele: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);
        setLoading(false);

        throw err;
      }
    }
  });

  const emailSchema = Yup.string().nullable().required().email(meetsFormat);

  const validationSchema = Yup.object<Requester>().shape({
    firstName: Yup.string().nullable().required(),
    lastName: Yup.string().nullable().required(),
    email: emailSchema.test('email_test', doesNotExist, async function (value) {
      if (emailSchema.validateSync(value)) {
        const fetch = validateEmail(value!);
        return await fetch.json();
      } else {
        return true;
      }
    }),
    organisationName: Yup.string().nullable().required(),
    organisationIc: Yup.string().nullable().required(),
  });

  return (
    <Dialog
      ref={dialogRef}
      loading={loading}
      title={
        <FormattedMessage
          id="KS_O_TICKETS_NEW_REQUESTER_DIALOG_TITLE"
          defaultMessage="Nový tazatel"
        />
      }
      onConfirm={onConfirm}
    >
      {() => (
        <Form<Requester>
          editing={!loading}
          initialValues={{} as any}
          onSubmit={handleSubmit}
          ref={ref}
          validationSchema={validationSchema}
        >
          <Grid container className={classes.actionSimpleDialogWrapper}>
            <Grid item xs={12} sm={12}>
              <FormTextField
                name="email"
                required
                label={
                  <FormattedMessage
                    id="KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_EMAIL"
                    defaultMessage="Email"
                  />
                }
                helpLabel={intl.formatMessage({
                  id: 'KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_HELP_EMAIL',
                  defaultMessage: ' ',
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormTextField
                name="firstName"
                required
                label={
                  <FormattedMessage
                    id="KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_FIRST_NAME"
                    defaultMessage="Jméno"
                  />
                }
                helpLabel={intl.formatMessage({
                  id: 'KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_HELP_FIRST_NAME',
                  defaultMessage: ' ',
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormTextField
                name="lastName"
                required
                label={
                  <FormattedMessage
                    id="KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_LAST_NAME"
                    defaultMessage="Příjmení"
                  />
                }
                helpLabel={intl.formatMessage({
                  id: 'KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_HELP_LAST_NAME',
                  defaultMessage: ' ',
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormTextField
                name="phone"
                label={
                  <FormattedMessage
                    id="KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_PHONE"
                    defaultMessage="Telefon"
                  />
                }
                helpLabel={intl.formatMessage({
                  id: 'KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_HELP_PHONE',
                  defaultMessage: ' ',
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormTextField
                name="organisationName"
                required
                label={
                  <FormattedMessage
                    id="KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_ORGANISATION_NAME"
                    defaultMessage="Název organizace"
                  />
                }
                helpLabel={intl.formatMessage({
                  id:
                    'KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_HELP_ORGANISATION_NAME',
                  defaultMessage: ' ',
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormTextField
                name="organisationIc"
                required
                label={
                  <FormattedMessage
                    id="KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_ORGANISATION_IC"
                    defaultMessage="IČ organizace"
                  />
                }
                helpLabel={intl.formatMessage({
                  id:
                    'KS_O_TICKETS_NEW_REQUESTER_DIALOG_FIELD_HELP_ORGANISATION_IC',
                  defaultMessage: ' ',
                })}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Dialog>
  );
});
