import { abortableFetch } from '@eas/common-web';
import { PasswordResetRequest } from '../../../common/models';

/**
 * Calls registration API.
 *
 * @param item Object to save
 */
export function sendPasswordResetRequest(dto: PasswordResetRequest) {
  return abortableFetch('/api/ks/public/password-reset', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(dto),
  });
}
