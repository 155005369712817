import React, { useContext } from 'react';
import { LocaleContext, useEventCallback, LocaleName } from '@eas/common-web';
import Button from '@material-ui/core/Button';
import { useStyles } from './operator-app-styles';

export function OperatorLang() {
  const classes = useStyles();
  const { locale, switchLocale } = useContext(LocaleContext);

  const handleCzClick = useEventCallback(() => {
    switchLocale(LocaleName.cs);
  });

  const handleEnClick = useEventCallback(() => {
    switchLocale(LocaleName.en);
  });

  return (
    <div className={classes.langItem}>
      <Button
        size="small"
        variant={locale.name === LocaleName.cs ? 'contained' : undefined}
        color={locale.name === LocaleName.cs ? 'primary' : undefined}
        className={classes.langButton}
        onClick={handleCzClick}
      >
        CZ
      </Button>
      <Button
        size="small"
        variant={locale.name === LocaleName.en ? 'contained' : undefined}
        color={locale.name === LocaleName.en ? 'primary' : undefined}
        className={classes.langButton}
        onClick={handleEnClick}
      >
        EN
      </Button>
    </div>
  );
}
