import * as Yup from 'yup';
import { Operator } from '../../../common/models';
import { useDefaultValidationMessages } from '@eas/common-web';

export function useValidationSchema() {
  const { meetsFormat } = useDefaultValidationMessages();

  return Yup.object<Operator>().shape({
    firstName: Yup.string().nullable().required(),
    lastName: Yup.string().nullable().required(),
    email: Yup.string().nullable().required().email(meetsFormat),
  });
}
