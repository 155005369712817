import React from 'react';
import { Form } from '@eas/common-web';
import { KsAppSettings } from '../../../../common/models';
import { useAppSettings } from './app-settings-hook';
import { AppSettingsFields } from './app-settings-fields';
import { useStyles } from './app-settings-styles';

export function AppSettingsPage() {
  const {
    loading,
    editing,
    ref,
    validationSchema,
    handleSubmit,
    handleCancelEditing,
    handleStartEditing,
  } = useAppSettings();

  const classes = useStyles();

  return (
    <div className={classes.wraper}>
      <Form<KsAppSettings>
        editing={!loading && editing}
        initialValues={{} as any}
        onSubmit={handleSubmit}
        ref={ref}
        validationSchema={validationSchema as any}
      >
        <AppSettingsFields
          loading={loading}
          editing={editing}
          handleStartEditing={handleStartEditing}
          handleCancelEditing={handleCancelEditing}
        />
      </Form>
    </div>
  );
}
