import React, { forwardRef, useRef, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
  DialogHandle,
  Dialog,
  useEventCallback,
  Form,
  FormHandle,
  FormTextArea,
  SnackbarVariant,
  SnackbarContext,
} from '@eas/common-web';
import { Amend, AmendDialogProps } from '../../tickets-types';
import { useStyles } from '../../tickets-styles';
import { amend } from '../../tickets-api';

export const AmendDialog = forwardRef<DialogHandle, AmendDialogProps>(
  function AmendDialog({ id, source, commentsSource }, dialogRef) {
    const classes = useStyles();
    const intl = useIntl();
    const { showSnackbar } = useContext(SnackbarContext);

    const ref = useRef<FormHandle<Amend>>(null);
    const [loading, setLoading] = useState(false);

    const onConfirm = useEventCallback(() => {
      ref.current?.submitForm();
    });

    const handleSubmit = useEventCallback(async (values: Amend) => {
      try {
        setLoading(true);

        await amend(id!, values).none();

        const message = intl.formatMessage({
          id: 'KS_R_TICKETS_AMEND_DIALOG_MSG_SUCCESS',
          defaultMessage: 'Komentář byl odeslán',
        });

        showSnackbar(message, SnackbarVariant.SUCCESS);
        setLoading(false);

        source.reset();
        commentsSource.reset();
      } catch (err) {
        if (err.name !== 'AbortError') {
          const message = intl.formatMessage(
            {
              id: 'KS_R_TICKETS_AMEND_DIALOG_MSG_ERROR',
              defaultMessage: 'Chyba doplnění: {detail}',
            },
            { detail: err.message }
          );

          showSnackbar(message, SnackbarVariant.ERROR);
          setLoading(false);

          throw err;
        }
      }
    });

    return (
      <Dialog
        ref={dialogRef}
        loading={loading}
        title={
          <FormattedMessage
            id="KS_R_TICKETS_AMEND_DIALOG_TITLE"
            defaultMessage="Doplnit dotaz"
          />
        }
        onConfirm={onConfirm}
      >
        {() => (
          <Form<Amend>
            editing={!loading}
            initialValues={{ text: '' }}
            onSubmit={handleSubmit}
            ref={ref}
          >
            <Grid container className={classes.commentDialogWrapper}>
              <Grid item xs={12} sm={12}>
                <Typography>
                  <FormattedMessage
                    id="KS_R_TICKETS_AMEND_DIALOG_SUBTITLE"
                    defaultMessage="Dotaz bude vrácen zpět řešiteli s Vašim komentářem. Pokud chcete upravit atributy dotazu, použijte nejdřív funkci Upravit."
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormTextArea
                  required
                  maxRows={25}
                  name="text"
                  label={
                    <FormattedMessage
                      id="KS_R_TICKETS_AMEND_FIELD_LABEL_TEXT"
                      defaultMessage="Komentář"
                    />
                  }
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Dialog>
    );
  }
);
