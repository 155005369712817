import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 30,
    height: 30,
  },
  language: {
    minWidth: 'auto',
    width: 30,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '0.8rem!important',
    borderRadius: '2px!important',

    '&.MuiButton-containedPrimary': {
      '&:hover,&:focus': {
        backgroundColor: `${theme.palette.primary.main}!important`,
      },
    },
  },
  selected: {
    color: 'white',
  },
  divider: {
    marginLeft: 5,
    marginRight: 5,
  },
}));
