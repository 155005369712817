import { abortableFetch } from '@eas/common-web';

/**
 * Calls change password api.
 *
 * @param id Id of the operator
 * @param password Password to set
 */
export function changeOperatorPassword(id: string, password: string) {
  return abortableFetch(`/api/ks/operators/${id}/password`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: password,
  });
}
