import { useRef, useState, useEffect, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  useEventCallback,
  FormHandle,
  UserContext,
  AbortableFetch,
  SnackbarContext,
  SnackbarVariant,
  DialogHandle,
  useDefaultValidationMessages,
} from '@eas/common-web';
import { Profile, ProfileUpdate } from '../../../common/models';
import { fetchProfile, updateProfile } from './profile-api';

export function useProfile() {
  const intl = useIntl();
  const { meetsFormat } = useDefaultValidationMessages();
  const { showSnackbar } = useContext(SnackbarContext);
  const ref = useRef<FormHandle<Profile>>(null);
  const fetch = useRef<AbortableFetch | null>(null);
  const pwdChangeDialog = useRef<DialogHandle>(null);
  const { user } = useContext(UserContext);

  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);

  const validationSchema = Yup.object<Profile>().shape({
    firstName: Yup.string().nullable().required(),
    lastName: Yup.string().nullable().required(),
    organisationName: Yup.string().nullable().required(),
    organisationIc: Yup.string().nullable().required(),
    phone: Yup.string()
      .nullable()
      .matches(
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
        meetsFormat
      ),
  });

  const get = useEventCallback(async (id: string) => {
    try {
      setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = fetchProfile();

      const data: Profile = await fetch.current.json();
      unstable_batchedUpdates(() => {
        setProfile(data);
        setLoading(false);
      });
      return data;
    } catch (err) {
      setLoading(false);

      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_R_PROFILE_MSG_GET_ERROR',
            defaultMessage: 'Chyba načtení dat: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const update = useEventCallback(async (item: ProfileUpdate) => {
    try {
      setLoading(true);

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = updateProfile(item);

      const data: Profile = await fetch.current.json();

      const message = intl.formatMessage({
        id: 'KS_R_PROFILE_MSG_UPDATE_SUCCESS',
        defaultMessage: 'Váš profil byl úspěšně upraven.',
      });

      unstable_batchedUpdates(() => {
        showSnackbar(message, SnackbarVariant.SUCCESS);
        setProfile(data);
        setLoading(false);
      });

      window.location.reload(false);
      return data;
    } catch (err) {
      setLoading(false);

      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_R_PROFILE_MSG_UPDATE_ERROR',
            defaultMessage: 'Chyba uložení dat: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const handleStartEditing = useEventCallback(() => {
    setEditing(true);
  });

  const handleCancelEditing = useEventCallback(() => {
    setEditing(false);
    ref.current?.setFieldValues(profile!);
  });

  useEffect(() => {
    if (user !== undefined && user.id !== null) {
      get(user.id).then((data) => ref.current?.setFieldValues(data!));
    }
  }, []);

  const handleSubmit = useEventCallback(async () => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_R_PROFILE_MSG_VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });

        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        setLoading(false);
        return;
      }

      const profile = await update(ref.current.getFieldValues());
      ref.current.setFieldValues(profile!);
      setEditing(false);
    }
  });

  const handlePwdChange = useEventCallback(() => {
    pwdChangeDialog.current?.open();
  });

  return {
    loading,
    editing,
    handleSubmit,
    handleStartEditing,
    handleCancelEditing,
    handlePwdChange,
    ref,
    pwdChangeDialog,
    validationSchema,
  };
}
