import { useContext, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useIntl } from 'react-intl';
import {
  CrudSource,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  SnackbarVariant,
  activateItem,
  deactivateItem,
  DialogHandle,
} from '@eas/common-web';
import { Faq } from '../../../common/models';

export function useFaqs({
  source,
  onPersisted,
}: {
  source: CrudSource<Faq>;
  onPersisted: (id: string | null) => void;
}) {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useRef<AbortableFetch | null>(null);
  const publishDialog = useRef<DialogHandle>(null);
  const unpublishDialog = useRef<DialogHandle>(null);

  const publish = useEventCallback(async () => {
    try {
      source.setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = activateItem(source.url, source.data!.id);

      await fetch.current.none();

      const message = intl.formatMessage({
        id: 'KS_O_FAQ_MSG_ACTIVATE_SUCCESS',
        defaultMessage: 'Záznam byl úspěšně aktivován.',
      });

      unstable_batchedUpdates(() => {
        showSnackbar(message, SnackbarVariant.SUCCESS);
        source.setLoading(false);
      });

      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_O_FAQ_MSG_ACTIVATE_ERROR',
            defaultMessage: 'Chyba volání funkce: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const unpublish = useEventCallback(async () => {
    try {
      source.setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = deactivateItem(source.url, source.data!.id);

      await fetch.current.none();

      const message = intl.formatMessage({
        id: 'KS_O_FAQ_MSG_DEACTIVATE_SUCCESS',
        defaultMessage: 'Záznam byl úspěšně deaktivován.',
      });

      unstable_batchedUpdates(() => {
        showSnackbar(message, SnackbarVariant.SUCCESS);
        source.setLoading(false);
      });

      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_O_FAQ_MSG_DEACTIVATE_ERROR',
            defaultMessage: 'Chyba volání funkce: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const openPublishDialog = useEventCallback(() => {
    publishDialog.current?.open();
  });

  const openUnpublishDialog = useEventCallback(() => {
    unpublishDialog.current?.open();
  });

  return {
    publish,
    unpublish,
    openPublishDialog,
    openUnpublishDialog,
    publishDialog,
    unpublishDialog,
  };
}
