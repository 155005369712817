import React, { PropsWithChildren, useContext } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserContext } from '@eas/common-web';
import { PublicPageProps } from './public-page-types';
import { PublicMenu } from './public-menu';
import { Footer } from '../footer/footer';
import { useStyles } from './public-page-styles';
import { RequesterMenu } from './requester-menu';

export function PublicPage({
  title,
  children,
  loading,
  editHightlight = false,
}: PropsWithChildren<PublicPageProps>) {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <div
      className={clsx(classes.root, {
        [classes.editNotHightlight]: !editHightlight,
      })}
    >
      <div className={classes.header}>
        {user !== undefined ? <RequesterMenu /> : <PublicMenu />}
      </div>
      <div className={classes.body}>
        <Grid container>
          <Grid item xs={false} sm={1} />
          <Grid item xs={12} sm={10} className={classes.loadingGrid}>
            {loading && (
              <div className={classes.loaderWrapper}>
                <CircularProgress disableShrink className={classes.loader} />
              </div>
            )}
            {title !== undefined && <h2 className={classes.title}>{title}</h2>}
            {children}
          </Grid>
          <Grid item xs={false} sm={1} />
        </Grid>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
}
