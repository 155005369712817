import {
  useScrollableSource,
  useEventCallback,
  NavigationContext,
  TableHandle,
} from '@eas/common-web';
import { TicketExternalList } from '../../../common/models';
import { useColumns } from './tickets-columns';
import { useContext, useRef } from 'react';
import { usePrintHook } from './tickets-print-hook';

export function useTickets() {
  const { navigate } = useContext(NavigationContext);
  const source = useScrollableSource<TicketExternalList>({
    url: '/api/ks/external/tickets/list',
  });
  const columns = useColumns();
  const tableRef = useRef<TableHandle<TicketExternalList>>(null);
  const { print, printing } = usePrintHook(tableRef);

  const handleClickNew = useEventCallback(() => {
    navigate('/requester/tickets/new');
  });

  return {
    source,
    columns,
    tableRef,
    handleClickNew,
    handleClickPrint: print,
    printing,
  };
}
