import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelect, FormPanel, FormAutocomplete } from '@eas/common-web';
import { usePermissions, useExportTemplates } from '../../operator-api';

export function AccessRulesFields() {
  const intl = useIntl();
  const templates = useExportTemplates();
  const permissions = usePermissions();
  return (
    <FormPanel
      label={
        <FormattedMessage
          id="KS_A_EXPORT_ACCESS_RULES_FIELD_PANEL_TITLE"
          defaultMessage="Pravidlo přístupu k exportu"
        />
      }
    >
      <FormAutocomplete
        name="template"
        required
        label={
          <FormattedMessage
            id="KS_A_EXPORT_ACCESS_RULES_FIELD_LABEL_TEMPLATE"
            defaultMessage="Šablona"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_A_EXPORT_ACCESS_RULES_FIELD_HELP_TEMPLATE',
          defaultMessage: ' ',
        })}
        source={templates}
        tooltipMapper={(o) => o.name}
      />
      <FormSelect
        name="permissions"
        label={
          <FormattedMessage
            id="KS_A_EXPORT_ACCESS_RULES_FIELD_LABEL_PERMISSIONS"
            defaultMessage="Oprávnění"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_A_EXPORT_ACCESS_RULES_FIELD_HELP_PERMISSIONS',
          defaultMessage: ' ',
        })}
        source={permissions}
        tooltipMapper={(o) => o.name}
        multiple={true}
        valueIsId={true}
      />
    </FormPanel>
  );
}
