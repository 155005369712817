import * as Yup from 'yup';
import {
  NotificationTemplate,
  NotificationEvent,
} from '../../../common/models';

export function useValidationSchema() {
  return Yup.object<NotificationTemplate>().shape({
    name: Yup.string().nullable().required(),
    subject: Yup.string().nullable().required(),
    content: Yup.string().nullable().required(),
    event: Yup.mixed<NotificationEvent>().nullable().required(),
  });
}
