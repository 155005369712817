import { useIntl } from 'react-intl';
import { TableColumn, TableCells, TableFilterCells } from '@eas/common-web';
import { NotificationTemplate } from '../../../common/models';
import { useNotificationEvents } from './notification-templates-api';

export function useColumns(): TableColumn<NotificationTemplate>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'KS_O_NOTICFICATION_TEMPLATES_COLUMN_NAME',
        defaultMessage: 'Název',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'subject',
      name: intl.formatMessage({
        id: 'KS_O_NOTICFICATION_TEMPLATES_COLUMN__SUBJECT',
        defaultMessage: 'Nadpis',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'formatted',
      name: intl.formatMessage({
        id: 'KS_O_NOTICFICATION_TEMPLATES_COLUMN_FORMATTED',
        defaultMessage: 'HTML šablona',
      }),
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'event',
      sortkey: 'event.name',
      filterkey: 'event.id',
      name: intl.formatMessage({
        id: 'KS_O_NOTICFICATION_TEMPLATES_COLUMN_EVENT',
        defaultMessage: 'Událost',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useNotificationEvents
      ),
      valueMapper: TableCells.useSelectCellFactory(useNotificationEvents),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'active',
      name: intl.formatMessage({
        id: 'KS_O_NOTICFICATION_TEMPLATES_COLUMN_ACTIVE',
        defaultMessage: 'Aktivní',
      }),
      width: 100,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
  ];
}
