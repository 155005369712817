import { useEventCallback, TableHandle, TableColumn } from '@eas/common-web';
import { TicketExternalList } from '../../../common/models';
import { useGeneralPrintHook } from '../printing/requester-print-hook';

export function usePrintHook(
  tableRef: React.RefObject<TableHandle<TicketExternalList>>
) {
  function serializeColumns(columns: TableColumn<any>[]) {
    return columns.map((column) => ({
      name: column.name,
      datakey: column.datakey,
      displaykey: column.displaykey,
      width: column.width,
      visible: column.visible,
      cellComponentName:
        column.CellComponent.displayName ?? column.CellComponent.name,
      valueMapperName:
        column.valueMapper?.displayName ?? column.valueMapper?.name,
      valueMapperData: (column.valueMapper as any)?.data,
    }));
  }

  const provideData = useEventCallback(() => {
    const table = tableRef.current!;

    return {
      params: table.source.getParams(),
      columns: serializeColumns(table.filteredColumns),
      title: 'Dotazy',
    };
  });

  return useGeneralPrintHook(
    '/api/ks/external/tickets/print',
    '/api/ks/external/tickets/print',
    provideData
  );
}

export function usePrintSingleHook(id: string) {
  const provideData = useEventCallback(() => {
    return {};
  });

  return useGeneralPrintHook(
    `/api/ks/external/tickets/print-single/${id}`,
    '/api/ks/external/tickets/print',
    provideData
  );
}
