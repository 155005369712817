import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {
  Form,
  FormTextField,
  FormSubmitButton,
  useEventCallback,
} from '@eas/common-web';
import { PublicPage } from '../../../components/public-page/public-page';
import { LoginData } from '../../../common/models';
import { useLoginOperatorExternal } from './login-operator-external-hook';
import { useStyles } from './login-operator-external-styles';
import { KsPublicFormItem } from '../components/ks-public-form-item/ks-public-form-item';
import crrLogo from './crr-logo.svg';

export function LoginOperatorExternal() {
  const intl = useIntl();

  const classes = useStyles();

  const handleLoginOperatorClick = useEventCallback(() => {
    window.location.href = '/api/ks/auth/operator-saml';
  });

  const {
    loading,
    formRef,
    handleSubmit,
    hiddenFormRef,
    hiddenUsernameRef,
    hiddenPasswordRef,
    hiddenCaptchaRef,
  } = useLoginOperatorExternal();
  return (
    <PublicPage>
      <Typography className={classes.title} variant="h1">
        <FormattedMessage
          id="KS_P_LOGIN_EXO_MAIN_TITLE"
          defaultMessage="Konzultační servis"
        />
      </Typography>
      <Typography className={classes.warning}>
        <FormattedMessage id="KS_P_LOGIN_EXO_WARNING" defaultMessage=" " />
      </Typography>
      <Typography className={classes.subtitle} variant="h3">
        <FormattedMessage
          id="KS_P_LOGIN_EXO_SUBTITLE"
          defaultMessage="Přihlášení"
        />
      </Typography>

      <Grid container>
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} variant="outlined">
            <Box display="flex" flexDirection="column" alignItems="stretch">
              <Typography variant="h2" className={classes.internalTitle}>
                <FormattedMessage
                  id="KS_P_LOGIN_EXO_LOGIN_TITLE"
                  defaultMessage="Interní řešitel"
                />
              </Typography>
              <img className={classes.internalLogo} src={crrLogo} />
              <Button
                onClick={handleLoginOperatorClick}
                className={classes.internalButton}
                variant="contained"
                color="primary"
                disabled={loading}
                startIcon={
                  loading && <CircularProgress size="20px" color="inherit" />
                }
              >
                <FormattedMessage
                  id="KS_P_LOGIN_EXO_FIELD_BTN_LOGIN"
                  defaultMessage="Přihlásit"
                />
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} variant="outlined">
            <Form<LoginData>
              editing={!loading}
              initialValues={{} as any}
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignItems="stretch">
                  <Typography variant="h2" className={classes.externalTitle}>
                    <FormattedMessage
                      id="KS_P_LOGIN_EXO_LOGIN_EXTERNAL_TITLE"
                      defaultMessage="Externí řešitel"
                    />
                  </Typography>

                  <KsPublicFormItem
                    name="username"
                    className={classes.externalUsername}
                    helpLabel={intl.formatMessage({
                      id: 'KS_P_LOGIN_EXO_FIELD_HELP_EMAIL',
                      defaultMessage: ' ',
                    })}
                    label={
                      <FormattedMessage
                        id="KS_P_LOGIN_EXO_FIELD_LABEL_EMAIL"
                        defaultMessage="Email"
                      />
                    }
                  >
                    <FormTextField
                      required
                      id="username"
                      name="username"
                      autocomplete="username"
                    />
                  </KsPublicFormItem>
                  <KsPublicFormItem
                    name="password"
                    className={classes.externalPassword}
                    helpLabel={intl.formatMessage({
                      id: 'KS_P_LOGIN_EXO_FIELD_HELP_PASSWORD',
                      defaultMessage: ' ',
                    })}
                    label={
                      <FormattedMessage
                        id="KS_P_LOGIN_EXO_FIELD_LABEL_PASSWORD"
                        defaultMessage="Heslo"
                      />
                    }
                  >
                    <FormTextField
                      required
                      type="password"
                      id="password"
                      name="password"
                      autocomplete="current-password"
                    />
                  </KsPublicFormItem>

                  <FormSubmitButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.externalButton}
                    disabled={loading}
                    startIcon={
                      loading && (
                        <CircularProgress size="20px" color="inherit" />
                      )
                    }
                  >
                    <FormattedMessage
                      id="KS_P_LOGIN_EXO_FIELD_BTN_LOGIN"
                      defaultMessage="Přihlásit"
                    />
                  </FormSubmitButton>

                  <Typography className={classes.or}>
                    <FormattedMessage
                      id="KS_P_LOGIN_TEXT_LOGIN_OR"
                      defaultMessage="nebo"
                    />
                  </Typography>

                  <Typography className={classes.reset}>
                    <Link href="/operator-password-reset-request">
                      <FormattedMessage
                        id="KS_P_LOGIN_TEXT_FORGOTTEN_PASSWORD"
                        defaultMessage="Zapoměli jste heslo ?"
                      />
                    </Link>
                  </Typography>

                  <form
                    ref={hiddenFormRef}
                    action="/api/ks/auth/operator-external"
                    method="POST"
                  >
                    <input ref={hiddenUsernameRef} name="username" hidden />
                    <input
                      ref={hiddenPasswordRef}
                      name="password"
                      type="password"
                      hidden
                    />
                    <input ref={hiddenCaptchaRef} name="captcha" hidden />
                  </form>
                </Box>
              </Grid>
            </Form>
          </Paper>
          <Grid item xs={false} md={2}></Grid>
        </Grid>
      </Grid>
    </PublicPage>
  );
}
