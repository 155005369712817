import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useAuthoredEvidence } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { Operator } from '../../../common/models';
import { OperatorFields } from './operator-fields';
import { useColumns } from './operators-columns';
import { OperatorsToolbar } from './operators-toolbar';
import { useValidationSchema } from './operators-schema';

export function OperatorOperators() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useAuthoredEvidence<Operator>({
    identifier: 'OPERATORS',
    apiProps: {
      url: '/api/ks/operators',
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'KS_O_OPERATORS_TABLE_TITLE',
        defaultMessage: 'Řešitelé',
      }),
      reportTag: ExportTags.OPERATOR_GRID,
    },
    detailProps: {
      FieldsComponent: OperatorFields,
      validationSchema,
      toolbarProps: {
        after: <OperatorsToolbar />,
      },
    },
  });

  return <Evidence {...evidence} />;
}
