import React, { forwardRef, useRef, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  DialogHandle,
  Dialog,
  useEventCallback,
  Form,
  FormHandle,
  SnackbarVariant,
  SnackbarContext,
  DetailContext,
  FormTextField,
  UserContext,
  FormRichEditor,
  FormTextArea,
} from '@eas/common-web';
import { ResolveDialogProps, Resolve } from './tickets-types';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './tickets-styles';
import { resolve } from './tickets-api';
import { TicketDialogLeftPanel } from './ticket-dialog-left-panel';

export const ResolveDialog = forwardRef<DialogHandle, ResolveDialogProps>(
  function ResolveDialog({ id, ticket }, dialogRef) {
    const classes = useStyles();
    const intl = useIntl();
    const { showSnackbar } = useContext(SnackbarContext);
    const { refreshAll } = useContext(DetailContext);
    const { user } = useContext(UserContext);

    const ref = useRef<FormHandle<Resolve>>(null);
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object<Resolve>().shape({
      answer: Yup.string().nullable().required(),
      resolveName: Yup.string().nullable().required(),
    });

    const onConfirm = useEventCallback(async () => {
      if (ref.current != undefined) {
        const errors = await ref.current.validateForm();

        if (errors.length > 0) {
          const errorMsg = intl.formatMessage({
            id: 'KS_O_TICKETS_RESOLVE_DIALOG_MSG_VALIDATION',
            defaultMessage: 'Ve formuláři se nacházejí chyby',
          });

          showSnackbar(errorMsg, SnackbarVariant.ERROR);
          setLoading(false);
          return false;
        }

        ref.current.submitForm();
      }
    });

    const handleSubmit = useEventCallback(async (values: Resolve) => {
      try {
        setLoading(true);

        await resolve(id!, values).none();

        const message = intl.formatMessage({
          id: 'KS_O_TICKETS_RESOLVE_DIALOG_MSG_SUCCESS',
          defaultMessage: 'Dotaz byl zodpovězen',
        });

        showSnackbar(message, SnackbarVariant.SUCCESS);
        setLoading(false);

        refreshAll();
      } catch (err) {
        if (err.name !== 'AbortError') {
          const message = intl.formatMessage(
            {
              id: 'KS_O_TICKETS_RESOLVE_DIALOG_MSG_ERROR',
              defaultMessage: 'Chyba uložení: {detail}',
            },
            { detail: err.message }
          );

          showSnackbar(message, SnackbarVariant.ERROR);
          setLoading(false);

          throw err;
        }
      }
    });

    return (
      <Dialog
        disableBackdrop
        ref={dialogRef}
        loading={loading}
        title={
          <FormattedMessage
            id="KS_O_TICKETS_RESOLVE_DIALOG_TITLE"
            defaultMessage="Zodpovězení dotazu"
          />
        }
        onConfirm={onConfirm}
      >
        {() => (
          <Grid container className={classes.actionDialogContainerWrapper}>
            <Grid
              item
              xs={4}
              sm={4}
              className={classes.actionDialogQuestionWrapper}
            >
              <TicketDialogLeftPanel ticket={ticket} />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              className={classes.actionDialogAnswerWrapper}
            >
              <Form<Resolve>
                editing={!loading}
                initialValues={{ answer: '', resolveName: user?.name ?? '' }}
                onSubmit={handleSubmit}
                ref={ref}
                validationSchema={validationSchema}
              >
                <Grid container className={classes.actionDialogWrapper}>
                  <Grid item xs={12} sm={12}>
                    {ticket?.formatting ? (
                      <FormRichEditor
                        required
                        name="answer"
                        label={
                          <FormattedMessage
                            id="KS_O_TICKETS_RESOLVE_DIALOG_FIELD_ANSWER"
                            defaultMessage="Odpověď"
                          />
                        }
                        helpLabel={intl.formatMessage({
                          id: 'KS_O_TICKETS_RESOLVE_DIALOG_FIELD_HELP_ANSWER',
                          defaultMessage: ' ',
                        })}
                      />
                    ) : (
                      <FormTextArea
                        required
                        name="answer"
                        maxRows={25}
                        label={
                          <FormattedMessage
                            id="KS_O_TICKETS_RESOLVE_DIALOG_FIELD_ANSWER"
                            defaultMessage="Odpověď"
                          />
                        }
                        helpLabel={intl.formatMessage({
                          id: 'KS_O_TICKETS_RESOLVE_DIALOG_FIELD_HELP_ANSWER',
                          defaultMessage: ' ',
                        })}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextField
                      name="resolveName"
                      required
                      label={
                        <FormattedMessage
                          id="KS_O_TICKETS_RESOLVE_DIALOG_FIELD_RESOLVE_NAME"
                          defaultMessage="Autor odpovědi"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id:
                          'KS_O_TICKETS_RESOLVE_DIALOG_FIELD_HELP_RESOLVE_NAME',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}
      </Dialog>
    );
  }
);
