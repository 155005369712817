import * as Yup from 'yup';
import { useDefaultValidationMessages } from '@eas/common-web';
import { Requester } from '../../../common/models';
import { validateEmail } from './requesters-api';

export function useValidationSchema() {
  const { meetsFormat, doesNotExist } = useDefaultValidationMessages();

  const emailSchema = Yup.string().nullable().required().email(meetsFormat);

  return Yup.object<Requester>().shape({
    firstName: Yup.string().nullable().required(),
    lastName: Yup.string().nullable().required(),
    phone: Yup.string()
      .nullable()
      .matches(
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
        meetsFormat
      ),
    email: emailSchema.test('email_test', doesNotExist, async function (value) {
      const values: Requester = (this.options.context as any).values;
      if (emailSchema.validateSync(value)) {
        const fetch = validateEmail(value!, values.id);
        return await fetch.json();
      } else {
        return true;
      }
    }),
    organisationName: Yup.string().nullable().required(),
    organisationIc: Yup.string().nullable().required(),
  });
}
