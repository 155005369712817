import { useContext, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useIntl } from 'react-intl';
import {
  CrudSource,
  SnackbarContext,
  AbortableFetch,
  useEventCallback,
  SnackbarVariant,
  activateItem,
  deactivateItem,
  DialogHandle,
} from '@eas/common-web';
import { Requester } from '../../../common/models';

export function useRequesters({
  source,
  onPersisted,
}: {
  source: CrudSource<Requester>;
  onPersisted: (id: string | null) => void;
}) {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const fetch = useRef<AbortableFetch | null>(null);
  const passwordChangeDialog = useRef<DialogHandle>(null);
  const confirmDeactivateDialog = useRef<DialogHandle>(null);
  const confirmActivateDialog = useRef<DialogHandle>(null);

  const activate = useEventCallback(async () => {
    try {
      source.setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = activateItem(source.url, source.data!.id);

      await fetch.current.none();

      const message = intl.formatMessage({
        id: 'KS_O_REQUESTERS_MSG_ACTIVATED_SUCCESS',
        defaultMessage: 'Záznam byl úspěšně aktivován.',
      });

      unstable_batchedUpdates(() => {
        showSnackbar(message, SnackbarVariant.SUCCESS);
        source.setLoading(false);
      });

      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_O_REQUESTERS_MSG_ACTIVATED_ERROR',
            defaultMessage: 'Chyba volání funkce: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const deactivate = useEventCallback(async () => {
    try {
      source.setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = deactivateItem(source.url, source.data!.id);

      await fetch.current.none();

      const message = intl.formatMessage({
        id: 'KS_O_REQUESTERS_MSG_DEACTIVATED_SUCCESS',
        defaultMessage: 'Záznam byl úspěšně deaktivován.',
      });

      unstable_batchedUpdates(() => {
        showSnackbar(message, SnackbarVariant.SUCCESS);
        source.setLoading(false);
      });

      onPersisted(source.data!.id);
      await source.refresh();
    } catch (err) {
      source.setLoading(false);

      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_O_REQUESTERS_MSG_DEACTIVATED_ERROR',
            defaultMessage: 'Chyba volání funkce: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const changePassword = useEventCallback(() => {
    passwordChangeDialog.current?.open();
  });

  const openActivateDialog = useEventCallback(() => {
    confirmActivateDialog.current?.open();
  });

  const openDeactivateDialog = useEventCallback(() => {
    confirmDeactivateDialog.current?.open();
  });

  return {
    activate,
    deactivate,
    changePassword,
    openActivateDialog,
    openDeactivateDialog,
    passwordChangeDialog,
    confirmDeactivateDialog,
    confirmActivateDialog,
    id: source.data?.id,
  };
}
