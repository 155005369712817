import {
  useEventCallback,
  ExportRequest,
  sleep,
  AbortableFetch,
  abortableFetch,
  getErrorMessage,
  SnackbarContext,
  SnackbarVariant,
  FilesContext,
} from '@eas/common-web';
import { useContext, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { unstable_batchedUpdates } from 'react-dom';

export enum ExportRequestState {
  /**
   * State of a export request after creation
   */
  PENDING = 'PENDING',

  /**
   * State of a export request being processed
   */
  PROCESSING = 'PROCESSING',

  /**
   * State of a request successfully processed and corresponding export file was generated
   */
  PROCESSED = 'PROCESSED',

  /**
   * State of a export request whose processing failed
   */
  FAILED = 'FAILED',
}

export function useGeneralPrintHook(
  urlSubmit: string,
  urlPoll: string,
  provideData: () => Record<string, any>
) {
  const requestSource = usePrintSourceHook(urlSubmit, urlPoll);
  const [printing, setPrinting] = useState(false);
  const { getFileUrl } = useContext(FilesContext);

  const print = useEventCallback(async () => {
    submitPrint(provideData, (id) => {
      if (id !== undefined) {
        window.open(getFileUrl(id!));
      }
    });
  });

  const submitPrint = useEventCallback(
    async (
      provideData: () => Record<string, any>,
      resultCallback: (id: string | undefined) => void
    ) => {
      setPrinting(true);
      await requestSource.create(JSON.stringify(provideData()));

      let state: ExportRequestState | undefined;
      let data: ExportRequest | undefined;

      try {
        do {
          await sleep(2000);

          data = await requestSource.refresh();
          state = data?.state;
        } while (
          state === ExportRequestState.PENDING ||
          state === ExportRequestState.PROCESSING
        );

        if (state === ExportRequestState.PROCESSED) {
          resultCallback(data?.result?.id);
        } else if (state === ExportRequestState.FAILED) {
          resultCallback(undefined);
        }
      } finally {
        setPrinting(false);
      }
    }
  );

  return {
    print,
    printing,
  };
}

function usePrintSourceHook(urlSubmit: string, urlPoll: string) {
  const fetch = useRef<AbortableFetch | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ExportRequest | null>(null);
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);

  const handleCreate = useEventCallback(async (configuration: string) => {
    try {
      setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = abortableFetch(urlSubmit, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: configuration,
      });

      const data: ExportRequest = await fetch.current.json();

      const message = intl.formatMessage({
        id: 'KS_R_PRINTING_MSG_CREATE_SUCCESS',
        defaultMessage: 'Požadavek na export byl zařazen do fronty.',
      });

      unstable_batchedUpdates(async () => {
        showSnackbar(message, SnackbarVariant.SUCCESS);
        setData(data);
        setLoading(false);
      });
      return data;
    } catch (err) {
      setLoading(false);

      if (err.name !== 'AbortError') {
        const errorDetail = getErrorMessage(err);

        const message = intl.formatMessage(
          {
            id: 'KS_R_PRINTING_MSG_CREATE_ERROR',
            defaultMessage: 'Chyba exportu: {detail}',
          },
          { detail: errorDetail }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const handleGet = useEventCallback(async (id: string) => {
    try {
      setLoading(true);
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = abortableFetch(`${urlPoll}/${id}`, {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });

      const data: ExportRequest = await fetch.current.json();
      unstable_batchedUpdates(async () => {
        setData(data);
        setLoading(false);
      });
      return data;
    } catch (err) {
      setLoading(false);

      if (err.name !== 'AbortError') {
        const errorDetail = getErrorMessage(err);

        const message = intl.formatMessage(
          {
            id: 'KS_R_PRINTING_MSG_LOAD_ERROR',
            defaultMessage: 'Chyba exportu: {detail}',
          },
          { detail: errorDetail }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
      return undefined;
    }
  });

  const refresh = useEventCallback(async () => {
    if (data !== null) {
      return handleGet(data.id);
    }
  });

  return {
    create: handleCreate,
    get: handleGet,
    refresh,
    loading,
  };
}
