import React, { useContext, Context } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { AppSettingsContext, FormTextField } from '@eas/common-web';
import { KsAppSettings } from '../../../../../common/models';
import { KsPublicFormItem } from '../../../../public/components/ks-public-form-item/ks-public-form-item';

export function TicketCustomFields() {
  const intl = useIntl();
  const { settings } = useContext(
    (AppSettingsContext as unknown) as Context<
      AppSettingsContext<KsAppSettings>
    >
  );
  const fields = settings.fields ?? [];

  return (
    <>
      {fields.map(
        (field, index) =>
          field != null &&
          field.enabled &&
          field.public && (
            <Grid key={index} item xs={12} sm={12}>
              <KsPublicFormItem
                required
                name={`field${index + 1}`}
                helpLabel={
                  field.helpId
                    ? intl.formatMessage({
                        id: field.helpId,
                        defaultMessage: field.helpDefault,
                      })
                    : undefined
                }
                label={
                  field.labelId && (
                    <FormattedMessage
                      id={field.labelId}
                      defaultMessage={field.labelDefault}
                    />
                  )
                }
              >
                <FormTextField
                  required={field.required}
                  name={`field${index + 1}`}
                />
              </KsPublicFormItem>
            </Grid>
          )
      )}
    </>
  );
}
