import { useState, useRef, useContext } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useIntl } from 'react-intl';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as Yup from 'yup';
import {
  AbortableFetch,
  SnackbarContext,
  NavigationContext,
  FormHandle,
  useEventCallback,
  SnackbarVariant,
  useDefaultValidationMessages,
} from '@eas/common-web';
import { PasswordResetRequest } from '../../../common/models';
import { sendPasswordResetRequest } from './password-reset-request-api';

export function usePasswordResetRequest(isOperator: boolean) {
  const intl = useIntl();
  const { meetsFormat } = useDefaultValidationMessages();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);

  const [loading, setLoading] = useState(false);
  const fetch = useRef<AbortableFetch | null>(null);
  const formRef = useRef<FormHandle<PasswordResetRequest>>(null);

  const validationSchema = Yup.object<PasswordResetRequest>().shape({
    email: Yup.string().nullable().required().email(meetsFormat),
  });

  const submitPasswordResetRequest = useEventCallback(
    async (item: PasswordResetRequest) => {
      try {
        setLoading(true);

        const captcha = await executeRecaptcha!('passwordReset');

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = sendPasswordResetRequest({
          ...item,
          operator: isOperator,
          captcha,
        });

        await fetch.current.none();

        unstable_batchedUpdates(() => {
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);

        if (err.name !== 'AbortError') {
          const message = intl.formatMessage(
            {
              id: 'KS_P_PW_RESET_REQUEST_MSG_ERROR',
              defaultMessage: 'Chyba resetovani hesla: {detail}',
            },
            { detail: err.message }
          );

          showSnackbar(message, SnackbarVariant.ERROR);

          throw err;
        }
        return undefined;
      }
    }
  );

  const handleSubmit = useEventCallback(async (data: PasswordResetRequest) => {
    if (formRef.current != undefined) {
      const errors = await formRef.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_P_PW_RESET_MSG_VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });

        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        return;
      }
    }

    await submitPasswordResetRequest(data);

    navigate('/password-reset-request-complete', true);
  });

  return {
    loading,
    formRef,
    validationSchema,
    handleSubmit,
  };
}
