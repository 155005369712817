import React, { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { DialogHandle, Dialog, Form, FormTextField } from '@eas/common-web';

import { useRequestersPwDialog } from './requesters-pw-dialog-hook';
import {
  RequestersPwChange,
  RequestersPwChangeDialogProps,
} from './requesters-types';
import { useStyles } from './requesters-styles';

export const RequesterPwChangeDialog = forwardRef<
  DialogHandle,
  RequestersPwChangeDialogProps
>(function RequesterPwChangeDialog({ id }, dialogRef) {
  const intl = useIntl();
  const classes = useStyles();
  const {
    ref,
    loading,
    validationSchema,
    handleConfirm,
    handleSubmit,
  } = useRequestersPwDialog(id);

  return (
    <Dialog
      ref={dialogRef}
      loading={loading}
      title={
        <FormattedMessage
          id="KS_O_REQUESTERS_PW_CHANGE_DIALOG_TITLE"
          defaultMessage="Změna hesla"
        />
      }
      onConfirm={handleConfirm}
    >
      {() => (
        <Form<RequestersPwChange>
          editing={!loading}
          initialValues={{ newPassword: '', newPasswordAgain: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          ref={ref}
        >
          <Grid container className={classes.dialogWrapper}>
            <Grid item xs={12} sm={12}>
              <FormTextField
                type="password"
                required
                name="newPassword"
                autocomplete="new-password"
                label={
                  <FormattedMessage
                    id="KS_O_REQUESTERS_PW_CHANGE_DIALOG_FIELD_NEW_PASSWORD"
                    defaultMessage="Nové heslo"
                  />
                }
                helpLabel={intl.formatMessage({
                  id:
                    'KS_O_REQUESTERS_PW_CHANGE_DIALOG_FIELD_HELP_NEW_PASSWORD',
                  defaultMessage: 'Heslo musí obsahovat 8-64 znaků',
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormTextField
                type="password"
                required
                name="newPasswordAgain"
                autocomplete="new-password"
                label={
                  <FormattedMessage
                    id="KS_O_REQUESTERS_PW_CHANGE_DIALOG_FIELD_NEW_PASSWORD_AGAIN"
                    defaultMessage="Nové heslo znovu"
                  />
                }
                helpLabel={intl.formatMessage({
                  id:
                    'KS_O_REQUESTERS_PW_CHANGE_DIALOG_FIELD_HELP_NEW_PASSWORD_AGAIN',
                  defaultMessage: ' ',
                })}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Dialog>
  );
});
