import React from 'react';
import { FormattedMessage } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  TableCellProps,
  TableToolbarButton,
  useEventCallback,
} from '@eas/common-web';
import { TicketExternalList } from '../../../common/models';

export function TicketActions(props: TableCellProps<TicketExternalList>) {
  const classes = useStyles();

  const handleClickEdit = useEventCallback(() => {
    // old opening in same window: navigate(`/requester/tickets/${props.rowValue.id}`);
    window.open(`/requester/tickets/${props.rowValue.id}`, '_blank');
  });

  return (
    <ul className={classes.toolbarButtonList}>
      <TableToolbarButton
        className={classes.tableButton}
        label={
          <>
            <EditIcon />
            <FormattedMessage
              id="KS_R_TICKETS_BTN_EDIT_TEXT"
              defaultMessage="Detail"
            />
          </>
        }
        primary={true}
        onClick={handleClickEdit}
        tooltip={
          <FormattedMessage
            id="KS_R_TICKETS_BTN_EDIT"
            defaultMessage="Otevře okno s detailem"
          />
        }
      />
    </ul>
  );
}

export const useStyles = makeStyles(() => ({
  toolbarButtonList: {
    marginTop: 0,
    marginBottom: 0,
    listStyle: 'none',
    display: 'flex',
    paddingLeft: 0,
  },
  tableButton: {
    fontWeight: 400,
  },
}));
