import { abortableFetch } from '@eas/common-web';

/**
 * Calls confirm registration API method.
 *
 * @param id Id of the item
 */
export function confirmRegistration(id: string) {
  return abortableFetch(`/api/ks/registrations/${id}/confirm`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}
