import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormTextArea,
  FormSelect,
  FormAutocomplete,
  FormPanel,
  FormCheckbox,
  FormDateTimeField,
  FormCustomField,
  useFormSelector,
  Link,
  EvidenceStateAction,
} from '@eas/common-web';
import {
  useProjectPhases,
  useRegions,
  useSpecificTargets,
  useActivities,
  useNotices,
  useTags,
  useActiveEditors,
} from '../operator-api';
import { Faq } from '../../../common/models';

export function FaqFields() {
  const intl = useIntl();
  const source = useFormSelector((data: Faq) => data.source);

  const projectPhases = useProjectPhases();
  const regions = useRegions();
  const specificTargets = useSpecificTargets();
  const activities = useActivities();
  const notices = useNotices();
  const tags = useTags();

  const operators = useActiveEditors();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="KS_O_FAQ_FIELD_PANEL_TITLE"
            defaultMessage="FAQ"
          />
        }
      >
        <FormTextArea
          name="question"
          required
          maxRows={25}
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_QUESTION"
              defaultMessage="Otázka"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_QUESTION',
            defaultMessage: ' ',
          })}
        />
        <FormTextArea
          name="answer"
          required
          maxRows={25}
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_ANSWER"
              defaultMessage="Odpověď"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_ANSWER',
            defaultMessage: ' ',
          })}
        />
        <FormSelect
          name="projectPhases"
          required
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_PROJECT_PHASE"
              defaultMessage="Dotaz k fázi projektu"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_PROJECT_PHASE',
            defaultMessage: ' ',
          })}
          source={projectPhases}
          tooltipMapper={(o) => o.name}
          multiple={true}
        />
        <FormSelect
          name="regions"
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_REGION"
              defaultMessage="Kraj realizace"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_REGION',
            defaultMessage: ' ',
          })}
          source={regions}
          tooltipMapper={(o) => o.name}
          multiple={true}
        />
        <FormSelect
          name="specificTargets"
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_SPECIFIC_TARGET"
              defaultMessage="Specifický cíl"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_SPECIFIC_TARGET',
            defaultMessage: ' ',
          })}
          source={specificTargets}
          tooltipMapper={(o) => o.name}
          multiple={true}
        />
        <FormSelect
          name="notices"
          required
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_NOTICE"
              defaultMessage="Výzva"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_NOTICE',
            defaultMessage: ' ',
          })}
          source={notices}
          tooltipMapper={(o) => o.name}
          multiple={true}
        />
        <FormSelect
          name="activities"
          required
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_ACTIVITY"
              defaultMessage="Aktivita"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_ACTIVITY',
            defaultMessage: ' ',
          })}
          source={activities}
          tooltipMapper={(o) => o.name}
          multiple={true}
        />
        <FormAutocomplete
          name="tags"
          required
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_TAGS"
              defaultMessage="Klíčová slova"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_TAGS',
            defaultMessage: ' ',
          })}
          source={tags}
          tooltipMapper={(o) => o.name}
          multiple={true}
        />
        <FormCustomField
          name="source"
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_SOURCE"
              defaultMessage="Zdrojový dotaz"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_SOURCE',
            defaultMessage: ' ',
          })}
        >
          {source != null && (
            <Link
              to="/operator/tickets"
              state={{
                action: EvidenceStateAction.SHOW_ITEM,
                data: source.id,
              }}
            >
              <FormattedMessage
                id="KS_O_FAQ_FIELD_LINK_SOURCE"
                defaultMessage="Odkaz"
              />
            </Link>
          )}
        </FormCustomField>
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="KS_O_FAQ_FIELD_PUBLISHING_PANEL_TITLE"
            defaultMessage="Zveřejnění"
          />
        }
      >
        <FormCheckbox
          name="published"
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_PUBLISHED"
              defaultMessage="Publikováno"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_PUBLISHED',
            defaultMessage: ' ',
          })}
          disabled
        />
        <FormDateTimeField
          name="publishedDate"
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_PUBLISHED_DATE"
              defaultMessage="Datum publikování"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_PUBLISHED_DATE',
            defaultMessage: ' ',
          })}
          disabled
        />
        <FormAutocomplete
          name="publisher"
          label={
            <FormattedMessage
              id="KS_O_FAQ_FIELD_LABEL_PUBLISHER"
              defaultMessage="Zveřejnitel"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_FAQ_FIELD_HELP_PUBLISHER',
            defaultMessage: ' ',
          })}
          source={operators}
          tooltipMapper={(o) => o.name}
          disabled
        />
      </FormPanel>
    </>
  );
}
