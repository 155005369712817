import { useRef, useState, useEffect, useContext, Context } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  useEventCallback,
  FormHandle,
  SnackbarContext,
  SnackbarVariant,
  AppSettingsContext,
} from '@eas/common-web';
import { KsAppSettings } from '../../../../common/models';

export function useAppSettings() {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const { settings, update } = useContext(
    (AppSettingsContext as unknown) as Context<
      AppSettingsContext<KsAppSettings>
    >
  );

  const ref = useRef<FormHandle<KsAppSettings>>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);

  const validationSchema = Yup.object<KsAppSettings>().shape({});

  const handleStartEditing = useEventCallback(() => {
    setEditing(true);
  });

  const handleCancelEditing = useEventCallback(() => {
    setEditing(false);
    ref.current?.setFieldValues(settings);
  });

  useEffect(() => {
    ref.current?.setFieldValues(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useEventCallback(async () => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_O_APP_SETTTINGS_MSG_VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });
        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        setLoading(false);
        return;
      }

      await update(ref.current.getFieldValues());

      setEditing(false);
    }
  });

  return {
    loading,
    editing,
    handleSubmit,
    handleStartEditing,
    handleCancelEditing,
    ref,
    validationSchema,
  };
}
