import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormNumberField,
  FormSelect,
  FormAutocomplete,
  FormPanel,
} from '@eas/common-web';
import {
  useRegions,
  useSpecificTargets,
  useActivities,
  useNotices,
  useActiveEditors,
} from '../operator-api';

export function RulesFields() {
  const intl = useIntl();
  const regions = useRegions();
  const specificTargets = useSpecificTargets();
  const activities = useActivities();
  const notices = useNotices();
  const operators = useActiveEditors();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="KS_O_RULES_FIELD_PANEL_TITLE"
          defaultMessage="Pravidlo"
        />
      }
    >
      <FormNumberField
        name="order"
        label={
          <FormattedMessage
            id="KS_O_RULES_FIELD_LABEL_ORDER"
            defaultMessage="Pořadí"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_RULES_FIELD_HELP_ORDER',
          defaultMessage: ' ',
        })}
      />
      <FormSelect
        name="regions"
        label={
          <FormattedMessage
            id="KS_O_RULES_FIELD_LABEL_REGIONS"
            defaultMessage="Kraje"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_RULES_FIELD_HELP_REGIONS',
          defaultMessage: ' ',
        })}
        source={regions}
        tooltipMapper={(o) => o.name}
        multiple={true}
      />
      <FormSelect
        name="specificTargets"
        label={
          <FormattedMessage
            id="KS_O_RULES_FIELD_LABEL_SPECIFIC_TARGETS"
            defaultMessage="Specifické cíle"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_RULES_FIELD_HELP_SPECIFIC_TARGETS',
          defaultMessage: ' ',
        })}
        source={specificTargets}
        tooltipMapper={(o) => o.name}
        multiple={true}
      />
      <FormSelect
        name="activities"
        label={
          <FormattedMessage
            id="KS_O_RULES_FIELD_LABEL_ACTIVITIES"
            defaultMessage="Aktivity"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_RULES_FIELD_HELP_ACTIVITIES',
          defaultMessage: ' ',
        })}
        source={activities}
        tooltipMapper={(o) => o.name}
        multiple={true}
      />
      <FormSelect
        name="notices"
        label={
          <FormattedMessage
            id="KS_O_RULES_FIELD_LABEL_NOTICES"
            defaultMessage="Výzvy"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_RULES_FIELD_HELP_NOTICES',
          defaultMessage: ' ',
        })}
        source={notices}
        tooltipMapper={(o) => o.name}
        multiple={true}
      />
      <FormAutocomplete
        name="assignee"
        required
        label={
          <FormattedMessage
            id="KS_O_RULES_FIELD_LABEL_ASSIGNEE"
            defaultMessage="Řešitel"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_RULES_FIELD_HELP_ASSIGNEE',
          defaultMessage: ' ',
        })}
        source={operators}
        tooltipMapper={(o) => o.name}
      />
    </FormPanel>
  );
}
