import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table, TableToolbarButton } from '@eas/common-web';
import { PublicPage } from '../../../components/public-page/public-page';
import { useTickets } from './tickets-hook';
import { useErrorLoginCounter } from '../../../components/error-login-counter/error-login-counter-hook';

export function RequesterTickets() {
  const intl = useIntl();
  const {
    source,
    columns,
    tableRef,
    handleClickNew,
    handleClickPrint,
    printing,
  } = useTickets();

  // reset error login attempt count
  const { resetCount } = useErrorLoginCounter();

  useEffect(() => {
    resetCount();
  }, [resetCount]);

  return (
    <PublicPage
      title={intl.formatMessage({
        id: 'KS_R_TICKETS_TITLE',
        defaultMessage: 'Moje dotazy',
      })}
    >
      <div>
        <Table
          ref={tableRef}
          tableId="REQUESTER_TICKETS"
          version={2}
          columns={columns}
          source={source}
          height={500}
          showBulkActionButton={false}
          showSelectBox={false}
          showReportButton={false}
          tableName={intl.formatMessage({
            id: 'KS_R_TICKETS_TABLE_TITLE',
            defaultMessage: 'Dotazy',
          })}
          defaultSorts={[
            {
              datakey: 'identifier',
              field: 'identifier',
              order: 'DESC',
              type: 'FIELD',
            },
          ]}
          toolbarProps={{
            before: (
              <>
                <TableToolbarButton
                  label={
                    <>
                      <AddIcon />
                      <FormattedMessage
                        id="KS_R_TICKETS_BTN_NEW_LABEL"
                        defaultMessage="Nový dotaz"
                      />
                    </>
                  }
                  secondary
                  onClick={handleClickNew}
                  tooltip={intl.formatMessage({
                    id: 'KS_R_TICKETS_BTN_NEW',
                    defaultMessage: 'Otevře okno s novým dotazem',
                  })}
                />
                <TableToolbarButton
                  label={
                    printing ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      <PrintIcon />
                    )
                  }
                  onClick={handleClickPrint}
                  tooltip={intl.formatMessage({
                    id: 'KS_R_TICKETS_BTN_PRINT',
                    defaultMessage: 'Export zobrazených záznamů',
                  })}
                />
              </>
            ),
          }}
        />
      </div>
    </PublicPage>
  );
}
