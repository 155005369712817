import { abortableFetch } from '@eas/common-web';
import {
  Resolve,
  RequestAmend,
  Consult,
  Comment,
  ChangeOwner,
  RemoveConsultant,
} from './tickets-types';
import { Requester } from '../../../common/models';

/**
 * API call for state chage to PROCESSING.
 *
 *
 * @param id Id of the item
 */
export function startSolving(id: string) {
  return abortableFetch(`/api/ks/tickets/${id}/start-solving`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  });
}

/**
 * API call for state chage to RESOLVED.
 *
 *
 * @param id Id of the item
 * @param dto Answer
 */
export function resolve(id: string, dto: Resolve) {
  return abortableFetch(`/api/ks/tickets/${id}/resolve`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

/**
 * API call for state chage to RESOLVED.
 *
 *
 * @param id Id of the item
 * @param dto Answer
 */
export function resolveAgain(id: string, dto: Resolve) {
  return abortableFetch(`/api/ks/tickets/${id}/resolve-again`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

/**
 * API call for state chage to AMEND.
 *
 *
 * @param id Id of the item
 * @param dto Request text
 */
export function requestAmend(id: string, dto: RequestAmend) {
  return abortableFetch(`/api/ks/tickets/${id}/request-amendment`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

/**
 * API call for state chage to CONSULT.
 *
 *
 * @param id Id of the item
 * @param dto Request text and consultant ids
 */
export function consult(id: string, dto: Consult) {
  return abortableFetch(`/api/ks/tickets/${id}/add-consultants`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

/**
 * API call for removing consultants.
 *
 *
 * @param id Id of the item
 * @param dto consultant ids
 */
export function removeConsultants(id: string, dto: RemoveConsultant) {
  return abortableFetch(`/api/ks/tickets/${id}/remove-consultants`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

/**
 * API call for comment ticket.
 *
 *
 * @param id Id of the item
 * @param dto text + internal flag
 */
export function comment(id: string, dto: Comment) {
  return abortableFetch(`/api/ks/tickets/${id}/comments`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

/**
 * API call for creating new requester
 *
 * @param requester New requester
 */
export function createRequester(requester: Requester) {
  return abortableFetch(`/api/ks/requesters`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(requester),
  });
}

/**
 * API call for changing owner.
 *
 *
 * @param id Id of the item
 * @param dto Request text and consultant ids
 */
export function changeOwner(id: string, dto: ChangeOwner) {
  return abortableFetch(`/api/ks/tickets/${id}/change-owner`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}
