import React from 'react';
import { useFormSelector, FormNumberField } from '@eas/common-web';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FormTextField,
  FormSubmitButton,
  FormCheckbox,
  FormPanel,
} from '@eas/common-web';
import { KsAppSettings } from '../../../../common/models';
import { FormattedMessage, useIntl } from 'react-intl';

export function AppSettingsFields({
  editing,
  loading,
  handleStartEditing,
  handleCancelEditing,
}: {
  editing: boolean;
  loading: boolean;
  handleStartEditing: () => void;
  handleCancelEditing: () => void;
}) {
  const intl = useIntl();

  const enabled =
    useFormSelector((data: KsAppSettings) =>
      data.fields?.map((field) => field?.enabled)
    ) ?? [];

  return (
    <Grid container>
      <Grid item xs={false} sm={1} />
      <Grid item xs={12} sm={10}>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <h1>
              <FormattedMessage
                id="KS_O_APP_SETTINGS_TITLE"
                defaultMessage="Nastavení aplikace"
              />
            </h1>

            <FormPanel
              label={
                <FormattedMessage
                  id="KS_O_APP_SETTINGS_FIELD_GENERAL_PANEL_TITLE"
                  defaultMessage="Obecné"
                />
              }
            >
              <FormNumberField
                name="respondDeadline"
                label={
                  <FormattedMessage
                    id="KS_O_APP_SETTINGS_FIELD_LABEL_RESPOND_DEADLINE"
                    defaultMessage="Lhůta na odpověď"
                  />
                }
                helpLabel={intl.formatMessage({
                  id: 'KS_O_APP_SETTINGS_FIELD_HELP_RESPOND_DEADLINE',
                  defaultMessage: 'Hodnota je ve dnech',
                })}
              />
              <FormNumberField
                name="amendmentDeadline"
                label={
                  <FormattedMessage
                    id="KS_O_APP_SETTINGS_FIELD_LABEL_AMENDMENT_DEADLINE"
                    defaultMessage="Lhůta na doplnění"
                  />
                }
                helpLabel={intl.formatMessage({
                  id: 'KS_O_APP_SETTINGS_FIELD_HELP_AMENDMENT_DEADLINE',
                  defaultMessage: 'Hodnota je ve dnech',
                })}
              />
            </FormPanel>
            {[0, 1, 2, 3].map((index) => (
              <FormPanel
                key={index}
                label={
                  <FormattedMessage
                    id="KS_O_APP_SETTINGS_FIELD_PANEL_TITLE"
                    defaultMessage="Volitelné pole {num}"
                    values={{ num: index + 1 }}
                  />
                }
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <FormCheckbox
                      name={`fields[${index}].enabled`}
                      label={
                        <FormattedMessage
                          id="KS_O_APP_SETTINGS_FIELD_LABEL_FIELD_ENABLED"
                          defaultMessage="Viditelnost"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id: 'KS_O_APP_SETTINGS_FIELD_HELP_FIELD_ENABLED',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormCheckbox
                      disabled={!enabled[index]}
                      name={`fields[${index}].required`}
                      label={
                        <FormattedMessage
                          id="KS_O_APP_SETTINGS_FIELD_LABEL_FIELD_REQUIRED"
                          defaultMessage="Povinnost vyplnění"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id: 'KS_O_APP_SETTINGS_FIELD_HELP_FIELD_REQUIRED',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormCheckbox
                      disabled={!enabled[index]}
                      name={`fields[${index}].public`}
                      label={
                        <FormattedMessage
                          id="KS_O_APP_SETTINGS_FIELD_LABEL_FIELD_PUBLIC"
                          defaultMessage="Viditelnost pro tazatele"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id: 'KS_O_APP_SETTINGS_FIELD_HELP_FIELD_PUBLIC',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextField
                      required
                      disabled={!enabled[index]}
                      name={`fields[${index}].labelId`}
                      label={
                        <FormattedMessage
                          id="KS_O_APP_SETTINGS_FIELD_LABEL_FIELD_LABEL_ID"
                          defaultMessage="Klíč pro pojmenování"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id: 'KS_O_APP_SETTINGS_FIELD_HELP_FIELD_LABEL_ID',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextField
                      required
                      disabled={!enabled[index]}
                      name={`fields[${index}].labelDefault`}
                      label={
                        <FormattedMessage
                          id="KS_O_APP_SETTINGS_FIELD_LABEL_FIELD_LABEL_DEFAULT"
                          defaultMessage="Výchozí pojmenování"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id: 'KS_O_APP_SETTINGS_FIELD_HELP_FIELD_LABEL_DEFAULT',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextField
                      disabled={!enabled[index]}
                      name={`fields[${index}].helpId`}
                      label={
                        <FormattedMessage
                          id="KS_O_APP_SETTINGS_FIELD_LABEL_FIELD_HELP_ID"
                          defaultMessage="Klíč pro nápovědu"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id: 'KS_O_APP_SETTINGS_FIELD_HELP_FIELD_HELP_ID',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextField
                      disabled={!enabled[index]}
                      name={`fields[${index}].helpDefault`}
                      label={
                        <FormattedMessage
                          id="KS_O_APP_SETTINGS_FIELD_LABEL_FIELD_HELP_DEFAULT"
                          defaultMessage="Výchozí nápověda"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id: 'KS_O_APP_SETTINGS_FIELD_HELP_FIELD_HELP_DEFAULT',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                </Grid>
              </FormPanel>
            ))}

            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box height={50} />
                <Box display="flex" flexDirection="row-reverse">
                  {!editing && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleStartEditing}
                      >
                        <FormattedMessage
                          id="KS_O_PROFILE_BTN_EDIT"
                          defaultMessage="Upravit"
                        />
                      </Button>
                      <Box width={10} />
                    </>
                  )}
                  {editing && (
                    <>
                      <Button
                        variant="contained"
                        disabled={loading}
                        onClick={handleCancelEditing}
                      >
                        <FormattedMessage
                          id="KS_O_PROFILE_BTN_CANCEL"
                          defaultMessage="Zrušit"
                        />
                      </Button>
                      <Box width={10} />
                    </>
                  )}
                  {editing && (
                    <>
                      <FormSubmitButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                        startIcon={
                          loading && (
                            <CircularProgress size="20px" color="inherit" />
                          )
                        }
                      >
                        <FormattedMessage
                          id="KS_O_PROFILE_BTN_SAVE"
                          defaultMessage="Uložit"
                        />
                      </FormSubmitButton>
                      <Box width={10} />
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false} sm={1} />
        </Grid>
      </Grid>
    </Grid>
  );
}
