import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  dialogWrapper: {
    display: 'flex',
    width: 800,
  },
  button: {
    height: 40,
  },
}));
