import React from 'react';
import Grid from '@material-ui/core/Grid';
import { CommentsProps } from '../../tickets-types';
import { TicketComment } from './ticket-comment';
import { CommentDialog } from './ticket-comment-dialog';

export function TicketComments({ id, dialog, commentsSource }: CommentsProps) {
  return (
    <>
      <Grid container>
        {commentsSource.items.map((comment) => (
          <TicketComment key={comment.id} comment={comment} />
        ))}
      </Grid>
      <CommentDialog ref={dialog} id={id} source={commentsSource} />
    </>
  );
}
