import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, FormTextField, FormSubmitButton } from '@eas/common-web';
import { PublicPage } from '../../../components/public-page/public-page';
import { RegistrationExternal } from '../../../common/models';
import { useRegistration } from './registration-hook';
import { KsPublicFormItem } from '../components/ks-public-form-item/ks-public-form-item';

export function Registration() {
  const intl = useIntl();
  const {
    handleSubmit,
    loading,
    formRef,
    validationSchema,
  } = useRegistration();

  return (
    <PublicPage
      title={
        <FormattedMessage
          id="KS_P_REGISTRATION_TITLE"
          defaultMessage="Registrace"
        />
      }
    >
      <Form<RegistrationExternal>
        editing={!loading}
        initialValues={{} as any}
        onSubmit={handleSubmit}
        ref={formRef}
        validationSchema={validationSchema as any}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="firstName"
              helpLabel={intl.formatMessage({
                id: 'KS_P_REGISTRATION_FIELD_HELP_FIRST_NAME',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_P_REGISTRATION_FIELD_LABEL_FIRST_NAME"
                  defaultMessage="Jméno"
                />
              }
            >
              <FormTextField required name="firstName" />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="lastName"
              helpLabel={intl.formatMessage({
                id: 'KS_P_REGISTRATION_FIELD_HELP_LAST_NAME',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_P_REGISTRATION_FIELD_LABEL_LAST_NAME"
                  defaultMessage="Příjmení"
                />
              }
            >
              <FormTextField required name="lastName" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="organisationName"
              helpLabel={intl.formatMessage({
                id: 'KS_P_REGISTRATION_FIELD_HELP_ORGANISATION_NAME',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_P_REGISTRATION_FIELD_LABEL_ORGANISATION_NAME"
                  defaultMessage="Organizace"
                />
              }
            >
              <FormTextField required name="organisationName" />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="organisationIc"
              helpLabel={intl.formatMessage({
                id: 'KS_P_REGISTRATION_FIELD_HELP_ORGANISATION_IC',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_P_REGISTRATION_FIELD_LABEL_ORGANISATION_IC"
                  defaultMessage="IČO"
                />
              }
            >
              <FormTextField required name="organisationIc" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              name="phone"
              helpLabel={intl.formatMessage({
                id: 'KS_P_REGISTRATION_FIELD_HELP_PHONE',
                defaultMessage:
                  'Vložte číslo v mezinárodním formátu +420xxxxxxxxx',
              })}
              label={
                <FormattedMessage
                  id="KS_P_REGISTRATION_FIELD_LABEL_PHONE"
                  defaultMessage="Telefon"
                />
              }
            >
              <FormTextField name="phone" />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="email"
              helpLabel={intl.formatMessage({
                id: 'KS_P_REGISTRATION_FIELD_HELP_EMAIL',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_P_REGISTRATION_FIELD_LABEL_EMAIL"
                  defaultMessage="Email"
                />
              }
            >
              <FormTextField required name="email" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="password"
              helpLabel={intl.formatMessage({
                id: 'KS_P_REGISTRATION_FIELD_HELP_PASSWORD',
                defaultMessage: 'Heslo musí obsahovat 8-64 znaků',
              })}
              label={
                <FormattedMessage
                  id="KS_P_REGISTRATION_FIELD_LABEL_PASSWORD"
                  defaultMessage="Heslo"
                />
              }
            >
              <FormTextField required type="password" name="password" />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="passwordAgain"
              helpLabel={intl.formatMessage({
                id: 'KS_P_REGISTRATION_FIELD_HELP_PASSWORD_AGAIN',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_P_REGISTRATION_FIELD_LABEL_PASSWORD_AGAIN"
                  defaultMessage="Heslo znova"
                />
              }
            >
              <FormTextField required type="password" name="passwordAgain" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box display="flex" flexDirection="row-reverse">
              <FormSubmitButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                disableElevation
                startIcon={
                  loading && <CircularProgress size="20px" color="inherit" />
                }
              >
                <FormattedMessage
                  id="KS_P_REGISTRATION_BTN_SUBMIT"
                  defaultMessage="Registrovat"
                />
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </PublicPage>
  );
}
