import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { useEventCallback, LocaleContext } from '@eas/common-web';
import { useStyles } from './faq-styles';
import { FaqItemProps } from './faq-types';

export function FaqItem({ item }: FaqItemProps) {
  const { locale } = useContext(LocaleContext);

  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const toggleExpand = useEventCallback(() => {
    setExpanded(!expanded);
  });

  return (
    <>
      <Card className={classes.item} raised={true}>
        <CardHeader
          onClick={toggleExpand}
          title={
            <>
              {item.question}

              <ExpandMoreIcon
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
              />
            </>
          }
          titleTypographyProps={{ className: classes.itemHeaderText }}
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="h5" className={classes.itemExpanded}>
              {item.answer}
            </Typography>
          </CardContent>
        </Collapse>

        <CardActions>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {expanded && (
              <Box display="flex" flexDirection="column">
                {item.regions != null && item.regions.length > 0 && (
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.attributeLabel}>
                      <FormattedMessage
                        id="KS_P_FAQ_LABEL_REGION"
                        defaultMessage="Kraj"
                      />
                      :
                    </Typography>
                    <Typography className={classes.attributeText}>
                      {item.regions.map((a) => a.name).join(', ')}
                    </Typography>
                  </Box>
                )}
                {item.specificTargets != null &&
                  item.specificTargets.length > 0 && (
                    <Box display="flex" flexDirection="row">
                      <Typography className={classes.attributeLabel}>
                        <FormattedMessage
                          id="KS_P_FAQ_LABEL_SPECIFIC_TAGRET"
                          defaultMessage="Specifický cíl"
                        />
                        :
                      </Typography>
                      <Typography className={classes.attributeText}>
                        {item.specificTargets.map((a) => a.name).join(', ')}
                      </Typography>
                    </Box>
                  )}
                {item.notices != null && item.notices.length > 0 && (
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.attributeLabel}>
                      <FormattedMessage
                        id="KS_P_FAQ_LABEL_NOTICE"
                        defaultMessage="Výzva"
                      />
                      :
                    </Typography>
                    <Typography className={classes.attributeText}>
                      {item.notices.map((a) => a.name).join(', ')}
                    </Typography>
                  </Box>
                )}
                {item.projectPhases != null && item.projectPhases.length > 0 && (
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.attributeLabel}>
                      <FormattedMessage
                        id="KS_P_FAQ_LABEL_PROJECT_PHASE"
                        defaultMessage="Dotaz k fázi projektu"
                      />
                      :
                    </Typography>
                    <Typography className={classes.attributeText}>
                      {item.projectPhases.map((a) => a.name).join(', ')}
                    </Typography>
                  </Box>
                )}
                {item.activities != null && item.activities.length > 0 && (
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.attributeLabel}>
                      <FormattedMessage
                        id="KS_P_FAQ_LABEL_ACTIVITY"
                        defaultMessage="Aktivita"
                      />
                      :
                    </Typography>
                    <Typography className={classes.attributeText}>
                      {item.activities.map((a) => a.name).join(', ')}
                    </Typography>
                  </Box>
                )}
                {item.publishedDate != null && (
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.attributeLabel}>
                      <FormattedMessage
                        id="KS_P_FAQ_LABEL_CREATED"
                        defaultMessage="Odpověď ze dne"
                      />
                      :
                    </Typography>
                    <Typography className={classes.attributeText}>
                      {format(
                        Date.parse(item.publishedDate),
                        locale.dateFormat
                      )}
                    </Typography>
                  </Box>
                )}
                {item.tags != null && item.tags.length > 0 && (
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.attributeLabel}>
                      <FormattedMessage
                        id="KS_P_FAQ_LABEL_TAGS"
                        defaultMessage="Klíčová slova"
                      />
                      :
                    </Typography>
                    <Typography className={classes.attributeText}>
                      {item.tags.map((a) => a.name).join(', ')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </CardActions>
      </Card>
      <Box height="20px" />
    </>
  );
}
