import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useAuthoredEvidence } from '@eas/common-web';
import { NotificationTemplate } from '../../../common/models';
import { useValidationSchema } from './notification-templates-schema';
import { NotificationTemplatesFields } from './notification-templates-fields';
import { useColumns } from './notification-templates-colums';
import { ExportTags } from '../../../common/export-tags';

export function AdminNotificationTemplates() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const evidence = useAuthoredEvidence<NotificationTemplate>({
    identifier: 'NOTIFICATION_TEMPLATES',
    apiProps: {
      url: '/api/ks/admin/notification-template',
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'KS_O_NOTICFICATION_TEMPLATES_TABLE_TITLE',
        defaultMessage: 'Šablony notifikací',
      }),
      reportTag: ExportTags.NOTIFICATION_TEMPLATE_GRID,
    },
    detailProps: {
      FieldsComponent: NotificationTemplatesFields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
