import * as Yup from 'yup';
import { Faq, Region, Activity, Tag } from '../../../common/models';

export function useValidationSchema() {
  return Yup.object<Faq>().shape({
    question: Yup.string().nullable().required(),
    answer: Yup.string().nullable().required(),
    projectPhases: Yup.array<Region>().nullable().required(),
    activities: Yup.array<Activity>().nullable().required(),
    notices: Yup.array<Region>().nullable().required(),
    tags: Yup.array<Tag>().nullable().required(),
  });
}
