import { useIntl } from 'react-intl';
import { TableColumn, TableCells, TableFilterCells } from '@eas/common-web';
import { KsExportAccessRule } from '../../../../common/models';
import { useExportTemplatesAll } from '../../operator-api';

export function useColumns(): TableColumn<KsExportAccessRule>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'template',
      sortkey: 'template.name',
      filterkey: 'template.id',
      name: intl.formatMessage({
        id: 'KS_A_EXPORT_ACCESS_RULES_COLUMN_TEMPLATE',
        defaultMessage: 'Šablona',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useExportTemplatesAll
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
  ];
}
