import React from 'react';
import { usePersonalEvents } from './personal-events-hook';
import { Table } from '@eas/common-web';

export function PersonalEvents() {
  const { source, columns } = usePersonalEvents();

  return (
    <div>
      <Table
        columns={columns}
        source={source}
        height={300}
        showBulkActionButton={false}
        showSelectBox={false}
        showSearchbar={false}
        showReportButton={false}
        defaultSorts={[
          {
            field: 'created',
            datakey: 'created',
            type: 'FIELD',
            order: 'DESC',
          },
        ]}
      />
    </div>
  );
}
