import { useRef } from 'react';
import { DialogHandle, useEventCallback } from '@eas/common-web';

export function useTicketFields() {
  const newRequesterDialog = useRef<DialogHandle>(null);

  const handleNewRequester = useEventCallback(() => {
    newRequesterDialog.current?.open();
  });

  return {
    newRequesterDialog,
    handleNewRequester,
  };
}
