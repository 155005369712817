import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Panel,
  FormSelect,
  useEventCallback,
  FormContext,
  UserContext,
  useFormSelector,
  FormTextField,
} from '@eas/common-web';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TicketExternalCreate } from '../../../../common/models';
import { useOrganisationTypes } from '../../requester-api';
import { KsPublicFormItem } from '../../../public/components/ks-public-form-item/ks-public-form-item';

export function RequesterPanel() {
  const intl = useIntl();
  const { setFieldValue, getFieldValues } = useContext(FormContext);
  const { user } = useContext(UserContext);
  const organisationTypes = useOrganisationTypes();

  const organisationType = useFormSelector(
    (data: TicketExternalCreate) => data.organisationType
  );
  const isApplicant = organisationType?.code === 'APPLICANT';

  const handleOrgTypeChange = useEventCallback(() => {
    const orgType = getFieldValues().organisationType;

    if (orgType?.code === 'APPLICANT') {
      setFieldValue('applicantName', user?.tenant?.name);
      setFieldValue('applicantIc', user?.tenant?.id);
    } else {
      setFieldValue('applicantName', '');
      setFieldValue('applicantIc', '');
    }
  });

  return (
    <Panel
      label={
        <FormattedMessage
          id="KS_P_TICKET_REQUESTER_PANEL_TITLE"
          defaultMessage="Žadatel"
        />
      }
      sideBorder={true}
    >
      <Box display="flex" flexDirection="row" width="100%" padding="16px">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="organisationType"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_ORGANISATION_TYPE',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_ORGANISATION_TYPE"
                  defaultMessage="Typ organizace"
                />
              }
            >
              <FormSelect
                name="organisationType"
                notifyChange={handleOrgTypeChange}
                source={organisationTypes}
                tooltipMapper={(o) => o.name}
              />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          <>
            <Grid item xs={12} sm={6}>
              <KsPublicFormItem
                name="applicantName"
                required
                helpLabel={
                  !isApplicant
                    ? intl.formatMessage({
                        id: 'KS_R_PROFILE_FIELD_HELP_APPLICANT_NAME',
                        defaultMessage:
                          'Pokud nejste žadatel, vyplňte pole ručně',
                      })
                    : undefined
                }
                label={
                  <FormattedMessage
                    id="KS_R_PROFILE_FIELD_LABEL_APPLICANT_NAME"
                    defaultMessage="Název žadatele"
                  />
                }
              >
                <FormTextField
                  disabled={isApplicant}
                  required={!isApplicant}
                  name="applicantName"
                />
              </KsPublicFormItem>
            </Grid>
            <Grid item xs={12} sm={6}>
              <KsPublicFormItem
                name="applicantIc"
                required
                helpLabel={
                  !isApplicant
                    ? intl.formatMessage({
                        id: 'KS_R_PROFILE_FIELD_HELP_APPLICANT_IC',
                        defaultMessage:
                          'Pokud nejste žadatel, vyplňte pole ručně',
                      })
                    : undefined
                }
                label={
                  <FormattedMessage
                    id="KS_R_PROFILE_FIELD_LABEL_APPLICANT_IC"
                    defaultMessage="IČ žadatele"
                  />
                }
              >
                <FormTextField
                  disabled={isApplicant}
                  required={!isApplicant}
                  name="applicantIc"
                />
              </KsPublicFormItem>
            </Grid>
          </>
        </Grid>
      </Box>
    </Panel>
  );
}
