import { useStaticListSource, DictionaryAutocomplete } from '@eas/common-web';

export function useNotificationEvents() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'NEW_TICKET', name: 'Nový dotaz' },
    { id: 'ASSIGNED_TICKET', name: 'Přidělený dotaz' },
    { id: 'NO_ASSIGNEE', name: 'Řešitel nenalezen' },
    { id: 'REMOVED_TICKET', name: 'Odebraný dotaz' },
    { id: 'RETURNED_TICKET', name: 'Dotaz vrácen k doplnění' },
    { id: 'AMENDED_TICKET', name: 'Dotaz doplněn' },
    { id: 'RESOLVED_TICKET', name: 'Dotaz zodpovězen' },
    { id: 'RESOLVED_AGAIN_TICKET', name: 'Dotaz opětovně zodpovězen' },
    { id: 'ADD_CONSULTANT', name: 'Přidání konzultanta' },
    { id: 'REMOVE_CONSULTANT', name: 'Odebrání konzultanta' },
    { id: 'REASSIGNED_TICKET', name: 'Dotaz přeřazen' },
    { id: 'PASSWORD_RESET', name: 'Žádost o reset hesla' },
    { id: 'PASSWORD_CHANGED', name: 'Heslo změneno' },
    { id: 'COMMENT_ADDED', name: 'Přidán komentář' },
    { id: 'COMMENT_ADDED_REQUESTER', name: 'Přidán komentář pro tazatele' },
    { id: 'CONFIRM_REGISTRATION', name: 'Žádost o registraci' },
    { id: 'OVERDUE_TICKET', name: 'Překročena lhůta' },
    { id: 'OVERDUE_TICKET_OPERATOR', name: 'Překročena lhůta - řešitel' },
    { id: 'OVERDUE_AMENDMENT', name: 'Překročena lhůta pro doplnění' },
    {
      id: 'OVERDUE_AMENDMENT_OPERATOR',
      name: 'Překročena lhůta pro doplnění - řešitel',
    },
    { id: 'OPERATOR_ACCOUNT_DEACTIVATED', name: 'Účet řešitele deaktivován' },
    { id: 'REQUESTER_ACCOUNT_DEACTIVATED', name: 'Účet tazatele deaktivován' },
  ]);
}
