import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
  },
  title: {
    marginTop: 30,
    marginBottom: 60,
    textAlign: 'center',
  },
  warning: {
    marginTop: 5,
    marginBottom: 15,
    textAlign: 'center',
    color: theme.palette.secondary.dark,
  },
  paper: {
    backgroundColor: '#fafcfd',
    borderColor: '#E9F1FB',
    borderWidth: 1,
  },
  subtitle: {
    marginTop: 20,
    marginBottom: 30,
    textAlign: 'center',
  },
  username: {
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 30,
  },
  password: {
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 50,
  },
  button: {
    marginLeft: 30,
    marginRight: 30,
  },
  or: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: 20,
  },
  register: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: 20,
  },
  divider: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    backgroundColor: '#E9F1FB',
  },
  reset: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  help: {
    color: theme.palette.grey[600],
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
}));
