import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Authorized, LoggedIn, UserSettingsProvider } from '@eas/common-web';
import { RequesterTickets } from './tickets/tickets';
import { RequesterProfilePage } from './profile/profile';
import { RequesterTicketsNew } from './tickets/new/tickets-new';
import { RequesterTicketsEdit } from './tickets/edit/tickets-edit';

export function RequesterApp() {
  return (
    <LoggedIn redirectUrl="/">
      <UserSettingsProvider url="/api/ks/user-settings">
        <Authorized permission="REQUESTER">
          <Switch>
            <Route exact path="/requester">
              <Redirect to="/requester/tickets" />
            </Route>
            <Route
              exact
              path="/requester/tickets"
              component={RequesterTickets}
            />
            <Route
              exact
              path="/requester/tickets/new"
              component={RequesterTicketsNew}
            />
            <Route
              exact
              path="/requester/tickets/:id"
              component={RequesterTicketsEdit}
            />
            <Route path="/requester/profile" component={RequesterProfilePage} />
          </Switch>
        </Authorized>
      </UserSettingsProvider>
    </LoggedIn>
  );
}
