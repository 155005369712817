import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useDictionaryEvidence } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { Region } from '../../../common/models';
import { initNewDisabledItem } from '../../../utils/dictionary-item';
import { useValidationSchema } from './regions-schema';

export function OperatorRegions() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<Region>({
    identifier: 'REGIONS',
    apiProps: {
      url: '/api/ks/regions',
    },
    tableProps: {
      tableName: intl.formatMessage({
        id: 'KS_O_REGIONS_TABLE_TITLE',
        defaultMessage: 'Kraje',
      }),
      reportTag: ExportTags.REGION_GRID,
    },
    detailProps: {
      validationSchema,
      initNewItem: initNewDisabledItem,
    },
  });

  return <Evidence {...evidence} />;
}
