import { useRef, useContext, useEffect, useState, Context } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  FormHandle,
  SnackbarContext,
  useEventCallback,
  SnackbarVariant,
  useCrudSource,
  DialogHandle,
  UserContext,
  AppSettingsContext,
  NavigationContext,
  useListSource,
} from '@eas/common-web';
import {
  TicketExternalCreate,
  TicketExternalDetail,
  KsAppSettings,
  OrganisationType,
  TicketCommentExternal,
} from '../../../../common/models';

export function useTicketsEdit(id: string) {
  const intl = useIntl();
  const { user } = useContext(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const source = useCrudSource<TicketExternalDetail>({
    url: '/api/ks/external/tickets',
  });
  const commentsSource = useListSource<TicketCommentExternal>({
    url: `/api/ks/external/tickets/${id}/comments/list`,
  });
  const formRef = useRef<FormHandle<TicketExternalDetail>>(null);
  const commentDialog = useRef<DialogHandle>(null);
  const amendDialog = useRef<DialogHandle>(null);
  const [editing, setEditing] = useState(false);

  const { settings } = useContext(
    (AppSettingsContext as unknown) as Context<
      AppSettingsContext<KsAppSettings>
    >
  );

  const { navigate } = useContext(NavigationContext);

  const fields = settings.fields ?? [];

  const fieldsSchema = fields.map((field) => {
    let schema = Yup.string().nullable();

    if (field?.enabled && field.required && field.labelDefault) {
      schema = schema.required();
    }

    return schema;
  });

  const validationSchema = Yup.object<TicketExternalCreate>().shape({
    applicantName: Yup.string().nullable().required(),
    applicantIc: Yup.string().nullable().required(),
    organisationType: Yup.object<OrganisationType>().nullable().required(),
    projectPhase: Yup.object().nullable().required(),
    region: Yup.object().nullable().required(),
    notice: Yup.object().nullable().required(),
    activity: Yup.object().nullable().required(),
    question: Yup.string().nullable().required().max(5000),
    field1: fieldsSchema[0],
    field2: fieldsSchema[1],
    field3: fieldsSchema[2],
    field4: fieldsSchema[3],
  });

  const handleSubmit = useEventCallback(async (data: TicketExternalDetail) => {
    if (formRef.current != undefined) {
      const errors = await formRef.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_R_TICKETS_EDIT_MSG_VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });

        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        return;
      }
    }

    try {
      await source.update(data, data);

      const message = intl.formatMessage({
        id: 'KS_R_TICKETS_EDIT_MSG_SUCCESS',
        defaultMessage: 'Dotaz byl upraven',
      });
      showSnackbar(message, SnackbarVariant.SUCCESS);
      setEditing(false);
    } catch (err) {
      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_R_TICKETS_EDIT_MSG_ERROR',
            defaultMessage: 'Chyba uložení: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
    }
  });

  const handleEditClick = useEventCallback(() => {
    setEditing(true);
  });

  const handleCommentClick = useEventCallback(() => {
    commentDialog.current?.open();
  });

  const handleFollowupClick = useEventCallback(() => {
    navigate('/requester/tickets/new', false, {
      action: 'followup',
      data: source.data,
    });
  });

  const handleAmendClick = useEventCallback(() => {
    amendDialog.current?.open();
  });

  useEffect(() => {
    source.get(id).then((data) => {
      if (data !== undefined) {
        formRef.current?.setFieldValues(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrgTypeChange = useEventCallback(() => {
    const orgType = formRef.current?.getFieldValues().organisationType;

    if (orgType?.code === 'APPLICANT') {
      formRef.current?.setFieldValue('applicantName', user?.tenant?.name);
      formRef.current?.setFieldValue('applicantIc', user?.tenant?.id);
    } else {
      formRef.current?.setFieldValue('applicantName', '');
      formRef.current?.setFieldValue('applicantIc', '');
    }
  });

  return {
    formRef,
    validationSchema,
    handleSubmit,
    handleEditClick,
    handleAmendClick,
    handleCommentClick,
    handleFollowupClick,
    handleOrgTypeChange,
    loading: source.loading,
    source,
    commentsSource,
    commentDialog,
    amendDialog,
    editing,
  };
}
