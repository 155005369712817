import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@eas/common-web';
import { PublicPage } from '../../../../components/public-page/public-page';
import { useTicketsNew } from './tickets-new-hook';
import { TicketExternalCreate } from '../../../../common/models';
import { RequesterTicketsNewFields } from './tickets-new-fields';

export function RequesterTicketsNew() {
  const {
    loading,
    formRef,
    validationSchema,
    initialValues,
    handleSubmit,
  } = useTicketsNew();

  return (
    <PublicPage
      editHightlight={true}
      title={
        <FormattedMessage
          id="KS_R_TICKETS_NEW_TITLE"
          defaultMessage="Nový dotaz"
        />
      }
    >
      <Form<TicketExternalCreate>
        editing={!loading}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        ref={formRef}
        validationSchema={validationSchema as any}
      >
        <RequesterTicketsNewFields loading={loading} />
      </Form>
    </PublicPage>
  );
}
