import React, { forwardRef, useRef, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import {
  DialogHandle,
  Dialog,
  useEventCallback,
  Form,
  FormHandle,
  FormTextArea,
  SnackbarVariant,
  SnackbarContext,
} from '@eas/common-web';
import { CommentExternal, CommentDialogProps } from '../../tickets-types';
import { useStyles } from '../../tickets-styles';
import { comment } from '../../tickets-api';

export const CommentDialog = forwardRef<DialogHandle, CommentDialogProps>(
  function RequestAmendDialog({ id, source }, dialogRef) {
    const classes = useStyles();
    const intl = useIntl();
    const { showSnackbar } = useContext(SnackbarContext);

    const ref = useRef<FormHandle<CommentExternal>>(null);
    const [loading, setLoading] = useState(false);

    const onConfirm = useEventCallback(() => {
      ref.current?.submitForm();
    });

    const handleSubmit = useEventCallback(async (values: CommentExternal) => {
      try {
        setLoading(true);

        await comment(id!, values).none();

        const message = intl.formatMessage({
          id: 'KS_R_TICKETS_COMMENT_DIALOG_MSG_SUCCESS',
          defaultMessage: 'Komentář byl odeslán',
        });

        showSnackbar(message, SnackbarVariant.SUCCESS);
        setLoading(false);

        source.reset();
      } catch (err) {
        if (err.name !== 'AbortError') {
          const message = intl.formatMessage(
            {
              id: 'KS_R_TICKETS_COMMENT_DIALOG_MSG_ERROR',
              defaultMessage: 'Chyba komentáře: {detail}',
            },
            { detail: err.message }
          );

          showSnackbar(message, SnackbarVariant.ERROR);
          setLoading(false);

          throw err;
        }
      }
    });

    return (
      <Dialog
        disableBackdrop
        ref={dialogRef}
        loading={loading}
        title={
          <FormattedMessage
            id="KS_R_TICKETS_COMMENT_DIALOG_TITLE"
            defaultMessage="Přidat komentář"
          />
        }
        onConfirm={onConfirm}
      >
        {() => (
          <Form<CommentExternal>
            editing={!loading}
            initialValues={{ text: '' }}
            onSubmit={handleSubmit}
            ref={ref}
          >
            <Grid container className={classes.commentDialogWrapper}>
              <Grid item xs={12} sm={12}>
                <FormTextArea
                  required
                  name="text"
                  maxRows={25}
                  label={
                    <FormattedMessage
                      id="KS_R_TICKETS_COMMENT_DIALOG_FIELD_LABEL_TEXT"
                      defaultMessage="Komentář"
                    />
                  }
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Dialog>
    );
  }
);
