import { useRef, useState, useContext } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import {
  abortableFetch,
  FormHandle,
  useEventCallback,
  SnackbarContext,
  SnackbarVariant,
  useDefaultValidationMessages,
} from '@eas/common-web';
import { PasswordChangeDto } from '../../../common/models';
import { OperatorsPwChange } from './operators-types';
import { changeOperatorPassword } from './operators-api';

export function useOperatorsPwDialog(id: string) {
  const intl = useIntl();
  const { isTheSame, meetsRules } = useDefaultValidationMessages();

  const { showSnackbar } = useContext(SnackbarContext);

  const ref = useRef<FormHandle<OperatorsPwChange>>(null);
  const [loading, setLoading] = useState(false);

  const handleConfirm = useEventCallback(async () => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_O_OPERATORS_PW_DIALOG_MSG_VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });

        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        setLoading(false);
        return false;
      }

      ref.current.submitForm();
    }
  });

  const handleSubmit = useEventCallback(async (values: OperatorsPwChange) => {
    try {
      setLoading(true);

      await changeOperatorPassword(id, values.newPassword).none();

      const message = intl.formatMessage({
        id: 'KS_O_OPERATORS_PW_DIALOG_MSG_SUCCESS',
        defaultMessage: 'Heslo bylo úspěšně změneno',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
      setLoading(false);
    } catch (err) {
      if (err.message.includes('oldPassword')) {
        const message = intl.formatMessage({
          id: 'KS_O_OPERATORS_PW_DIALOG_MSG_ERROR',
          defaultMessage: 'Chyba změny hesla: Staré heslo nesedí',
        });

        showSnackbar(message, SnackbarVariant.ERROR);
        setLoading(false);
        throw err;
      } else if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_O_OPERATORS_PW_DIALOG_MSG_GENERAL_ERROR',
            defaultMessage: 'Chyba změny hesla: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);
        setLoading(false);

        throw err;
      }
    }
  });

  const validationSchema = Yup.object<PasswordChangeDto>().shape({
    newPassword: Yup.string()
      .nullable()
      .required()
      .test('password_test', meetsRules, async function (value) {
        if (Yup.string().nullable().required().validateSync(value)) {
          const fetch = validatePassword(value!);
          return await fetch.json();
        } else {
          return true;
        }
      }),
    newPasswordAgain: Yup.string()
      .nullable()
      .required()
      .oneOf([Yup.ref('newPassword')], isTheSame),
  });

  return {
    ref,
    loading,
    validationSchema,
    handleConfirm,
    handleSubmit,
  };
}

export function validatePassword(password: string) {
  return abortableFetch('/api/ks/public/registrations/validate-password', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: password,
  });
}
