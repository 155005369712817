import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import MuiFormLabel from '@material-ui/core/FormLabel';
import { ContextHelp } from '@eas/common-web';
import { KsPublicFormLabelProps } from './ks-public-form-label-types';
import { useStyles } from './ks-public-form-label-styles';

export function KsPublicFormLabel({
  children,
  required,
  helpLabel,
  className,
  error,
}: PropsWithChildren<KsPublicFormLabelProps>) {
  const intl = useIntl();

  if (required) {
    const requiredText = intl.formatMessage({
      id: 'KS_FIELD_REQUIRED',
      defaultMessage: 'Povinné pole',
    });

    if (helpLabel === ' ' || helpLabel == null) {
      helpLabel = requiredText;
    } else {
      helpLabel = requiredText + '<br/>' + helpLabel;
    }
  }

  const { root, labelRequired } = useStyles();
  return (
    <MuiFormLabel
      className={clsx(root, className, { [labelRequired]: required })}
      error={error}
    >
      {children}
      <ContextHelp label={helpLabel} type="HOVER" />
    </MuiFormLabel>
  );
}
