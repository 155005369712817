import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  Evidence,
  useAuthoredEvidence,
  UserContext,
  useEventCallback,
} from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { Ticket, TicketState } from '../../../common/models';
import { Permissions } from '../../../common/permissions';
import { TicketFields } from './tickets-fields';
import { useColumns } from './tickets-columns';
import { TicketsToolbar } from './tickets-toolbar';
import { useValidationSchema } from './tickets-schema';

export function OperatorTickets() {
  const intl = useIntl();
  const { hasPermission } = useContext(UserContext);
  const validationSchema = useValidationSchema();

  const isEditor = hasPermission(Permissions.EDITOR);
  const isAdministrator = hasPermission(Permissions.ADMINISTRATOR);
  const showButton = useEventCallback(({ button }: { button: string }) => {
    if (button === 'REMOVE') {
      return isAdministrator;
    } else {
      return isEditor || isAdministrator;
    }
  });

  const evidence = useAuthoredEvidence<Ticket>({
    identifier: 'TICKETS',
    version: 6,
    apiProps: {
      url: '/api/ks/tickets',
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'KS_O_TICKETS_TABLE_TITLE',
        defaultMessage: 'Dotazy',
      }),
      reportTag: ExportTags.TICKET_GRID,
      defaultSorts: [
        {
          field: 'identifier',
          datakey: 'identifier',
          order: 'DESC',
          type: 'FIELD',
        },
      ],
    },
    detailProps: {
      FieldsComponent: TicketFields,
      validationSchema,
      toolbarProps: {
        after: <TicketsToolbar />,
        showButton,
      },
      initNewItem: () => ({
        id: uuidv4(),
        state: TicketState.NEW,
        formatting: true,
      }),
    },
  });

  return <Evidence {...evidence} />;
}
