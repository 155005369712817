import { useContext, useState, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  useEventCallback,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
  AbortableFetch,
  FormHandle,
  NavigationContext,
  useDefaultValidationMessages,
} from '@eas/common-web';
import { RegistrationExternal, RegistrationDto } from '../../../common/models';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

/**
 * Calls registration API.
 *
 * @param item Object to save
 */
export function callSubmitRegistration(dto: RegistrationDto) {
  return abortableFetch('/api/ks/public/registrations', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(dto),
  });
}

export function validateEmail(email: string) {
  return abortableFetch('/api/ks/public/registrations/validate-email', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: email,
  });
}

export function validatePassword(password: string) {
  return abortableFetch('/api/ks/public/registrations/validate-password', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: password,
  });
}

export function useRegistration() {
  const intl = useIntl();
  const {
    isTheSame,
    meetsRules,
    meetsFormat,
    doesNotExist,
  } = useDefaultValidationMessages();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);

  const [loading, setLoading] = useState(false);
  const fetch = useRef<AbortableFetch | null>(null);
  const formRef = useRef<FormHandle<RegistrationExternal>>(null);

  const emailSchema = Yup.string().nullable().required().email(meetsFormat);

  const passwordSchema = Yup.string().nullable().required();

  const validationSchema = Yup.object<RegistrationExternal>().shape({
    firstName: Yup.string().nullable().required(),
    lastName: Yup.string().nullable().required(),
    organisationName: Yup.string().nullable().required(),
    organisationIc: Yup.string().nullable().required(),
    email: emailSchema.test('email_test', doesNotExist, async function (value) {
      if (emailSchema.validateSync(value)) {
        const fetch = validateEmail(value!);
        return await fetch.json();
      } else {
        return true;
      }
    }),
    phone: Yup.string()
      .nullable()
      .matches(
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
        meetsFormat
      ),
    password: passwordSchema.test('password_test', meetsRules, async function (
      value
    ) {
      if (Yup.string().nullable().required().validateSync(value)) {
        const fetch = validatePassword(value!);
        return await fetch.json();
      } else {
        return true;
      }
    }),
    passwordAgain: Yup.string()
      .nullable()
      .required()
      .oneOf([Yup.ref('password')], isTheSame),
  });

  const submitRegistration = useEventCallback(
    async (item: RegistrationExternal) => {
      try {
        setLoading(true);

        const captcha = await executeRecaptcha!('registration');

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callSubmitRegistration({ registration: item, captcha });

        await fetch.current.none();

        unstable_batchedUpdates(() => {
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);

        if (err.name !== 'AbortError') {
          const message = intl.formatMessage(
            {
              id: 'KS_P_REGISTRATION_MSG_ERROR',
              defaultMessage: 'Chyba registrace: {detail}',
            },
            { detail: err.message }
          );

          showSnackbar(message, SnackbarVariant.ERROR);

          throw err;
        }
        return undefined;
      }
    }
  );

  const handleSubmit = useEventCallback(async (data: RegistrationExternal) => {
    if (formRef.current != undefined) {
      const errors = await formRef.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_P_REGISTRATION_MSG_VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });

        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        return;
      }
    }

    await submitRegistration(data);

    navigate('/registration-complete', true);
  });

  return {
    handleSubmit,
    validationSchema,
    formRef,
    loading,
  };
}
