import { abortableFetch } from '@eas/common-web';
import { CommentExternal, Amend } from './tickets-types';

/**
 * Calls for comment ticket.
 *
 *
 * @param id Id of the item
 * @param dto text + internal flag
 */
export function comment(id: string, dto: CommentExternal) {
  return abortableFetch(`/api/ks/external/tickets/${id}/comments`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}

/**
 * Calls for returning the ticket back to operator.
 *
 *
 * @param id Id of the item
 * @param dto Request text
 */
export function amend(id: string, dto: Amend) {
  return abortableFetch(`/api/ks/external/tickets/${id}/amend`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}
