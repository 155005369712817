import * as Yup from 'yup';
import {
  TicketState,
  Region,
  Activity,
  Ticket,
  Requester,
  OrganisationType,
  ProjectPhase,
  KsAppSettings,
} from '../../../common/models';
import { useContext, Context } from 'react';
import { AppSettingsContext } from '@eas/common-web';

export function useValidationSchema() {
  const { settings } = useContext(
    (AppSettingsContext as unknown) as Context<
      AppSettingsContext<KsAppSettings>
    >
  );
  const fields = settings.fields ?? [];

  const fieldsSchema = fields.map((field) => {
    let schema = Yup.string().nullable();

    if (field?.enabled && field.required && field.labelDefault) {
      schema = schema.required();
    }

    return schema;
  });

  return Yup.object<Ticket>().shape({
    applicantName: Yup.string().nullable().required(),
    applicantIc: Yup.string().nullable().required(),
    question: Yup.string().nullable().required(),
    state: Yup.mixed<TicketState>().nullable().required(),
    requester: Yup.object<Requester>().nullable().required(),

    organisationType: Yup.object<OrganisationType>().nullable().required(),
    projectPhase: Yup.object<ProjectPhase>().nullable().required(),

    region: Yup.object<Region>().nullable().required(),

    notice: Yup.object<Region>().nullable().required(),
    activity: Yup.object<Activity>().nullable().required(),
    field1: fieldsSchema[0],
    field2: fieldsSchema[1],
    field3: fieldsSchema[2],
    field4: fieldsSchema[3],
  });
}
