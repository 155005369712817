import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useAuthoredEvidence } from '@eas/common-web';
import { KsExportAccessRule } from '../../../../common/models';
import { AccessRulesFields } from './access-rules-fields';
import { useValidationSchema } from './access-rules-schema';
import { ExportTags } from '../../../../common/export-tags';

import { useColumns } from './access-rules-columns';

export function ExportAccessRules() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useAuthoredEvidence<KsExportAccessRule>({
    identifier: 'EXPORT_ACCESS_RULES',
    apiProps: {
      url: '/api/ks/export/access-rules',
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'KS_A_EXPORT_ACCESS_RULES_TITLE',
        defaultMessage: 'Pravidla přístupu k exportům',
      }),
      reportTag: ExportTags.EXPORT_ACCESS_RULES_GRID,
    },
    detailProps: {
      FieldsComponent: AccessRulesFields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
