import React, { useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '@eas/common-web';
import Link from '@material-ui/core/Link';
import { Menubar } from '../../components/menubar/menubar';
import { MenubarItem } from '../../components/menubar/menubar-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Langbar } from '../langbar/langbar';
import { useStyles } from './requster-menu-styles';
import Button from '@material-ui/core/Button';

function RequesterMenuActions() {
  const { user, logout } = useContext(UserContext);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link href="/requester/profile">
        <h2 className={classes.name}>{user.name}</h2>
      </Link>
      <div className={classes.actions}>
        <Langbar />
        <Button className={classes.button} onClick={() => logout()}>
          <FormattedMessage id="KS_MENU_BTN_LOGOUT" defaultMessage="Odhlásit" />
        </Button>
      </div>
    </div>
  );
}

export function RequesterMenu() {
  const history = useHistory();
  const intl = useIntl();

  const publicMenuItems: MenubarItem[] = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'KS_P_MENU_TICKETS',
          defaultMessage: 'Moje Dotazy',
        }),
        onClick: () => history.push('/requester/tickets'),
      },
      {
        label: intl.formatMessage({
          id: 'KS_P_MENU_FAQ',
          defaultMessage: 'Časté dotazy IROP',
        }),
        onClick: () => history.push('/faq'),
      },
    ],
    [history, intl]
  );

  return (
    <Menubar items={publicMenuItems} ActionComponent={RequesterMenuActions} />
  );
}
