import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Panel,
  FormTextArea,
  useFormSelector,
  FormRichEditor,
} from '@eas/common-web';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { KsPublicFormItem } from '../../../public/components/ks-public-form-item/ks-public-form-item';
import { TicketExternalCreate } from '../../../../common/models';

export function AnswerPanel() {
  const formatting = useFormSelector(
    (data: TicketExternalCreate) => data.formatting
  );
  return (
    <Panel
      label={
        <FormattedMessage
          id="KS_P_TICKET_ANSWER_PROJECT_TITLE"
          defaultMessage="Odpověď na Váš dotaz"
        />
      }
      sideBorder={true}
    >
      <Box display="flex" flexDirection="row" width="100%" padding="16px">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <KsPublicFormItem name="answer">
              {formatting ? (
                <FormRichEditor disabled required name="answer" />
              ) : (
                <FormTextArea disabled maxRows={25} required name="answer" />
              )}
            </KsPublicFormItem>
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
}
