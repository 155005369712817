import {
  useEventCallback,
  TableColumn,
  Params,
  TableCells,
  TableFilterCells,
} from '@eas/common-web';
import { useGeneralPrintHook } from '../printing/requester-print-hook';
import { FaqExternalDetail } from '../../../common/models';
import { useIntl } from 'react-intl';
import {
  useProjectPhasesAll,
  useRegionsAll,
  useSpecificTargetsAll,
  useNoticesAll,
  useActivitiesAll,
  useTagsAll,
} from '../../operator/operator-api';

export function usePrintHook(params: Params) {
  const columns = useColumns();

  function serializeColumns(columns: TableColumn<any>[]) {
    return columns.map((column) => ({
      name: column.name,
      datakey: column.datakey,
      displaykey: column.displaykey,
      width: column.width,
      visible: column.visible,
      cellComponentName:
        column.CellComponent.displayName ?? column.CellComponent.name,
      valueMapperName:
        column.valueMapper?.displayName ?? column.valueMapper?.name,
      valueMapperData: (column.valueMapper as any)?.data,
    }));
  }

  const provideData = useEventCallback(() => {
    return {
      params,
      columns: serializeColumns(columns),
      title: 'Faq',
    };
  });

  return useGeneralPrintHook(
    '/api/ks/external/faq/print',
    '/api/ks/external/faq/print',
    provideData
  );
}

function useColumns(): TableColumn<FaqExternalDetail>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'projectPhases',
      sortkey: 'projectPhases.concatenated',
      filterkey: 'projectPhases.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_PROJECT_PHASE',
        defaultMessage: 'Dotaz k fázi projektu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useProjectPhasesAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
      visible: true,
    },
    {
      datakey: 'regions',
      sortkey: 'regions.concatenated',
      filterkey: 'regions.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_REGION',
        defaultMessage: 'Kraj',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useRegionsAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
      visible: true,
    },
    {
      datakey: 'specificTargets',
      sortkey: 'specificTargets.concatenated',
      filterkey: 'specificTargets.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_SPECIFIC_TARGET',
        defaultMessage: 'Specifický cíl',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useSpecificTargetsAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
      visible: true,
    },
    {
      datakey: 'notices',
      sortkey: 'notices.concatenated',
      filterkey: 'notices.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_NOTICE',
        defaultMessage: 'Výzva',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useNoticesAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
      visible: true,
    },
    {
      datakey: 'activities',
      sortkey: 'activities.concatenated',
      filterkey: 'activities.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_ACTIVITY',
        defaultMessage: 'Aktivita',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useActivitiesAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
      visible: true,
    },
    {
      datakey: 'tags',
      sortkey: 'tags.concatenated',
      filterkey: 'tags.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_TAGS',
        defaultMessage: 'Klíčová slova',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useTagsAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
      visible: true,
    },
    {
      datakey: 'publishedDate',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_PUBLISHED_DATE',
        defaultMessage: 'Datum zveřejnění',
      }),
      width: 180,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      visible: true,
    },
    {
      datakey: 'question',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_QUESTION',
        defaultMessage: 'Otázka',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      visible: true,
    },
    {
      datakey: 'answer',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_QUESTION',
        defaultMessage: 'Odpověď',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      valueMapper: truncateColumnMapper,
      visible: true,
    },
  ];
}

function truncateColumnMapper({ value }: { value: string }) {
  return value != null ? value.substr(0, 32000) : '';
}

truncateColumnMapper.displayName = 'truncateColumnMapper';
truncateColumnMapper.data = 32000;
