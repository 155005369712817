import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { PasswordResetRequestPage } from './password-reset-request';

export function PasswordResetRequestWrapper() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LeLOd0ZAAAAAASD3guled-gDeL0gg4YTvqFFQiT"
      language="cs"
    >
      <PasswordResetRequestPage />
    </GoogleReCaptchaProvider>
  );
}
