import { abortableFetch } from '@eas/common-web';
import { ProfileUpdate, PasswordChangeDto } from '../../../common/models';

/**
 * Fetches data for operator profile
 *
 * @param api API url
 */
export function fetchProfile() {
  return abortableFetch('/api/ks/external/profile', {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
}

/**
 * Calls update API method.
 *
 * @param item Object to save
 */
export function updateProfile(item: ProfileUpdate) {
  return abortableFetch('/api/ks/external/profile', {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify(item),
  });
}

/**
 * Calls update API method.
 *
 * @param item Object to save
 */
export function changePassword(dto: PasswordChangeDto) {
  return abortableFetch('/api/ks/external/profile/password', {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify(dto),
  });
}
