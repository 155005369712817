import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelect, FormPanel } from '@eas/common-web';
import { usePriorityTargets } from '../operator-api';

export function SpecificTargetsFields() {
  const intl = useIntl();
  const priorityTargets = usePriorityTargets();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="KS_O_SPECIFIC_TARGETS_FIELD_PANEL_TITLE"
          defaultMessage="Specifický cíl"
        />
      }
    >
      <FormSelect
        name="parent"
        required
        label={
          <FormattedMessage
            id="KS_O_SPECIFIC_TARGETS_FIELD_LABEL_PARENT"
            defaultMessage="Prioritní osa"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_SPECIFIC_TARGETS_FIELD_HELP_PARENT',
          defaultMessage: ' ',
        })}
        source={priorityTargets}
        tooltipMapper={(o) => o.name}
      />
    </FormPanel>
  );
}
