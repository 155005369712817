import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useDictionaryEvidence } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { PriorityTarget } from '../../../common/models';
import { initNewDisabledItem } from '../../../utils/dictionary-item';
import { useValidationSchema } from './priority-targets-schema';

export function OperatorPriorityTargets() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<PriorityTarget>({
    identifier: 'PRIORITY_TARGETS',
    apiProps: {
      url: '/api/ks/priority-targets',
    },
    tableProps: {
      tableName: intl.formatMessage({
        id: 'KS_O_PRIORITY_TARGETS_TABLE_TITLE',
        defaultMessage: 'Prioritní osy',
      }),
      reportTag: ExportTags.PRIORITY_TARGET_GRID,
    },
    detailProps: {
      validationSchema,
      initNewItem: initNewDisabledItem,
    },
  });

  return <Evidence {...evidence} />;
}
