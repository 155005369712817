import { useLocalStorage, useEventCallback } from '@eas/common-web';

export function useErrorLoginCounter() {
  const [errorLoginAttemts, setErrorLoginAttemts] = useLocalStorage(
    'error-login-attempts',
    0
  );

  const increaseCount = useEventCallback(() => {
    setErrorLoginAttemts(errorLoginAttemts + 1);
    return errorLoginAttemts + 1;
  });

  const resetCount = useEventCallback(() => {
    setErrorLoginAttemts(0);
  });

  return {
    increaseCount,
    resetCount,
  };
}
