import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PublicPage } from '../../../components/public-page/public-page';

export const ahoj = () => {};

export function PasswordResetRequestComplete() {
  return (
    <PublicPage
      title={
        <FormattedMessage
          id="KS_P_PW_RESET_REQUEST_COMPLETE_TITLE"
          defaultMessage="Žádost o změnu hesla byla dokončena"
        />
      }
    >
      <FormattedMessage
        id="KS_P_PW_RESET_REQUEST_COMPLETE_SUBTITLE"
        defaultMessage="Potvrdte žádost kliknutím na zaslaný odkaz."
      />
    </PublicPage>
  );
}
