import { useIntl } from 'react-intl';
import { TableColumn, TableCells, TableFilterCells } from '@eas/common-web';
import { Faq } from '../../../common/models';
import {
  useRegionsAll,
  useSpecificTargetsAll,
  useActivitiesAll,
  useNoticesAll,
  useProjectPhasesAll,
  useTagsAll,
} from '../operator-api';
import { useOperators } from './faq-api';

export function useColumns(): TableColumn<Faq>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'projectPhases',
      sortkey: 'projectPhases.concatenated',
      filterkey: 'projectPhases.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_PROJECT_PHASE',
        defaultMessage: 'Dotaz k fázi projektu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useProjectPhasesAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'regions',
      sortkey: 'regions.concatenated',
      filterkey: 'regions.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_REGION',
        defaultMessage: 'Kraj',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useRegionsAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'specificTargets',
      sortkey: 'specificTargets.concatenated',
      filterkey: 'specificTargets.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_SPECIFIC_TARGET',
        defaultMessage: 'Specifický cíl',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useSpecificTargetsAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'notices',
      sortkey: 'notices.concatenated',
      filterkey: 'notices.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_NOTICE',
        defaultMessage: 'Výzva',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useNoticesAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'activities',
      sortkey: 'activities.concatenated',
      filterkey: 'activities.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_ACTIVITY',
        defaultMessage: 'Aktivita',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useActivitiesAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'tags',
      sortkey: 'tags.concatenated',
      filterkey: 'tags.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_TAGS',
        defaultMessage: 'Klíčová slova',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useTagsAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'published',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_PUBLISHED',
        defaultMessage: 'Zveřejněný',
      }),
      width: 120,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'publishedDate',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_PUBLISHED_DATE',
        defaultMessage: 'Datum zveřejnění',
      }),
      width: 180,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'publisher',
      sortkey: 'publisher.name',
      filterkey: 'publisher.id',
      name: intl.formatMessage({
        id: 'KS_O_FAQ_COLUMN_PUBLISHER',
        defaultMessage: 'Zveřejnitel',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useOperators
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
  ];
}
