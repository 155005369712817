import React, { RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { Panel, DialogHandle, ListSource } from '@eas/common-web';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TicketComments } from './comments/ticket-comments';
import { TicketCommentExternal } from '../../../../common/models';

export function CommentsPanel({
  id,
  commentDialog,
  commentsSource,
}: {
  id: string;
  commentDialog: RefObject<DialogHandle>;
  commentsSource: ListSource<TicketCommentExternal>;
}) {
  return (
    <Panel
      label={
        <FormattedMessage
          id="KS_P_TICKET_COMMENT_PANEL_TITLE"
          defaultMessage="Komentáře"
        />
      }
      sideBorder={true}
    >
      <Box display="flex" flexDirection="row" width="100%" padding="16px">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <TicketComments
              id={id}
              dialog={commentDialog}
              commentsSource={commentsSource}
            />
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
}
