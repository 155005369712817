import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useDictionaryEvidence } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { ProjectPhase } from '../../../common/models';
import { initNewDisabledItem } from '../../../utils/dictionary-item';
import { useValidationSchema } from './project-phases-schema';

export function OperatorProjectPhases() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<ProjectPhase>({
    identifier: 'PROJECT_PHASES',
    apiProps: {
      url: '/api/ks/project-phases',
    },
    tableProps: {
      tableName: intl.formatMessage({
        id: 'KS_O_PROJECT_PHASES_TABLE_TITLE',
        defaultMessage: 'Fáze projektu',
      }),
      reportTag: ExportTags.PROJECT_PHASE_GRID,
    },
    detailProps: {
      validationSchema,
      initNewItem: initNewDisabledItem,
    },
  });

  return <Evidence {...evidence} />;
}
