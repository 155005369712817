import { useRef, useContext, Context } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
  FormHandle,
  SnackbarContext,
  useEventCallback,
  SnackbarVariant,
  NavigationContext,
  useCrudSource,
  AppSettingsContext,
} from '@eas/common-web';
import {
  TicketExternalCreate,
  TicketExternalDetail,
  KsAppSettings,
  OrganisationType,
} from '../../../../common/models';

export function useTicketsNew() {
  const source = useCrudSource<TicketExternalDetail>({
    url: '/api/ks/external/tickets',
  });

  const intl = useIntl();
  const { navigate, stateAction } = useContext(NavigationContext);
  const formRef = useRef<FormHandle<TicketExternalCreate>>(null);
  const { showSnackbar } = useContext(SnackbarContext);

  const { settings } = useContext(
    (AppSettingsContext as unknown) as Context<
      AppSettingsContext<KsAppSettings>
    >
  );
  const fields = settings.fields ?? [];

  const fieldsSchema = fields.map((field) => {
    let schema = Yup.string().nullable();

    if (field?.enabled && field.required && field.labelDefault) {
      schema = schema.required();
    }

    return schema;
  });

  const validationSchema = Yup.object<TicketExternalCreate>().shape({
    applicantName: Yup.string().nullable().required(),
    applicantIc: Yup.string().nullable().required(),
    organisationType: Yup.object<OrganisationType>().nullable().required(),
    projectPhase: Yup.object().nullable().required(),
    region: Yup.object().nullable().required(),
    notice: Yup.object().nullable().required(),
    activity: Yup.object().nullable().required(),
    question: Yup.string().nullable().required().max(5000),
    field1: fieldsSchema[0],
    field2: fieldsSchema[1],
    field3: fieldsSchema[2],
    field4: fieldsSchema[3],
  });

  const handleSubmit = useEventCallback(async (data: TicketExternalCreate) => {
    if (formRef.current != undefined) {
      const errors = await formRef.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_R_TICKETS_NEW_MSG_VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });

        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        return;
      }
    }

    try {
      await source.create(data as TicketExternalDetail);

      const message = intl.formatMessage({
        id: 'KS_R_TICKETS_NEW_MSG_SUCCESS',
        defaultMessage: 'Dotaz byl odeslán',
      });
      showSnackbar(message, SnackbarVariant.SUCCESS);
      navigate('/requester/tickets');
    } catch (err) {
      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_R_TICKETS_NEW_MSG_ERROR',
            defaultMessage: 'Chyba odeslání: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }
    }
  });

  const initialValues = {
    state: 'NEW',
    formatting: true,
  } as any;

  if (stateAction?.action === 'followup') {
    const parent = stateAction.data;
    initialValues.parent = parent;

    initialValues.projectNumber = parent.projectNumber;
    initialValues.projectName = parent.projectName;
    initialValues.organisationType = parent.organisationType;
    initialValues.projectPhase = parent.projectPhase;
    initialValues.region = parent.region;
    initialValues.place = parent.place;
    initialValues.notice = parent.notice;
    initialValues.specificTarget = parent.specificTarget;
    initialValues.activity = parent.activity;
    initialValues.applicantName = parent.applicantName;
    initialValues.applicantIc = parent.applicantIc;

    initialValues.field1 = parent.field1;
    initialValues.field2 = parent.field2;
    initialValues.field3 = parent.field3;
    initialValues.field4 = parent.field4;
  }

  return {
    loading: source.loading,
    formRef,
    validationSchema,
    initialValues,
    handleSubmit,
  };
}
