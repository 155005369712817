import React from 'react';
import Grid from '@material-ui/core/Grid';
import { CommentsProps } from '../tickets-types';
import { useComments } from './ticket-comments-hook';
import { TicketComment } from './ticket-comment';
import { useStyles } from '../tickets-styles';

export function TicketComments({ id, ticket }: CommentsProps) {
  const classes = useStyles();

  const { source } = useComments(id);

  return (
    <Grid container className={classes.commentsWrapper}>
      {source.items.map((comment) => (
        <TicketComment key={comment.id} comment={comment} ticket={ticket} />
      ))}
    </Grid>
  );
}
