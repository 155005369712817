import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
  },
  title: {
    marginTop: 30,
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
  },
  warning: {
    marginTop: 5,
    marginBottom: 15,
    textAlign: 'center',
    color: theme.palette.secondary.dark,
  },
  subtitle: {
    marginTop: 20,
    marginBottom: 60,
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 400,
  },
  paper: {
    height: 400,
    backgroundColor: '#fafcfd',
    borderColor: '#E9F1FB',
  },
  internalTitle: {
    marginTop: 20,
    marginBottom: 60,
    color: theme.palette.primary.main,
    alignSelf: 'center',
    fontWeight: 400,
  },
  internalLogo: {
    width: 70,
    height: 70,
    marginBottom: 60,
    alignSelf: 'center',
  },
  internalButton: {
    marginLeft: 30,
    marginRight: 30,
  },
  externalTitle: {
    marginTop: 20,
    marginBottom: 30,
    color: theme.palette.primary.main,
    alignSelf: 'center',
    fontWeight: 400,
  },
  externalUsername: {
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 30,
  },
  externalPassword: {
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 50,
  },
  externalButton: {
    marginLeft: 30,
    marginRight: 30,
  },
  or: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: 20,
  },
  reset: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
}));
