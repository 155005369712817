import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
  Panel,
  FormSelect,
  FormCustomField,
  FormTextField,
  FormDateTimeField,
  useFormSelector,
} from '@eas/common-web';
import { useTicketStates } from '../../requester-api';
import { KsPublicFormItem } from '../../../public/components/ks-public-form-item/ks-public-form-item';
import { TicketExternalDetail, TicketState } from '../../../../common/models';

export function StatePanel({ existing }: { existing: boolean }) {
  const intl = useIntl();
  const states = useTicketStates();

  const state = useFormSelector((data: TicketExternalDetail) => data.state);

  return (
    <Panel
      label={
        <FormattedMessage
          id="KS_P_TICKET_STATE_PANEL_TITLE"
          defaultMessage="Dotaz"
        />
      }
      sideBorder={true}
    >
      <Box display="flex" flexDirection="row" width="100%" padding="16px">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              name="state"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_STATE',
                defaultMessage: 'Aktuální stav dotazu',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_STATE"
                  defaultMessage="Stav"
                />
              }
            >
              <FormSelect
                disabled
                name="state"
                source={states}
                tooltipMapper={(o) => o.name}
                valueIsId
              />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              name="identifier"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_IDENTIFIER',
                defaultMessage: 'Číslo dotazu přiřazené systémem',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_IDENTIFIER"
                  defaultMessage="Číslo"
                />
              }
            >
              {!existing ? (
                <FormCustomField disabled name="identifier">
                  <Box fontSize="16px">
                    <FormattedMessage
                      id="KS_R_TICKETS_FIELD_LABEL_IDENTIFIER_NEW"
                      defaultMessage="Číslo dotazu bude přiřazeno po jeho odeslání"
                    />
                  </Box>
                </FormCustomField>
              ) : (
                <FormTextField disabled name="identifier" />
              )}
            </KsPublicFormItem>
          </Grid>

          {state === TicketState.RESOLVED && (
            <Grid item xs={12} sm={6}>
              <KsPublicFormItem
                name="resolveDate"
                helpLabel={intl.formatMessage({
                  id: 'KS_R_TICKETS_FIELD_HELP_RESOLVE_DATE',
                  defaultMessage: ' ',
                })}
                label={
                  <FormattedMessage
                    id="KS_R_TICKETS_FIELD_LABEL_RESOLVE_DATE"
                    defaultMessage="Čas odpovědi"
                  />
                }
              >
                <FormDateTimeField
                  name="resolveDate"
                  disabled
                  label={
                    <FormattedMessage
                      id="KS_R_TICKETS_FIELD_LABEL_RESOLVE_DATE"
                      defaultMessage="Čas odpovědi"
                    />
                  }
                  helpLabel={intl.formatMessage({
                    id: 'KS_R_TICKET_FIELD_HELP_RESOLVE_DATE',
                    defaultMessage: ' ',
                  })}
                />
              </KsPublicFormItem>
            </Grid>
          )}
          {state === TicketState.RESOLVED && (
            <Grid item xs={12} sm={6}>
              <KsPublicFormItem
                name="resolveName"
                helpLabel={intl.formatMessage({
                  id: 'KS_R_TICKETS_FIELD_HELP_RESOLVE_NAME',
                  defaultMessage: ' ',
                })}
                label={
                  <FormattedMessage
                    id="KS_R_TICKETS_FIELD_LABEL_RESOLVE_NAME"
                    defaultMessage="Autor odpovědi"
                  />
                }
              >
                <FormTextField
                  name="resolveName"
                  disabled
                  label={
                    <FormattedMessage
                      id="KS_R_TICKETS_FIELD_LABEL_RESOLVE_NAME"
                      defaultMessage="Autor odpovědi"
                    />
                  }
                  helpLabel={intl.formatMessage({
                    id: 'KS_R_TICKET_FIELD_HELP_RESOLVE_NAME',
                    defaultMessage: ' ',
                  })}
                />
              </KsPublicFormItem>
            </Grid>
          )}
        </Grid>
      </Box>
    </Panel>
  );
}
