import { useIntl } from 'react-intl';
import { TableColumn, TableCells, TableFilterCells } from '@eas/common-web';
import { Rule } from '../../../common/models';
import {
  useRegionsAllAuto,
  useSpecificTargetsAllAuto,
  useActivitiesAllAuto,
  useNoticesAllAuto,
  useOperatorsAll,
} from '../operator-api';

export function useColumns(): TableColumn<Rule>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'order',
      name: intl.formatMessage({
        id: 'KS_O_RULES_COLUMN_ORDER',
        defaultMessage: 'Pořadí',
      }),
      width: 200,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'regions',
      sortkey: 'regions.concatenated',
      filterkey: 'regions.id',
      name: intl.formatMessage({
        id: 'KS_O_RULES_COLUMN_REGIONS',
        defaultMessage: 'Kraje',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleAutocompleteCellFactory(
        useRegionsAllAuto
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'specificTargets',
      sortkey: 'specificTargets.concatenated',
      filterkey: 'specificTargets.id',
      name: intl.formatMessage({
        id: 'KS_O_RULES_COLUMN_SPECIFIC_TARGETS',
        defaultMessage: 'Specifické cíle',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleAutocompleteCellFactory(
        useSpecificTargetsAllAuto
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'activities',
      sortkey: 'activities.concatenated',
      filterkey: 'activities.id',
      name: intl.formatMessage({
        id: 'KS_O_RULES_COLUMN_ACTIVITIES',
        defaultMessage: 'Aktivity',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleAutocompleteCellFactory(
        useActivitiesAllAuto
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'notices',
      sortkey: 'notices.concatenated',
      filterkey: 'notices.id',
      name: intl.formatMessage({
        id: 'KS_O_RULES_COLUMN_NOTICES',
        defaultMessage: 'Výzvy',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleAutocompleteCellFactory(
        useNoticesAllAuto
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'assignee',
      sortkey: 'assignee.name',
      filterkey: 'assignee.id',
      name: intl.formatMessage({
        id: 'KS_O_RULES_COLUMN_ASSIGNEE',
        defaultMessage: 'Řešitel',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleAutocompleteCellFactory(
        useOperatorsAll
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
  ];
}
