import React, { forwardRef, useRef, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DialogHandle,
  Dialog,
  useEventCallback,
  Form,
  FormHandle,
  FormTextArea,
  SnackbarVariant,
  SnackbarContext,
  DetailContext,
  FormAutocomplete,
} from '@eas/common-web';
import { ConsultDialogProps, Consult } from './tickets-types';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './tickets-styles';
import { consult } from './tickets-api';
import { useActiveEditors } from '../operator-api';
import { TicketDialogLeftPanel } from './ticket-dialog-left-panel';

export const ConsultDialog = forwardRef<DialogHandle, ConsultDialogProps>(
  function ConsultDialog({ id, ticket }, dialogRef) {
    const classes = useStyles();
    const intl = useIntl();
    const { showSnackbar } = useContext(SnackbarContext);
    const { refreshAll } = useContext(DetailContext);
    const operators = useActiveEditors();

    const ref = useRef<FormHandle<Consult>>(null);
    const [loading, setLoading] = useState(false);

    const onConfirm = useEventCallback(() => {
      ref.current?.submitForm();
    });

    const handleSubmit = useEventCallback(async (values: Consult) => {
      try {
        setLoading(true);

        await consult(id!, values).none();

        const message = intl.formatMessage({
          id: 'KS_O_TICKETS_CONSULT_DIALOG_MSG_SUCCESS',
          defaultMessage: 'Konzultanti byli přidáni',
        });

        showSnackbar(message, SnackbarVariant.SUCCESS);
        setLoading(false);

        refreshAll();
      } catch (err) {
        if (err.name !== 'AbortError') {
          const message = intl.formatMessage(
            {
              id: 'KS_O_TICKETS_CONSULT_DIALOG_MSG_ERROR',
              defaultMessage: 'Chyba přidání konzultantů: {detail}',
            },
            { detail: err.message }
          );

          showSnackbar(message, SnackbarVariant.ERROR);
          setLoading(false);

          throw err;
        }
      }
    });

    return (
      <Dialog
        disableBackdrop
        ref={dialogRef}
        loading={loading}
        title={
          <FormattedMessage
            id="KS_O_TICKETS_CONSULT_DIALOG_TITLE"
            defaultMessage="Přidání konzultantů"
          />
        }
        onConfirm={onConfirm}
      >
        {() => (
          <Grid container className={classes.actionDialogContainerWrapper}>
            <Grid
              item
              xs={4}
              sm={4}
              className={classes.actionDialogQuestionWrapper}
            >
              <TicketDialogLeftPanel ticket={ticket} />
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              className={classes.actionDialogAnswerWrapper}
            >
              <Form<Consult>
                editing={!loading}
                initialValues={{ text: '', consultants: [] }}
                onSubmit={handleSubmit}
                ref={ref}
              >
                <Grid container className={classes.actionDialogWrapper}>
                  <Grid item xs={12} sm={12}>
                    <FormAutocomplete
                      name="consultants"
                      label={
                        <FormattedMessage
                          id="KS_O_TICKETS_CONSULT_DIALOG_FIELD_LABEL_CONSULTANTS"
                          defaultMessage="Konzultanti"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id:
                          'KS_O_TICKETS_CONSULT_DIALOG_FIELD_LABEL_HELP_CONSULTANTS',
                        defaultMessage: ' ',
                      })}
                      multiple={true}
                      source={operators}
                      tooltipMapper={(o) => o.name}
                    />
                    <FormTextArea
                      required
                      name="text"
                      maxRows={25}
                      label={
                        <FormattedMessage
                          id="KS_O_TICKETS_CONSULT_DIALOG_FIELD_LABEL_TEXT"
                          defaultMessage="Komentář"
                        />
                      }
                      helpLabel={intl.formatMessage({
                        id: 'KS_O_TICKETS_CONSULT_DIALOG_FIELD_LABEL_HELP_TEXT',
                        defaultMessage: ' ',
                      })}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}
      </Dialog>
    );
  }
);
