import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    float: 'right',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  searchTextField: {
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    backgroundColor: '#fff',

    '& > div': {
      fontSize: 14,
      height: 32,
      '& input[type="search"]::-webkit-search-cancel-button': {
        display: 'none',
      },
    },
  },
  searchTextFieldInput: {
    '&:hover': {
      borderColor: 'red',
    },
  },
  clearIcon: {
    cursor: 'pointer',
    fontSize: 15,
    marginRight: 5,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  searchIcon: {
    cursor: 'pointer',
    marginRight: 5,
    // padding: 10,
    color: theme.palette.primary.dark,
  },
  itemHeaderText: {
    cursor: 'pointer',
    whiteSpace: 'pre-line',
    display: 'block',
    unicodeBidi: 'embed',
    fontWeight: 600,
  },
  item: {
    backgroundColor: '#FAFCFD',
  },
  itemExpanded: {
    whiteSpace: 'pre-line',
    display: 'block',
    unicodeBidi: 'embed',
  },
  attributeLabel: {
    color: theme.palette.grey[600],
    fontSize: '0.7rem',
    textTransform: 'uppercase',
    width: 200,
    marginLeft: 15,
  },
  attributeText: {
    fontSize: '0.7rem',
    textTransform: 'uppercase',
  },
  label: {
    fontSize: 16,
  },
  labelWrapper: {
    alignSelf: 'center',
  },
}));
