import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Form,
  FormTextField,
  FormSubmitButton,
  FormCheckbox,
} from '@eas/common-web';
import { Profile } from '../../../common/models';
import { useProfile } from './profile-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { PasswordChangeDialog } from './password-change-dialog';
import { PersonalEvents } from '../../../common/personal-events/personal-events';

export function ProfilePage() {
  const intl = useIntl();

  const {
    loading,
    editing,
    ref,
    pwdChangeDialog,
    validationSchema,
    handleSubmit,
    handleCancelEditing,
    handleStartEditing,
    handlePwdChange,
    handleUSerSettingsClear,
  } = useProfile();

  return (
    <Grid container>
      <Grid item xs={false} sm={1} />
      <Grid item xs={12} sm={10}>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <h1>
              <FormattedMessage
                id="KS_O_PROFILE_TITLE"
                defaultMessage="Profil"
              />
            </h1>
            <Form<Profile>
              editing={!loading && editing}
              initialValues={{} as any}
              onSubmit={handleSubmit}
              ref={ref}
              validationSchema={validationSchema as any}
            >
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    required
                    name="firstName"
                    label={
                      <FormattedMessage
                        id="KS_O_PROFILE_FIELD_FIRST_NAME"
                        defaultMessage="Jméno"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_PROFILE_FIELD_HELP_FIRST_NAME',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    required
                    name="lastName"
                    label={
                      <FormattedMessage
                        id="KS_O_PROFILE_FIELD_LAST_NAME"
                        defaultMessage="Příjmení"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_PROFILE_FIELD_HELP_LAST_NAME',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    disabled
                    name="email"
                    label={
                      <FormattedMessage
                        id="KS_O_PROFILE_FIELD_EMAIL"
                        defaultMessage="Email"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_PROFILE_FIELD_HELP_EMAIL',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    name="phone"
                    label={
                      <FormattedMessage
                        id="KS_O_PROFILE_FIELD_PHONE"
                        defaultMessage="Telefon"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_PROFILE_FIELD_HELP_PHONE',
                      defaultMessage:
                        'Vložte číslo v mezinárodním formátu +420xxxxxxxxx',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    name="organisationName"
                    label={
                      <FormattedMessage
                        id="KS_O_PROFILE_FIELD_ORGANISATION_NAME"
                        defaultMessage="Název organizace"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_PROFILE_FIELD_HELP_ORGANISATION_NAME',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    name="organisationIc"
                    label={
                      <FormattedMessage
                        id="KS_O_PROFILE_FIELD_ORGANISATION_IC"
                        defaultMessage="IČ organizace"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_PROFILE_FIELD_HELP_ORGANISATION_IC',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextField
                    name="employeeNumber"
                    label={
                      <FormattedMessage
                        id="KS_O_PROFILE_FIELD_EMPLOYEE_NUMBER"
                        defaultMessage="Organizační jednotka"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_PROFILE_FIELD_HELP_EMPLOYEE_NUMBER',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormCheckbox
                    name="active"
                    disabled
                    label={
                      <FormattedMessage
                        id="KS_O_OPERATORS_FIELD_LABEL_ACTIVE"
                        defaultMessage="Aktivní"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_OPERATORS_FIELD_HELP_ACTIVE',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormCheckbox
                    name="editor"
                    disabled
                    label={
                      <FormattedMessage
                        id="KS_O_OPERATORS_FIELD_LABEL_EDITOR"
                        defaultMessage="Editor"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_OPERATORS_FIELD_HELP_EDITOR',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormCheckbox
                    name="administrator"
                    disabled
                    label={
                      <FormattedMessage
                        id="KS_O_OPERATORS_FIELD_LABEL_ADMNISTRATOR"
                        defaultMessage="Administrátor"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_OPERATORS_FIELD_HELP_ADMINISTRATOR',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box height={50} />
                  <Box display="flex" flexDirection="row-reverse">
                    {!editing && (
                      <>
                        <Button
                          variant="contained"
                          disabled={loading}
                          onClick={handleUSerSettingsClear}
                        >
                          <FormattedMessage
                            id="KS_O_PROFILE_BTN_CLEAR_SETTINGS"
                            defaultMessage="Smazat nastavení"
                          />
                        </Button>
                        <Box width={10} />
                      </>
                    )}
                    {!editing && (
                      <>
                        <Button
                          variant="contained"
                          disabled={loading}
                          onClick={handlePwdChange}
                        >
                          <FormattedMessage
                            id="KS_O_PROFILE_BTN_PW_CHANGE"
                            defaultMessage="Změnit heslo"
                          />
                        </Button>
                        <Box width={10} />
                      </>
                    )}
                    {!editing && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          onClick={handleStartEditing}
                        >
                          <FormattedMessage
                            id="KS_O_PROFILE_BTN_EDIT"
                            defaultMessage="Upravit"
                          />
                        </Button>
                        <Box width={10} />
                      </>
                    )}
                    {editing && (
                      <>
                        <Button
                          variant="contained"
                          disabled={loading}
                          onClick={handleCancelEditing}
                        >
                          <FormattedMessage
                            id="KS_O_PROFILE_BTN_CANCEL"
                            defaultMessage="Zrušit"
                          />
                        </Button>
                        <Box width={10} />
                      </>
                    )}
                    {editing && (
                      <>
                        <FormSubmitButton
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          startIcon={
                            loading && (
                              <CircularProgress size="20px" color="inherit" />
                            )
                          }
                        >
                          <FormattedMessage
                            id="KS_O_PROFILE_BTN_SAVE"
                            defaultMessage="Uložit"
                          />
                        </FormSubmitButton>
                        <Box width={10} />
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Grid>
          <Grid item xs={false} sm={1} />
          <Grid item xs={12} sm={6}>
            <h1>
              <FormattedMessage
                id="KS_O_PERSONAL_EVENTS"
                defaultMessage="Události"
              />
            </h1>
            <PersonalEvents />
          </Grid>
        </Grid>
      </Grid>
      <PasswordChangeDialog ref={pwdChangeDialog} />
    </Grid>
  );
}
