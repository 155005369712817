import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  Evidence,
  useDatedEvidence,
  UserContext,
  useEventCallback,
} from '@eas/common-web';
import { Permissions } from '../../../common/permissions';
import { ExportTags } from '../../../common/export-tags';
import { Requester } from '../../../common/models';
import { RequesterFields } from './requesters-fields';
import { useColumns } from './requesters-columns';
import { RequestersToolbar } from './requesters-toolbar';
import { useValidationSchema } from './requesters-schema';

export function OperatorRequesters() {
  const intl = useIntl();
  const { hasPermission } = useContext(UserContext);
  const validationSchema = useValidationSchema();

  const isEditor = hasPermission(Permissions.EDITOR);
  const isAdministrator = hasPermission(Permissions.ADMINISTRATOR);
  const showButton = useEventCallback(
    ({ button }) => (isEditor && button !== 'REMOVE') || isAdministrator
  );

  const evidence = useDatedEvidence<Requester>({
    identifier: 'REQUESTERS',
    apiProps: {
      url: '/api/ks/requesters',
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'KS_O_REQUESTERS_TABLE_TITLE',
        defaultMessage: 'Tazatelé',
      }),
      reportTag: ExportTags.REQUESTER_GRID,
    },
    detailProps: {
      FieldsComponent: RequesterFields,
      validationSchema,
      toolbarProps: {
        after: <RequestersToolbar />,
        showButton,
      },
    },
  });

  return <Evidence {...evidence} />;
}
