import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  logo: {
    width: '100%',
    height: '100%',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 16,
    color: 'white',
  },
  langItem: {
    display: 'flex',
    alignItems: 'center',
  },
  langButton: {
    minWidth: 'auto',
    width: 30,
  },
}));
