import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Form, FormTextField, FormSubmitButton } from '@eas/common-web';
import { PublicPage } from '../../../components/public-page/public-page';
import { PasswordResetComplete } from '../../../common/models';
import { usePasswordReset } from './password-reset-hook';
import { KsPublicFormItem } from '../components/ks-public-form-item/ks-public-form-item';

export function PasswordReset() {
  const intl = useIntl();
  const {
    loading,
    validationSchema,
    formRef,
    secret,
    handleSubmit,
  } = usePasswordReset();

  return (
    <PublicPage
      title={
        <FormattedMessage
          id="KS_P_PW_RESET_TITLE"
          defaultMessage="Dokončení obnovy hesla"
        />
      }
    >
      {secret !== undefined ? (
        <>
          <Typography>
            <FormattedMessage
              id="KS_P_PW_RESET_SUBTITLE"
              defaultMessage="Zadejte nové heslo:"
            />
          </Typography>
          <Box height={50} />
          <Form<PasswordResetComplete>
            editing={!loading}
            initialValues={{} as any}
            onSubmit={handleSubmit}
            ref={formRef}
            validationSchema={validationSchema as any}
          >
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12}>
                <KsPublicFormItem
                  required
                  name="newPassword"
                  helpLabel={intl.formatMessage({
                    id: 'KS_P_PW_RESET_FIELD_HELP_NEW_PASSWORD',
                    defaultMessage: 'Heslo musí obsahovat 8-64 znaků',
                  })}
                  label={
                    <FormattedMessage
                      id="KS_P_PW_RESET_FIELD_LABEL_NEW_PASSWORD"
                      defaultMessage="Nové heslo"
                    />
                  }
                >
                  <FormTextField
                    required
                    type="password"
                    name="newPassword"
                    autocomplete="password"
                  />
                </KsPublicFormItem>
              </Grid>
              <Grid item xs={12} sm={12}>
                <KsPublicFormItem
                  required
                  name="newPasswordAgain"
                  helpLabel={intl.formatMessage({
                    id: 'KS_P_PW_RESET_FIELD_HELP_NEW_PASSWORD_AGAIN',
                    defaultMessage: ' ',
                  })}
                  label={
                    <FormattedMessage
                      id="KS_P_PW_RESET_FIELD_LABEL_NEW_PASSWORD_AGAIN"
                      defaultMessage="Nové heslo znovu"
                    />
                  }
                >
                  <FormTextField
                    required
                    type="password"
                    name="newPasswordAgain"
                    autocomplete="password"
                  />
                </KsPublicFormItem>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box display="flex" flexDirection="row-reverse">
                  <FormSubmitButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    startIcon={
                      loading && (
                        <CircularProgress size="20px" color="inherit" />
                      )
                    }
                  >
                    <FormattedMessage
                      id="KS_P_PW_RESET_BTN_SEND"
                      defaultMessage="Odeslat"
                    />
                  </FormSubmitButton>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </>
      ) : (
        <Typography>
          <FormattedMessage
            id="KS_P_PW_RESET_SUBTITLE_ERROR"
            defaultMessage="Chyba obnovy hesla"
          />
        </Typography>
      )}
    </PublicPage>
  );
}
