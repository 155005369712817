import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  DetailToolbarButton,
  DetailHandle,
  DetailContext,
  UserContext,
} from '@eas/common-web';
import { Registration } from '../../../common/models';
import { useStyles } from './registrations-styles';
import { useRegistrationsToolbar } from './registrations-toolbar-hook';
import { Permissions } from '../../../common/permissions';

export function RegistrationsToolbar() {
  const { isExisting, source, onPersisted } = useContext<
    DetailHandle<Registration>
  >(DetailContext);
  const classes = useStyles();

  const { confirm } = useRegistrationsToolbar({ source, onPersisted });
  const { hasPermission } = useContext(UserContext);
  const show = hasPermission(Permissions.ADMINISTRATOR);

  return (
    <>
      {show && isExisting && (
        <ButtonGroup
          size="small"
          variant="outlined"
          className={classes.toolbarIndentLeft}
        >
          {!source.data?.confirmed && (
            <DetailToolbarButton
              label={
                <FormattedMessage
                  id="KS_O_REGISTRATIONS_TOOLBAR_BTN_CONFIRM"
                  defaultMessage="Potvrdit"
                />
              }
              onClick={confirm}
            />
          )}
        </ButtonGroup>
      )}
    </>
  );
}
