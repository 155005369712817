import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import logo from './logo.svg';
import { MenubarProps } from './menubar-types';
import { useStyles } from './menubar-styles';
import { useHistory } from 'react-router-dom';
import { useEventCallback } from '@eas/common-web';

export function Menubar({ items, ActionComponent }: MenubarProps) {
  const classes = useStyles();

  const history = useHistory();

  const handleLogoClick = useEventCallback(() => {
    history.push('/');
  });

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.item} xs={false} sm={1} />
      <Grid item className={classes.item} xs={12} sm={2}>
        <img src={logo} className={classes.logo} onClick={handleLogoClick} />
      </Grid>
      <Grid className={classes.langItem} item xs={12} sm={8}>
        <div className={classes.actions}>
          {ActionComponent !== undefined && <ActionComponent />}
        </div>
        <MenuList className={classes.menu}>
          {items.map((item, i) => (
            <MenuItem key={i} onClick={item.onClick} className={classes.button}>
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Grid>
      <Grid item className={classes.item} xs={false} sm={1} />
    </Grid>
  );
}
