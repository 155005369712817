import React, { forwardRef, useRef, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DialogHandle,
  Dialog,
  useEventCallback,
  Form,
  FormHandle,
  FormTextArea,
  SnackbarVariant,
  SnackbarContext,
  DetailContext,
} from '@eas/common-web';
import { RequestAmend, RequestAmendDialogProps } from './tickets-types';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './tickets-styles';
import { requestAmend } from './tickets-api';
import { TicketDialogLeftPanel } from './ticket-dialog-left-panel';

export const RequestAmendDialog = forwardRef<
  DialogHandle,
  RequestAmendDialogProps
>(function RequestAmendDialog({ id, ticket }, dialogRef) {
  const classes = useStyles();
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const { refreshAll } = useContext(DetailContext);

  const ref = useRef<FormHandle<RequestAmend>>(null);
  const [loading, setLoading] = useState(false);

  const onConfirm = useEventCallback(() => {
    ref.current?.submitForm();
  });

  const handleSubmit = useEventCallback(async (values: RequestAmend) => {
    try {
      setLoading(true);

      await requestAmend(id!, values).none();

      const message = intl.formatMessage({
        id: 'KS_O_TICKETS_AMEND_DIALOG_MSG_SUCCESS',
        defaultMessage: 'Žádost o doplnění byla odeslána',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
      setLoading(false);

      refreshAll();
    } catch (err) {
      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_O_TICKETS_AMEND_DIALOG_MSG_ERROR',
            defaultMessage: 'Chyba žádosti: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);
        setLoading(false);

        throw err;
      }
    }
  });

  return (
    <Dialog
      ref={dialogRef}
      loading={loading}
      title={
        <FormattedMessage
          id="KS_O_TICKETS_AMEND_DIALOG_TITLE"
          defaultMessage="Žádost o doplnění"
        />
      }
      onConfirm={onConfirm}
    >
      {() => (
        <Grid container className={classes.actionDialogContainerWrapper}>
          <Grid
            item
            xs={4}
            sm={4}
            className={classes.actionDialogQuestionWrapper}
          >
            <TicketDialogLeftPanel ticket={ticket} />
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            className={classes.actionDialogAnswerWrapper}
          >
            <Form<RequestAmend>
              editing={!loading}
              initialValues={{ text: '' }}
              onSubmit={handleSubmit}
              ref={ref}
            >
              <Grid container className={classes.actionDialogWrapper}>
                <Grid item xs={12} sm={12}>
                  <FormTextArea
                    required
                    name="text"
                    maxRows={25}
                    label={
                      <FormattedMessage
                        id="KS_O_TICKETS_AMEND_DIALOG_FIELD_TEXT"
                        defaultMessage="Text žádosti"
                      />
                    }
                    helpLabel={intl.formatMessage({
                      id: 'KS_O_TICKETS_AMEND_DIALOG_FIELD_HELP_TEXT',
                      defaultMessage: ' ',
                    })}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
});
