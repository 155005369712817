import {
  DictionaryAutocomplete,
  useListSource,
  useAutocompleteSource,
} from '@eas/common-web';

export function useProjectPhases() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/project-phases/all',
  });
}

export function useRegions() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/regions/all',
  });
}

export function useSpecificTargets() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/specific-targets/all',
  });
}

export function useNotices() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/notices/all',
  });
}

export function useActivities() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/activities/all',
  });
}

export function useTags() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/tags/autocomplete',
  });
}
