import { abortableFetch } from '@eas/common-web';

/**
 * Calls registration confirm API.
 *
 */
export function sendRegistrationConfirm(secret: string) {
  return abortableFetch('/api/ks/public/registrations/confirm', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: secret,
  });
}

/**
 * Calls registration resend API.
 *
 */
export function sendRegistrationResend(secret: string) {
  return abortableFetch('/api/ks/public/registrations/resend', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: secret,
  });
}
