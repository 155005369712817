import React, { useContext, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { compact } from 'lodash';
import {
  Menubar,
  MenuItem,
  NavigationContext,
  Authorized,
  LoggedIn,
  AdminProvider,
  UserBtnAction,
  UserContext,
  UserSettingsProvider,
  WebsocketProvider,
  EvidenceDefaultsProvider,
} from '@eas/common-web';
import Typography from '@material-ui/core/Typography';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { OperatorTickets } from './tickets/tickets';
import { OperatorFaqs } from './faqs/faqs';
import { OperatorActivities } from './activities/activities';
import { OperatorNotices } from './notices/notices';
import { OperatorOperators } from './operators/operators';
import { OperatorOrganisationTypes } from './organisation-types/organisation-types';
import { OperatorPasswordResets } from './password-resets/password-resets';
import { OperatorProjectPhases } from './project-phases/project-phases';
import { OperatorRegions } from './regions/regions';
import { OperatorRegistrations } from './registrations/registrations';
import { OperatorRequesters } from './requesters/requesters';
import { OperatorRules } from './rules/rules';
import { OperatorTags } from './tags/tags';
import { OperatorPriorityTargets } from './priority-targets/priority-targets';
import { OperatorSpecificTargets } from './specific-targets/specific-targets';
import { AlogSources } from './alog/sources/sources';
import { AlogEvents } from './alog/events/events';
import { useStyles } from './operator-app-styles';
import { ProfilePage } from './profile/profile';
import { AdminNotificationTemplates } from './notification-templates/notification-templates';
import { Sequences } from './sequences/sequences';
import { Translations } from './translations/translations';
import { OperatorLang } from './operator-lang';
import { Permissions } from '../../common/permissions';
import { AppSettingsPage } from './settings/app/app-settings-page';
import { FormattedMessage } from 'react-intl';
import { ScheduleJobs } from './schedule/jobs/jobs';
import { ScheduleRuns } from './schedule/runs/runs';
import { ExportTemplates } from './export/templates/export-templates';
import { ExportRequests } from './export/requests/export-requests';
import { ExportAccessRules } from './export/access/access-rules';

export function OperatorApp() {
  const classes = useStyles();

  const { hasPermission } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);

  const items: MenuItem[] = compact([
    {
      label: (
        <FormattedMessage id="KS_MENU_EVIDENCES" defaultMessage="Evidence" />
      ),
      items: [
        {
          label: (
            <FormattedMessage
              id="KS_MENU_EVIDENCES_TICKETS"
              defaultMessage="Dotazy"
            />
          ),
          keyShortcut: 'F2',
          onClick: () => navigate('/operator/tickets'),
        },
        {
          label: (
            <FormattedMessage id="KS_MENU_EVIDENCES_FAQ" defaultMessage="FAQ" />
          ),
          keyShortcut: 'F3',
          onClick: () => navigate('/operator/faqs'),
        },
      ],
    },
    hasPermission(Permissions.ADMINISTRATOR) && {
      label: (
        <FormattedMessage
          id="KS_MENU_DICTIONARIES"
          defaultMessage="Číselníky"
        />
      ),
      items: compact([
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_OPERATORS"
              defaultMessage="Řešitelé"
            />
          ),
          onClick: () => navigate('/operator/operators'),
          keyShortcut: 'F4',
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_RULES"
              defaultMessage="Pravidla přiřazení"
            />
          ),
          onClick: () => navigate('/operator/rules'),
        },
        { label: '', separator: true },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_ACTIVITIES"
              defaultMessage="Aktivity"
            />
          ),
          onClick: () => navigate('/operator/activities'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_NOTICS"
              defaultMessage="Výzvy"
            />
          ),
          onClick: () => navigate('/operator/notices'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_ORGANISATION_TYPES"
              defaultMessage="Typy organizací"
            />
          ),
          onClick: () => navigate('/operator/organisation-types'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_PROJECT_PHASES"
              defaultMessage="Fáze projektu"
            />
          ),
          onClick: () => navigate('/operator/project-phases'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_REGIONS"
              defaultMessage="Kraje"
            />
          ),
          onClick: () => navigate('/operator/regions'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_TAGS"
              defaultMessage="Klíčová slova"
            />
          ),
          onClick: () => navigate('/operator/tags'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_PRIORITY_TARGETS"
              defaultMessage="Prioritní osy"
            />
          ),
          onClick: () => navigate('/operator/priority-targets'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_SPECIFIC_TARGETS"
              defaultMessage="Specifické cíle"
            />
          ),
          onClick: () => navigate('/operator/specific-targets'),
        },
        { label: '', separator: true },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_EXPORT_TEMPLATES"
              defaultMessage="Exportní šablony"
            />
          ),
          onClick: () => navigate('/operator/export/templates'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_EXPORT_QUEUE"
              defaultMessage="Fronta exportu"
            />
          ),
          onClick: () => navigate('/operator/export/requests'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_EXPORT_ACCESS_RULES"
              defaultMessage="Pravidla přístupu k exportům"
            />
          ),
          onClick: () => navigate('/operator/export/access-rules'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_NOTIFICATION_TEMPLATES"
              defaultMessage="Šablony notifikací"
            />
          ),
          onClick: () => navigate('/operator/notification/templates'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_SEQUENCES"
              defaultMessage="Číselné řady"
            />
          ),
          onClick: () => navigate('/operator/sequences'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_TRANSLATIONS"
              defaultMessage="Překlady"
            />
          ),
          onClick: () => navigate('/operator/translations'),
        },
        { label: '', separator: true },
        /*{
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_SCHEDULE_JOBS"
              defaultMessage="Časové úlohy"
            />
          ),
          onClick: () => navigate('/operator/schedule/jobs'),
        },
        {
          label: (
            <FormattedMessage
              id="KS_MENU_DICTIONARIES_SCHEDULE_RUNS"
              defaultMessage="Běhy časových úloh"
            />
          ),
          onClick: () => navigate('/operator/schedule/runs'),
        },*/
        hasPermission(Permissions.ADMINISTRATOR) && {
          label: (
            <FormattedMessage
              id="KS_MENU_REQUESTERS_PASSWORD_RESETS"
              defaultMessage="Žádosti o reset hesla"
            />
          ),
          onClick: () => navigate('/operator/password-resets'),
        },
      ]),
    },
    hasPermission(Permissions.OPERATOR) && {
      label: (
        <FormattedMessage id="KS_MENU_REQUESTERS" defaultMessage="Tazatelé" />
      ),
      items: compact([
        {
          label: (
            <FormattedMessage
              id="KS_MENU_REQUESTERS_GRID"
              defaultMessage="Přehled"
            />
          ),
          onClick: () => navigate('/operator/requesters'),
          keyShortcut: 'F5',
        },
        hasPermission(Permissions.ADMINISTRATOR) && {
          label: (
            <FormattedMessage
              id="KS_MENU_REQUESTERS_REGISTRATIONS"
              defaultMessage="Registrace"
            />
          ),
          onClick: () => navigate('/operator/registrations'),
        },
      ]),
    },
    hasPermission(Permissions.ADMINISTRATOR) && {
      label: (
        <FormattedMessage id="KS_MENU_SETTINGS" defaultMessage="Nastavení" />
      ),
      items: [
        {
          label: (
            <FormattedMessage
              id="KS_MENU_APPLICATION_SETTINGS"
              defaultMessage="Nastavení aplikace"
            />
          ),
          onClick: () => navigate('/operator/admin/settings'),
        },
      ],
    },
  ]);

  const userBtnActions: UserBtnAction[] = useMemo(
    () => [
      {
        label: (
          <FormattedMessage id="KS_MENU_PROFILE" defaultMessage="Profil" />
        ),
        action: () => {
          navigate('/operator/profile');
        },
      },
      {
        label: <OperatorLang />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <LoggedIn redirectUrl="/answers">
      <EvidenceDefaultsProvider
        //appLogo={HeadsetMicIcon}
        enableEmptyDetailContainer={true}
      >
        <UserSettingsProvider url="/api/ks/user-settings">
          <Authorized permission="OPERATOR">
            <Menubar
              userBtnActions={userBtnActions}
              logoUrl="/operator"
              logo={<HeadsetMicIcon className={classes.logo} />}
              title={
                <Typography className={classes.title}>
                  <FormattedMessage
                    id="KS_TITLE"
                    defaultMessage="Konzultační servis"
                  />
                </Typography>
              }
              items={items}
            >
              <WebsocketProvider
                wsUrl="/api/ks/stomp"
                destinations={[
                  '/user/queue/session',
                  '/user/queue/notification',
                ]}
                debug={false}
              >
                <AdminProvider
                  permission="SUPER_ADMINISTRATOR"
                  prefix="/operator"
                  reindexUrl="/api/ks/reindex"
                />
                <Switch>
                  <Route exact path="/operator">
                    <Redirect to="/operator/tickets" />
                  </Route>
                  <Route
                    path="/operator/tickets/:id?"
                    component={OperatorTickets}
                  />
                  <Route path="/operator/faqs/:id?" component={OperatorFaqs} />
                  <Route
                    path="/operator/activities/:id?"
                    component={OperatorActivities}
                  />
                  <Route
                    path="/operator/notices/:id?"
                    component={OperatorNotices}
                  />
                  <Route
                    path="/operator/operators/:id?"
                    component={OperatorOperators}
                  />
                  <Route
                    path="/operator/organisation-types/:id?"
                    component={OperatorOrganisationTypes}
                  />
                  <Route
                    path="/operator/password-resets/:id?"
                    component={OperatorPasswordResets}
                  />
                  <Route
                    path="/operator/project-phases/:id?"
                    component={OperatorProjectPhases}
                  />
                  <Route
                    path="/operator/regions/:id?"
                    component={OperatorRegions}
                  />
                  <Route
                    path="/operator/registrations/:id?"
                    component={OperatorRegistrations}
                  />
                  <Route
                    path="/operator/requesters/:id?"
                    component={OperatorRequesters}
                  />
                  <Route
                    path="/operator/rules/:id?"
                    component={OperatorRules}
                  />
                  <Route path="/operator/tags/:id?" component={OperatorTags} />
                  <Route
                    path="/operator/priority-targets/:id?"
                    component={OperatorPriorityTargets}
                  />
                  <Route
                    path="/operator/specific-targets/:id?"
                    component={OperatorSpecificTargets}
                  />

                  <Route
                    path="/operator/export/templates/:id?"
                    component={ExportTemplates}
                  />
                  <Route
                    path="/operator/export/requests/:id?"
                    component={ExportRequests}
                  />
                  <Route
                    path="/operator/export/access-rules/:id?"
                    component={ExportAccessRules}
                  />

                  <Route
                    path="/operator/alog/sources/:id?"
                    component={AlogSources}
                  />
                  <Route
                    path="/operator/alog/events/:id?"
                    component={AlogEvents}
                  />
                  <Route
                    path="/operator/profile/:id?"
                    component={ProfilePage}
                  />
                  <Route
                    path="/operator/notification/templates/:id?"
                    component={AdminNotificationTemplates}
                  />
                  <Route
                    path="/operator/sequences/:id?"
                    component={Sequences}
                  />
                  <Route
                    path="/operator/schedule/jobs/:id?"
                    component={ScheduleJobs}
                  />
                  <Route
                    path="/operator/schedule/runs/:id?"
                    component={ScheduleRuns}
                  />
                  <Route
                    path="/operator/translations/:id?"
                    component={Translations}
                  />
                  <Route
                    path="/operator/admin/settings/:id?"
                    component={AppSettingsPage}
                  />
                </Switch>
              </WebsocketProvider>
            </Menubar>
          </Authorized>
        </UserSettingsProvider>
      </EvidenceDefaultsProvider>
    </LoggedIn>
  );
}
