import { useState, useRef, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { unstable_batchedUpdates } from 'react-dom';
import {
  useEventCallback,
  FormHandle,
  SnackbarContext,
  SnackbarVariant,
  NavigationContext,
} from '@eas/common-web';
import { LoginData } from '../../../common/models';
import { useLocation } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useErrorLoginCounter } from '../../../components/error-login-counter/error-login-counter-hook';

export function useLogin() {
  const intl = useIntl();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandle<LoginData>>(null);
  const hiddenFormRef = useRef<HTMLFormElement>(null);
  const hiddenUsernameRef = useRef<HTMLInputElement>(null);
  const hiddenPasswordRef = useRef<HTMLInputElement>(null);
  const hiddenCaptchaRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const { increaseCount, resetCount } = useErrorLoginCounter();

  useEffect(() => {
    if (location.search === '?error') {
      showSnackbar('Chyba přihlášení', SnackbarVariant.ERROR);
      const errorCount = increaseCount();

      if (errorCount >= 3) {
        resetCount();
        navigate('/password-reset-request');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useEventCallback(async (data: LoginData) => {
    try {
      setLoading(true);

      const captcha = await executeRecaptcha!('requesterLogin');

      hiddenUsernameRef.current!.value = data.username;
      hiddenPasswordRef.current!.value = data.password;
      hiddenCaptchaRef.current!.value = captcha;

      hiddenFormRef.current?.submit();

      unstable_batchedUpdates(() => {
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);

      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_P_LOGIN_MSG_ERROR',
            defaultMessage: 'Chyba přihlášení: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }

      return undefined;
    }
  });

  return {
    loading,
    handleSubmit,
    hiddenFormRef,
    hiddenUsernameRef,
    hiddenPasswordRef,
    hiddenCaptchaRef,
    formRef,
  };
}
