import { useIntl } from 'react-intl';
import { TableColumn, TableCells } from '@eas/common-web';
import { Registration } from '../../../common/models';

export function useColumns(): TableColumn<Registration>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'email',
      name: intl.formatMessage({
        id: 'KS_O_REGISTRATIONS_COLUMN_EMAIL',
        defaultMessage: 'Email',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'firstName',
      name: intl.formatMessage({
        id: 'KS_O_REGISTRATIONS_COLUMN_FIRST_NAME',
        defaultMessage: 'Jméno',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'lastName',
      name: intl.formatMessage({
        id: 'KS_O_REGISTRATIONS_COLUMN_LAST_NAME',
        defaultMessage: 'Příjmení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'phone',
      name: intl.formatMessage({
        id: 'KS_O_REGISTRATIONS_COLUMN_PHONE',
        defaultMessage: 'Telefon',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'organisationName',
      name: intl.formatMessage({
        id: 'KS_O_REGISTRATIONS_COLUMN_ORGANISATION_NAME',
        defaultMessage: 'Název organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'organisationIc',
      name: intl.formatMessage({
        id: 'KS_O_REGISTRATIONS_COLUMN_ORGANISATION_IC',
        defaultMessage: 'IČ organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'confirmed',
      name: intl.formatMessage({
        id: 'KS_O_REGISTRATIONS_COLUMN_CONFIRMED',
        defaultMessage: 'Potvrzena',
      }),
      width: 120,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
  ];
}
