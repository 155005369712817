import React, { forwardRef, useRef, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DialogHandle,
  Dialog,
  useEventCallback,
  Form,
  FormHandle,
  SnackbarVariant,
  SnackbarContext,
  DetailContext,
  FormSelect,
} from '@eas/common-web';
import { RemoveConsultantDialogProps, RemoveConsultant } from './tickets-types';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './tickets-styles';
import { removeConsultants } from './tickets-api';
import { useConsultants } from '../operator-api';

export const RemoveConsultantDialog = forwardRef<
  DialogHandle,
  RemoveConsultantDialogProps
>(function RemoveConsultantDialog({ id, ticket }, dialogRef) {
  const classes = useStyles();
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const { refreshAll } = useContext(DetailContext);
  const operators = useConsultants(ticket);

  const ref = useRef<FormHandle<RemoveConsultant>>(null);
  const [loading, setLoading] = useState(false);

  const onConfirm = useEventCallback(() => {
    ref.current?.submitForm();
  });

  const handleSubmit = useEventCallback(async (values: RemoveConsultant) => {
    try {
      setLoading(true);

      await removeConsultants(id!, values).none();

      const message = intl.formatMessage({
        id: 'KS_O_TICKETS_REMOVE_CONSULTANT_DIALOG_MSG_SUCCESS',
        defaultMessage: 'Konzultanti byli odebráni',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
      setLoading(false);

      refreshAll();
    } catch (err) {
      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_O_TICKETS_REMOVE_CONSULTANT_DIALOG_MSG_ERROR',
            defaultMessage: 'Chyba odebrání konzultantů: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);
        setLoading(false);

        throw err;
      }
    }
  });

  return (
    <Dialog
      ref={dialogRef}
      loading={loading}
      title={
        <FormattedMessage
          id="KS_O_TICKETS_REMOVE_CONSULTANT_DIALOG_TITLE"
          defaultMessage="Odebrání konzultantů"
        />
      }
      onConfirm={onConfirm}
    >
      {() => (
        <Form<RemoveConsultant>
          editing={!loading}
          initialValues={{ consultants: [] }}
          onSubmit={handleSubmit}
          ref={ref}
        >
          <Grid container className={classes.actionSimpleDialogWrapper}>
            <Grid item xs={12} sm={12}>
              <FormSelect
                name="consultants"
                label={
                  <FormattedMessage
                    id="KS_O_TICKETS_REMOVE_CONSULTANT_DIALOG_FIELD_LABEL_CONSULTANTS"
                    defaultMessage="Konzultanti"
                  />
                }
                helpLabel={intl.formatMessage({
                  id:
                    'KS_O_TICKETS_REMOVE_CONSULTANT_DIALOG_FIELD_LABEL_HELP_CONSULTANTS',
                  defaultMessage: ' ',
                })}
                multiple={true}
                source={operators}
                tooltipMapper={(o) => o.name}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Dialog>
  );
});
