import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useAuthoredEvidence } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { Rule } from '../../../common/models';
import { RulesFields } from './rules-fields';
import { useColumns } from './rules-columns';
import { useValidationSchema } from './rules-schema';

export function OperatorRules() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useAuthoredEvidence<Rule>({
    identifier: 'RULES',
    apiProps: {
      url: '/api/ks/rules',
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'KS_O_RULES_TABLE_TITLE',
        defaultMessage: 'Pravidla',
      }),
      reportTag: ExportTags.RULE_GRID,
      defaultSorts: [
        { field: 'order', datakey: 'order', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      FieldsComponent: RulesFields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
