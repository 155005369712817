import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Login } from './login';

export function LoginWrapper() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LeLOd0ZAAAAAASD3guled-gDeL0gg4YTvqFFQiT"
      language="cs"
    >
      <Login />
    </GoogleReCaptchaProvider>
  );
}
