import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormSubmitButton } from '@eas/common-web';
import { StatePanel } from '../panels/state-panel';
import { RequesterPanel } from '../panels/requester-panel';
import { ProjectPanel } from '../panels/project-panel';
import { QuestionPanel } from '../panels/question-panel';
import { FilesPanel } from '../panels/files-panel';
import { ParentPanel } from '../panels/parent-panel';

export function RequesterTicketsNewFields({ loading }: { loading: boolean }) {
  return (
    <Box display="flex" flexDirection="column">
      <ParentPanel />
      <StatePanel existing={false} />
      <RequesterPanel />
      <ProjectPanel />
      <QuestionPanel />
      <FilesPanel />
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12}>
          <Box display="flex" flexDirection="row-reverse">
            <FormSubmitButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              startIcon={
                loading && <CircularProgress size="20px" color="inherit" />
              }
            >
              <FormattedMessage
                id="KS_R_TICKETS_NEW_BTN_SUBMIT"
                defaultMessage="Odeslat"
              />
            </FormSubmitButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
