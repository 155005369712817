import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FAFCFD',
    color: 'rgb(33, 37, 41)',
    borderBottom: '1px solid rgb(234, 234, 234)',
    height: 114,
  },
  item: {
    height: 114,
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  langItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  logo: {
    height: '100%',
    padding: '2em 0',
    cursor: 'pointer',
  },
  actions: {
    paddingLeft: 30,
  },
  button: {
    //borderWidth: '1px',
    //borderStyle: 'solid',
    //borderColor: theme.palette.primary.main,
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 18,
    color: theme.palette.primary.main,

    '&:hover': {
      //backgroundColor: theme.palette.primary.main,
      //color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
}));
