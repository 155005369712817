import { useRef, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  AbortableFetch,
  useEventCallback,
  SnackbarContext,
  NavigationContext,
  SnackbarVariant,
} from '@eas/common-web';
import {
  sendRegistrationConfirm,
  sendRegistrationResend,
} from './registration-confirm-api';

export function useRegistrationConfirm() {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);
  const [overdue, setOverdue] = useState(false);

  const fetch = useRef<AbortableFetch | null>(null);

  const { secret } = useParams();

  const submitRegistration = useEventCallback(async () => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = sendRegistrationConfirm(secret);

      await fetch.current.none();

      navigate('/registration-confirm-complete', true);
    } catch (err) {
      if (err.name !== 'AbortError') {
        if (
          err.exception === 'class cz.inqool.eas.ks.exception.OverdueOperation'
        ) {
          setOverdue(true);
          return undefined;
        }

        const message = intl.formatMessage(
          {
            id: 'KS_P_REGISTRATION_CONFIRM_MSG_ERROR',
            defaultMessage: 'Chyba registrace: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }

      return undefined;
    }
  });

  const resend = useEventCallback(async () => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = sendRegistrationResend(secret);

      await fetch.current.none();

      navigate('/registration-complete', true);
    } catch (err) {
      if (err.name !== 'AbortError') {
        const message = intl.formatMessage(
          {
            id: 'KS_P_REGISTRATION_CONFIRM_MSG_ERROR',
            defaultMessage: 'Chyba registrace: {detail}',
          },
          { detail: err.message }
        );

        showSnackbar(message, SnackbarVariant.ERROR);

        throw err;
      }

      return undefined;
    }
  });

  useEffect(() => {
    submitRegistration();
  }, [submitRegistration]);

  return {
    secret,
    overdue,
    resend,
  };
}
