import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 22,
  },
  actionSimpleDialogWrapper: {
    display: 'flex',
    width: 800,

    '& textarea': {
      resize: 'vertical',
    },
  },
  actionDialogWrapper: {
    display: 'flex',
    width: 1200,

    '& textarea': {
      resize: 'vertical',
    },
  },
  actionDialogContainerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 450,
  },
  actionDialogQuestionWrapper: {
    overflowY: 'scroll',
    height: '100%',
    flex: 1,
  },
  actionDialogAnswerWrapper: {},

  commentHeaderText: {
    textDecoration: 'underline',
    fontSize: 14,
  },
  comment: {
    backgroundColor: '#FAFCFD',
  },
  commentFromOperator: {
    backgroundColor: '#dcedf5',
  },
  commentInternal: {
    backgroundColor: '#f5f2c6',
  },
  commentSystem: {
    backgroundColor: '#c9de9e',
  },
  typography: {
    whiteSpace: 'pre-line',
  },
  commentsWrapper: {
    margin: 5,
  },
}));
