import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  Evidence,
  useAuthoredEvidence,
  UserContext,
  useEventCallback,
} from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { Faq } from '../../../common/models';
import { Permissions } from '../../../common/permissions';
import { FaqFields } from './faq-fields';
import { useColumns } from './faq-columns';
import { useValidationSchema } from './faqs-schema';
import { FaqsToolbar } from './faqs-toolbar';

export function OperatorFaqs() {
  const intl = useIntl();
  const { hasPermission } = useContext(UserContext);
  const validationSchema = useValidationSchema();

  const isEditor = hasPermission(Permissions.EDITOR);
  const isAdministrator = hasPermission(Permissions.ADMINISTRATOR);
  const showButton = useEventCallback(() => isEditor || isAdministrator);

  const evidence = useAuthoredEvidence<Faq>({
    identifier: 'FAQS',
    version: 2,
    apiProps: {
      url: '/api/ks/faqs',
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'KS_O_FAQ_TABLE_TITLE',
        defaultMessage: 'FAQ',
      }),
      reportTag: ExportTags.FAQ_GRID,
    },
    detailProps: {
      FieldsComponent: FaqFields,
      validationSchema,
      toolbarProps: {
        after: <FaqsToolbar />,
        showButton,
      },
    },
  });

  return <Evidence {...evidence} />;
}
