import { useContext, Context } from 'react';
import { useIntl } from 'react-intl';
import { compact } from 'lodash';
import {
  TableColumn,
  TableCells,
  TableFilterCells,
  AppSettingsContext,
} from '@eas/common-web';
import { TicketExternalList, KsAppSettings } from '../../../common/models';
import {
  useRegions,
  useSpecificTargets,
  useActivities,
  useNotices,
  useProjectPhases,
  useOrganisationTypes,
  useTicketStates,
} from '../requester-api';
import { TicketActions } from './ticket-actions';

export function useColumns(): TableColumn<TicketExternalList>[] {
  const intl = useIntl();
  const { settings } = useContext(
    (AppSettingsContext as unknown) as Context<
      AppSettingsContext<KsAppSettings>
    >
  );
  const fields = settings.fields ?? [];

  const fieldColumns = compact(
    fields.map((field, index) => {
      if (field == null || !field.enabled || !field.public) {
        return undefined;
      }

      return {
        datakey: `field${index + 1}`,
        name: intl.formatMessage({
          id: field.labelId ?? '',
          defaultMessage: field.labelDefault,
        }),
        width: 200,
        CellComponent: TableCells.TextCell,
        sortable: true,
        filterable: true,
      };
    })
  );

  return [
    {
      datakey: 'actions',
      fixed: true,
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_ACTIONS',
        defaultMessage: 'Akce',
      }),
      width: 120,
      CellComponent: TicketActions,
    },
    {
      datakey: 'identifier',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_IDENTIFIER',
        defaultMessage: 'Číslo',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_CREATED',
        defaultMessage: 'Vytvoření',
      }),
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'resolveDate',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_RESOLVE_DATE',
        defaultMessage: 'Čas odpovědi',
      }),
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'resolveName',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_RESOLVE_NAME',
        defaultMessage: 'Autor odpovědi',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_STATE',
        defaultMessage: 'Stav',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useTicketStates
      ),
      valueMapper: TableCells.useSelectCellFactory(useTicketStates),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'organisationType',
      sortkey: 'organisationType.name',
      filterkey: 'organisationType.id',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_ORGANISATION_TYPE',
        defaultMessage: 'Typ organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useOrganisationTypes
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'applicantName',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_APPLICANT_NAME',
        defaultMessage: 'Název žadatele',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'applicantIc',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_APPLICANT_IC',
        defaultMessage: 'IČ žadatele',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'projectName',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_PROJECT_NAME',
        defaultMessage: 'Název projektu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'projectNumber',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_PROJECT_NUMBER',
        defaultMessage: 'Číslo projektu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'projectPhase',
      sortkey: 'projectPhase.name',
      filterkey: 'projectPhase.id',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_PROJECT_PHASE',
        defaultMessage: 'Dotaz k fázi projektu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useProjectPhases
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'region',
      sortkey: 'region.name',
      filterkey: 'region.id',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_REGION',
        defaultMessage: 'Kraj',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useRegions
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'place',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_PLACE',
        defaultMessage: 'Místo',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'specificTarget',
      sortkey: 'specificTarget.name',
      filterkey: 'specificTarget.id',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_SPECIFIC_TARGET',
        defaultMessage: 'Specifický cíl',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useSpecificTargets
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'notice',
      sortkey: 'notice.name',
      filterkey: 'notice.id',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_NOTICE',
        defaultMessage: 'Výzva',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useNotices
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'activity',
      sortkey: 'activity.name',
      filterkey: 'activity.id',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_ACTIVITY',
        defaultMessage: 'Aktivita',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        useActivities
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'question',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_QUESTION',
        defaultMessage: 'Otázka',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
      visible: true,
    },
    {
      datakey: 'plainAnswer',
      name: intl.formatMessage({
        id: 'KS_R_TICKETS_COLUMN_ANSWER',
        defaultMessage: 'Odpověď',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
      valueMapper: truncateColumnMapper,
      visible: true,
    },
    ...fieldColumns,
  ];
}

function truncateColumnMapper({ value }: { value: string }) {
  return value != null ? value.substr(0, 32000) : '';
}

truncateColumnMapper.displayName = 'truncateColumnMapper';
truncateColumnMapper.data = 32000;
