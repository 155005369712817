import { useIntl } from 'react-intl';
import { TableColumn, TableCells } from '@eas/common-web';
import { Operator } from '../../../common/models';

export function useColumns(): TableColumn<Operator>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'firstName',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_FIRST_NAME',
        defaultMessage: 'Jméno',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'lastName',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_LAST_NAME',
        defaultMessage: 'Příjmení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'email',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_EMAIL',
        defaultMessage: 'Email',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'phone',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_PHONE',
        defaultMessage: 'Telefon',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'mobilePhone',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_MOBILE_PHONE',
        defaultMessage: 'Mobil',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'organisationName',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_ORGANISATION_NAME',
        defaultMessage: 'Název organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'organisationIc',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_ORGANISATION_IC',
        defaultMessage: 'Ič organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'employeeNumber',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_EMPLOYEE_NUMBER',
        defaultMessage: 'Organizační jednotka',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'externalId',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_EXTERNAL_ID',
        defaultMessage: 'Externí identifikátor',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'active',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_ACTIVE',
        defaultMessage: 'Aktivní',
      }),
      width: 100,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'editor',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_EDITOR',
        defaultMessage: 'Editor',
      }),
      width: 100,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'administrator',
      name: intl.formatMessage({
        id: 'KS_O_OPERATORS_COLUMN_ADMINISTRATOR',
        defaultMessage: 'Admin',
      }),
      width: 100,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
  ];
}
