import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  contacts: {
    flexGrow: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  contactsRoot: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 46,
  },
  contactsIcon: {
    verticalAlign: 'sub',
    marginRight: 5,
  },
  logos: {
    height: 80,
    flexGrow: 0,
    backgroundColor: '#404041',
    color: 'white',
  },
  logosRoot: {},
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  euLogo: {
    height: 60,
    marginLeft: -10,
    marginTop: 10,
    marginBottom: 10,
  },
  crrLogo: {
    height: 60,
    padding: 2,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
  },
  mmrLogo: {
    height: 60,
    padding: 2,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
  },
  logoText: {
    alignSelf: 'center',
  },
  linksLabel: {
    fontSize: '1rem',
    lineHeight: '26px',
    fontWeight: 600,
  },
  link: {
    color: 'white',
    fontSize: '1rem',
    lineHeight: '26px',
    fontWeight: 600,
  },
}));
