import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PublicPage } from '../../../components/public-page/public-page';

export function RegistrationComplete() {
  return (
    <PublicPage
      title={
        <FormattedMessage
          id="KS_P_REGISTRATION_COMPLETE_TITLE"
          defaultMessage="Registrace byla dokončena"
        />
      }
    >
      <FormattedMessage
        id="KS_P_REGISTRATION_COMPLETE_SUBTITLE"
        defaultMessage="Potvrdte registraci kliknutím na zaslaný odkaz."
      />
    </PublicPage>
  );
}
