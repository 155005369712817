import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  DetailHandle,
  DetailContext,
  DetailToolbarButton,
  UserContext,
  ConfirmDialog,
} from '@eas/common-web';
import { Operator } from '../../../common/models';
import { useOperators } from './operators-hook';
import { useStyles } from './operators-styles';
import { OperatorPwChangeDialog } from './operators-pw-dialog';
import { Permissions } from '../../../common/permissions';

export function OperatorsToolbar() {
  const classes = useStyles();
  const { isExisting, source, onPersisted } = useContext<
    DetailHandle<Operator>
  >(DetailContext);

  const {
    id,
    passwordChangeDialog,
    confirmDeactivateDialog,
    confirmActivateDialog,
    activate,
    deactivate,
    changePassword,
    openActivateDialog,
    openDeactivateDialog,
  } = useOperators({ source, onPersisted });
  const { hasPermission } = useContext(UserContext);

  const show = hasPermission(Permissions.ADMINISTRATOR);

  return (
    <>
      {show && isExisting && (
        <>
          <ButtonGroup
            size="small"
            variant="outlined"
            className={classes.toolbarIndentLeft}
          >
            {source.data?.active ? (
              <DetailToolbarButton
                label={
                  <FormattedMessage
                    id="KS_O_OPERATORS_TOOLBAR_BTN_DEACTIVATE"
                    defaultMessage="Deaktivovat"
                  />
                }
                tooltip={
                  <FormattedMessage
                    id="KS_O_OPERATORS_TOOLBAR_TOOLTIP_DEACTIVATE"
                    defaultMessage="Uživatel se nebude moci přihlásit"
                  />
                }
                onClick={openDeactivateDialog}
              />
            ) : (
              <DetailToolbarButton
                label={
                  <FormattedMessage
                    id="KS_O_OPERATORS_TOOLBAR_BTN_ACTIVATE"
                    defaultMessage="Aktivovat"
                  />
                }
                tooltip={
                  <FormattedMessage
                    id="KS_O_OPERATORS_TOOLBAR_TOOLTIP_ACTIVATE"
                    defaultMessage="Uživatel se bude moci přihlásit"
                  />
                }
                onClick={openActivateDialog}
              />
            )}
            <DetailToolbarButton
              label={
                <FormattedMessage
                  id="KS_O_OPERATORS_TOOLBAR_BTN_PW_CHANGE"
                  defaultMessage="Změnit heslo"
                />
              }
              tooltip={
                <FormattedMessage
                  id="KS_O_OPERATORS_TOOLBAR_TOOLTIP_PW_CHANGE"
                  defaultMessage="Otevře dialog pro změnu hesla"
                />
              }
              onClick={changePassword}
            />
          </ButtonGroup>
          <OperatorPwChangeDialog ref={passwordChangeDialog} id={id!} />
          <ConfirmDialog
            ref={confirmDeactivateDialog}
            onConfirm={deactivate}
            onCancel={noop}
            title={
              <FormattedMessage
                id="KS_DEACTIVATE_OPERATOR_DIALOG_TITLE"
                defaultMessage="Varování"
              />
            }
            text={
              <FormattedMessage
                id="KS_DEACTIVATE_OPERATOR_DIALOG_TEXT"
                defaultMessage="Účet bude deaktivován. Uživatel se nebude moci přihlásit ke svému účtu. Skutečně chcete pokračovat?"
              />
            }
          />
          <ConfirmDialog
            ref={confirmActivateDialog}
            onConfirm={activate}
            onCancel={noop}
            title={
              <FormattedMessage
                id="KS_ACTIVATE_OPERATOR_DIALOG_TITLE"
                defaultMessage="Varování"
              />
            }
            text={
              <FormattedMessage
                id="KS_ACTIVATE_OPERATOR_DIALOG_TEXT"
                defaultMessage="Účet bude aktivován. Skutečně chcete pokračovat?"
              />
            }
          />
        </>
      )}
    </>
  );
}
