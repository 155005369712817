import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';

export function useExportTags() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: ExportTags.TICKET_GRID, name: 'Přehled dotazů' },
    { id: ExportTags.TICKET_DETAIL, name: 'Detail dotazu' },

    { id: ExportTags.FAQ_GRID, name: 'Přehled FAQ' },
    { id: ExportTags.FAQ_DETAIL, name: 'Detail FAQ' },

    { id: ExportTags.ACTIVITY_GRID, name: 'Přehled aktivit' },
    { id: ExportTags.ACTIVITY_DETAIL, name: 'Detail aktivity' },

    { id: ExportTags.NOTICE_GRID, name: 'Přehled výzev' },
    { id: ExportTags.NOTICE_DETAIL, name: 'Detail výzvy' },

    { id: ExportTags.ORGANISATION_TYPE_GRID, name: 'Přehled typů organizací' },
    { id: ExportTags.ORGANISATION_TYPE_DETAIL, name: 'Detail typu organizací' },

    { id: ExportTags.PROJECT_PHASE_GRID, name: 'Přehled fází projektů' },
    { id: ExportTags.PROJECT_PHASE_DETAIL, name: 'Detail fáze projektů' },

    { id: ExportTags.REGION_GRID, name: 'Přehled krajů' },
    { id: ExportTags.REGION_DETAIL, name: 'Detail kraje' },

    { id: ExportTags.TAG_GRID, name: 'Přehled kličových slov' },
    { id: ExportTags.TAG_DETAIL, name: 'Detail klíčového slova' },

    { id: ExportTags.PRIORITY_TARGET_GRID, name: 'Přehled prioritních os' },
    { id: ExportTags.PRIORITY_TARGET_DETAIL, name: 'Detail prioritní osy' },

    { id: ExportTags.SPECIFIC_TARGET_GRID, name: 'Přehled specifických cílů' },
    { id: ExportTags.SPECIFIC_TARGET_DETAIL, name: 'Detail specifického cíle' },

    { id: ExportTags.OPERATOR_GRID, name: 'Přehled operátorů' },
    { id: ExportTags.OPERATOR_DETAIL, name: 'Detail operátora' },

    { id: ExportTags.REQUESTER_GRID, name: 'Přehled tazatelů' },
    { id: ExportTags.REQUESTER_DETAIL, name: 'Detail tazatele' },

    { id: ExportTags.REGISTRATION_GRID, name: 'Přehled registrací' },
    { id: ExportTags.REGISTRATION_DETAIL, name: 'Detail registrace' },

    {
      id: ExportTags.PASSWORD_RESET_GRID,
      name: 'Přehled žádostí o reset hesla',
    },
    {
      id: ExportTags.PASSWORD_RESET_DETAIL,
      name: 'Detail žádosti o reset hesla',
    },

    { id: ExportTags.RULE_GRID, name: 'Přehled pravidel' },
    { id: ExportTags.RULE_DETAIL, name: 'Detail pravidla' },

    { id: ExportTags.EXPORT_TEMPLATE_GRID, name: 'Přehled tiskových šablon' },
    { id: ExportTags.EXPORT_TEMPLATE_DETAIL, name: 'Detail tiskové šablony' },

    { id: ExportTags.EXPORT_REQUEST_GRID, name: 'Přehled tiskové fronty' },
    {
      id: ExportTags.EXPORT_REQUEST_DETAIL,
      name: 'Detail záznamu tiskové fronty',
    },

    {
      id: ExportTags.NOTIFICATION_TEMPLATE_GRID,
      name: 'Přehled šablon notifikací',
    },
    {
      id: ExportTags.NOTIFICATION_TEMPLATE_DETAIL,
      name: 'Detail šablony notifikací',
    },

    { id: ExportTags.SEQUENCE_GRID, name: 'Přehled číselných řad' },
    { id: ExportTags.SEQUENCE_DETAIL, name: 'Detail číselné řady' },

    { id: ExportTags.TRANSLATION_GRID, name: 'Přehled překladů' },
    { id: ExportTags.TRANSLATION_DETAIL, name: 'Detail překladu' },
  ]);
}
