import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  commentHeaderText: {
    textDecoration: 'underline',
    fontSize: 14,
  },
  comment: {
    backgroundColor: '#FAFCFD',
  },
  commentFromOperator: {
    backgroundColor: '#dcedf5',
  },
  commentInternal: {
    backgroundColor: '#f5f2c6',
  },
  commentDialogWrapper: {
    display: 'flex',
    width: 800,
  },
  typography: {
    whiteSpace: 'pre-line',
  },
}));
