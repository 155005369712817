import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormTextField,
  FormPanel,
  FormCheckbox,
  UserContext,
  DetailContext,
  DetailMode,
} from '@eas/common-web';
import { Permissions } from '../../../common/permissions';

export function RequesterFields() {
  const intl = useIntl();
  const { hasPermission } = useContext(UserContext);
  const { mode } = useContext(DetailContext);

  const isAdministrator = hasPermission(Permissions.ADMINISTRATOR);
  const isEditor = hasPermission(Permissions.EDITOR);
  const isAdminOrEditor = isAdministrator || isEditor;

  const allowEdit = isAdministrator || (isEditor && mode === DetailMode.NEW);

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="KS_O_REQUESTERS_FIELD_PANEL_TITLE"
          defaultMessage="Tazatel"
        />
      }
    >
      <FormTextField
        name="firstName"
        disabled={!allowEdit}
        required
        label={
          <FormattedMessage
            id="KS_O_REQUESTERS_FIELD_LABEL_FIRST_NAME"
            defaultMessage="Jméno"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_REQUESTERS_FIELD_HELP_FIRST_NAME',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="lastName"
        disabled={!allowEdit}
        required
        label={
          <FormattedMessage
            id="KS_O_REQUESTERS_FIELD_LABEL_LAST_NAME"
            defaultMessage="Příjmení"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_REQUESTERS_FIELD_HELP_LAST_NAME',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="organisationName"
        disabled={!allowEdit}
        required
        label={
          <FormattedMessage
            id="KS_O_REQUESTERS_FIELD_LABEL_ORGANISATION_NAME"
            defaultMessage="Název organizace"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_REQUESTERS_FIELD_HELP_ORGANISATION_NAME',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="organisationIc"
        disabled={!allowEdit}
        required
        label={
          <FormattedMessage
            id="KS_O_REQUESTERS_FIELD_LABEL_ORGANISATION_IC"
            defaultMessage="IČ organizace"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_REQUESTERS_FIELD_HELP_ORGANISATION_IC',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="email"
        disabled
        required
        label={
          <FormattedMessage
            id="KS_O_REQUESTERS_FIELD_LABEL_EMAIL"
            defaultMessage="Email"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_REQUESTERS_FIELD_HELP_EMAIL',
          defaultMessage: ' ',
        })}
      />
      {isAdminOrEditor && (
        <FormTextField
          name="phone"
          disabled={!allowEdit}
          label={
            <FormattedMessage
              id="KS_O_REQUESTERS_FIELD_LABEL_PHONE"
              defaultMessage="Telefon"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_REQUESTERS_FIELD_HELP_PHONE',
            defaultMessage: 'Vložte číslo v mezinárodním formátu +420xxxxxxxxx',
          })}
        />
      )}
      <FormTextField
        name="flag"
        label={
          <FormattedMessage
            id="KS_O_REQUESTERS_FIELD_LABEL_FLAG"
            defaultMessage="Příznak"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_REQUESTERS_FIELD_HELP_FLAG',
          defaultMessage: ' ',
        })}
      />
      <FormCheckbox
        name="active"
        disabled
        label={
          <FormattedMessage
            id="KS_O_REQUESTERS_FIELD_LABEL_ACTIVE"
            defaultMessage="Aktivní"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_REQUESTERS_FIELD_HELP_ACTIVE',
          defaultMessage: ' ',
        })}
      />
    </FormPanel>
  );
}
