import React, { PropsWithChildren, useContext } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormContext } from '@eas/common-web';
import { KsPublicFormItemProps } from './ks-public-form-item-types';
import { KsPublicFormLabel } from '../ks-public-form-label/ks-public-form-label';
import { FormattedMessage } from 'react-intl';

export function KsPublicFormItem({
  name,
  required,
  helpLabel,
  children,
  label,
  className,
}: PropsWithChildren<KsPublicFormItemProps>) {
  const { errors } = useContext(FormContext);

  const fieldErrors = errors.filter((e) => e.key === name);
  const hasError = fieldErrors.length > 0;

  const childrenProps = {
    labelOptions: { hide: true },
    errorOptions: { hide: true },
  };

  const wrappedChildren = React.Children.toArray(children)
    .filter(React.isValidElement)
    .map((child) => React.cloneElement(child, childrenProps))[0];

  return (
    <div className={className}>
      {label && (
        <KsPublicFormLabel
          error={hasError}
          helpLabel={helpLabel}
          required={required}
        >
          {label}
        </KsPublicFormLabel>
      )}
      {wrappedChildren}
      {fieldErrors?.map((error, index) => {
        const [key, defaultMessage] = error.value.split(';;');

        return (
          <FormHelperText key={index} error={true}>
            <FormattedMessage id={key} defaultMessage={defaultMessage} />
          </FormHelperText>
        );
      })}
    </div>
  );
}
