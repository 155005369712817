import React from 'react';
import { Form, TextArea, FormPanel, RichEditor } from '@eas/common-web';
import { TicketQuestion } from './tickets-types';
import { TicketComments } from './comments/ticket-comments';
import { Ticket } from '../../../common/models';
import { FormattedMessage } from 'react-intl';

export function TicketDialogLeftPanel({ ticket }: { ticket: Ticket | null }) {
  return (
    <Form<TicketQuestion>
      editing={false}
      initialValues={{ question: ticket?.question ?? '' }}
      onSubmit={() => {}}
    >
      <FormPanel
        label={
          <FormattedMessage
            id="KS_O_TICKETS_FIELD_LABEL_QUESTION"
            defaultMessage="Otázka"
          />
        }
      >
        <TextArea disabled maxRows={25} value={ticket?.question ?? ''} />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="KS_O_TICKETS_FIELD_LABEL_NOTE"
            defaultMessage="Poznámka"
          />
        }
      >
        {ticket?.formatting ? (
          <RichEditor disabled value={ticket?.note ?? ''} onChange={() => {}} />
        ) : (
          <TextArea disabled maxRows={25} value={ticket?.note ?? ''} />
        )}
      </FormPanel>
      {ticket != null && <TicketComments id={ticket.id} ticket={ticket} />}
    </Form>
  );
}
