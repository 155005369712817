import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Panel,
  FormSelect,
  FormTextField,
  useFormSelector,
  FormContext,
} from '@eas/common-web';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TicketExternalCreate } from '../../../../common/models';
import {
  useProjectPhases,
  useSpecificTargets,
  useNotices,
  useRegions,
  useActivities,
} from '../../requester-api';
import { TicketCustomFields } from './custom-fields/ticket-custom-fields';
import { KsPublicFormItem } from '../../../public/components/ks-public-form-item/ks-public-form-item';

export function ProjectPanel() {
  const intl = useIntl();
  const { setFieldValue, getFieldValues } = useContext(FormContext);

  const activity = useFormSelector(
    (data: TicketExternalCreate) => data.activity
  );

  const projectPhases = useProjectPhases();
  const regions = useRegions();
  const specificTargets = useSpecificTargets();
  const notices = useNotices(activity);
  const activities = useActivities();

  return (
    <Panel
      label={
        <FormattedMessage
          id="KS_P_TICKET_REQUESTER_PROJECT_TITLE"
          defaultMessage="Projekt"
        />
      }
      sideBorder={true}
    >
      <Box display="flex" flexDirection="row" width="100%" padding="16px">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <KsPublicFormItem
              required
              name="projectPhase"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_PROJECT_PHASE',
                defaultMessage:
                  'Pokud dotaz směřuje k již podané žádosti, kontaktujte manažera projektu přímo',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_PROJECT_PHASE"
                  defaultMessage="Dotaz k fázi projektu"
                />
              }
            >
              <FormSelect
                required
                name="projectPhase"
                source={projectPhases}
                tooltipMapper={(o) => o.name}
              />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={4}>
            <KsPublicFormItem
              name="projectNumber"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_PROJECT_NUMBER',
                defaultMessage:
                  'Pokud již máte přiděleno registrační číslo projektu, kontaktujte s dotazem svého manažera projektu. Vyplňte poslední pětičíslí registračního čísla projektu (např. 01234)',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_PROJECT_NUMBER"
                  defaultMessage="Číslo projektu"
                />
              }
            >
              <FormTextField name="projectNumber" />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={4}>
            <KsPublicFormItem
              name="projectName"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_PROJECT_NAME',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_PROJECT_NAME"
                  defaultMessage="Název projektu"
                />
              }
            >
              <FormTextField required name="projectName" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="region"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_REGION',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_REGION"
                  defaultMessage="Kraj realizace"
                />
              }
            >
              <FormSelect
                required
                name="region"
                source={regions}
                tooltipMapper={(o) => o.name}
              />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              name="place"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_PLACE',
                defaultMessage:
                  'Konkretizujte místo realizace projektu např. Obec nebo ulice',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_PLACE"
                  defaultMessage="Místo realizace"
                />
              }
            >
              {' '}
              <FormTextField required name="place" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="activity"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_ACTIVITY',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_ACTIVITY"
                  defaultMessage="Aktivita"
                />
              }
            >
              <FormSelect
                required
                name="activity"
                source={activities}
                tooltipMapper={(o) => o.name}
                notifyChange={() => {
                  setFieldValue('notice', undefined);
                  setFieldValue('specificTarget', undefined);
                }}
              />
            </KsPublicFormItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KsPublicFormItem
              required
              name="notice"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_NOTICE',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_NOTICE"
                  defaultMessage="Výzva"
                />
              }
            >
              <FormSelect
                required
                disabled={activity == null}
                name="notice"
                source={notices}
                tooltipMapper={(o) => o.name}
                notifyChange={() => {
                  requestAnimationFrame(() => {
                    const notice = getFieldValues()['notice'];
                    setFieldValue('specificTarget', notice?.parent);
                  });
                }}
              />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={12}>
            <KsPublicFormItem
              name="specificTarget"
              helpLabel={intl.formatMessage({
                id: 'KS_R_TICKETS_FIELD_HELP_SPECIFIC_TARGET',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_R_TICKETS_FIELD_LABEL_SPECIFIC_TARGET"
                  defaultMessage="Specifický cíl"
                />
              }
            >
              <FormSelect
                disabled
                name="specificTarget"
                source={specificTargets}
                tooltipMapper={(o) => o.name}
              />
            </KsPublicFormItem>
          </Grid>

          <TicketCustomFields />
        </Grid>
      </Box>
    </Panel>
  );
}
