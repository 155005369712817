import { abortableFetch } from '@eas/common-web';

/**
 * Calls change password api.
 *
 * @param id Id of the requester
 * @param password Password to set
 */
export function changeRequesterPassword(id: string, password: string) {
  return abortableFetch(`/api/ks/requesters/${id}/password`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: password,
  });
}

export function validateEmail(email: string, id?: string) {
  return abortableFetch('/api/ks/registrations/validate-existing-email', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ id, email }),
  });
}
