import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Panel, FormTextArea } from '@eas/common-web';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { KsPublicFormItem } from '../../../public/components/ks-public-form-item/ks-public-form-item';

export function QuestionPanel() {
  return (
    <Panel
      label={
        <FormattedMessage
          id="KS_P_TICKET_REQUEST_PROJECT_TITLE"
          defaultMessage="Dotaz"
        />
      }
      sideBorder={true}
    >
      <Box display="flex" flexDirection="row" width="100%" padding="16px">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <KsPublicFormItem name="question">
              <FormTextArea maxRows={25} required name="question" />
            </KsPublicFormItem>
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
}
