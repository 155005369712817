import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Panel, FormFileTable, ContextHelp } from '@eas/common-web';
import Grid from '@material-ui/core/Grid';
import { KsPublicFormItem } from '../../../public/components/ks-public-form-item/ks-public-form-item';

export function FilesPanel() {
  const intl = useIntl();

  return (
    <Panel
      label={
        <>
          <FormattedMessage
            id="KS_P_TICKET_FILES_TITLE"
            defaultMessage="Soubory"
          />
          <ContextHelp
            type="HOVER"
            label={intl.formatMessage({
              id: 'KS_P_TICKET_FILES_HELP',
              defaultMessage:
                'Maximální velikost přílohy je 5MB a podporované soubory jsou *.docx, *.doc, *.xlsx, *.xls, *.rtf, *.odt, *.ods, *.pdf, *.png a *.jpg',
            })}
          />
        </>
      }
      sideBorder={true}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12}>
          <KsPublicFormItem name="files">
            <FormFileTable name="files" maxItems={5} />
          </KsPublicFormItem>
        </Grid>
      </Grid>
    </Panel>
  );
}
