import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormTextField,
  FormPanel,
  FormCheckbox,
  UserContext,
} from '@eas/common-web';
import { Permissions } from '../../../common/permissions';

export function OperatorFields() {
  const intl = useIntl();
  const { hasPermission } = useContext(UserContext);

  const isAdministrator = hasPermission(Permissions.ADMINISTRATOR);

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="KS_O_OPERATORS_FIELD_PANEL_TITLE"
          defaultMessage="Řešitel"
        />
      }
    >
      <FormTextField
        name="firstName"
        required
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_FIRST_NAME"
            defaultMessage="Jméno"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_FIRST_NAME',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="lastName"
        required
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_LAST_NAME"
            defaultMessage="Příjmení"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_LAST_NAME',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="email"
        required
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_EMAIL"
            defaultMessage="Email"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_EMAIL',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="phone"
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_PHONE"
            defaultMessage="Telefon"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_PHONE',
          defaultMessage: 'Vložte číslo v mezinárodním formátu +420xxxxxxxxx',
        })}
      />
      <FormTextField
        name="mobilePhone"
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_MOBILE_PHONE"
            defaultMessage="Mobil"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_MOBILE_PHONE',
          defaultMessage: 'Vložte číslo v mezinárodním formátu +420xxxxxxxxx',
        })}
      />
      <FormTextField
        name="organisationName"
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_ORGANISATION_NAME"
            defaultMessage="Název organizace"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_ORGANISATION_NAME',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="organisationIc"
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_ORGANISATION_IC"
            defaultMessage="IČ organizace"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_ORGANISATION_IC',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="employeeNumber"
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_EMPLOYEE_NUMBER"
            defaultMessage="Organizační jednotka"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_EMPLOYEE_NUMBER',
          defaultMessage: ' ',
        })}
      />
      <FormTextField
        name="externalId"
        disabled
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_EXTERNAL_ID"
            defaultMessage="Externí identifikátor"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_EXTERNAL_ID',
          defaultMessage: ' ',
        })}
      />
      <FormCheckbox
        name="active"
        disabled
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_ACTIVE"
            defaultMessage="Aktivní"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_ACTIVE',
          defaultMessage: ' ',
        })}
      />
      <FormCheckbox
        name="editor"
        disabled={!isAdministrator}
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_EDITOR"
            defaultMessage="Editor"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_EDITOR',
          defaultMessage: ' ',
        })}
      />
      <FormCheckbox
        name="administrator"
        disabled={!isAdministrator}
        label={
          <FormattedMessage
            id="KS_O_OPERATORS_FIELD_LABEL_ADMNISTRATOR"
            defaultMessage="Administrátor"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_OPERATORS_FIELD_HELP_ADMINISTRATOR',
          defaultMessage: ' ',
        })}
      />
    </FormPanel>
  );
}
