import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  DetailHandle,
  DetailContext,
  DetailToolbarButton,
  UserContext,
  ConfirmDialog,
} from '@eas/common-web';
import { Faq } from '../../../common/models';
import { useFaqs } from './faqs-hook';
import { useStyles } from './faqs-styles';
import { Permissions } from '../../../common/permissions';

export function FaqsToolbar() {
  const classes = useStyles();
  const { isExisting, source, onPersisted } = useContext<DetailHandle<Faq>>(
    DetailContext
  );

  const {
    publishDialog,
    unpublishDialog,
    publish,
    unpublish,
    openPublishDialog,
    openUnpublishDialog,
  } = useFaqs({ source, onPersisted });
  const { hasPermission } = useContext(UserContext);

  const show = hasPermission(Permissions.EDITOR);

  return (
    <>
      {show && isExisting && (
        <>
          <ButtonGroup
            size="small"
            variant="outlined"
            className={classes.toolbarIndentLeft}
          >
            {!source.data?.published ? (
              <DetailToolbarButton
                label={
                  <FormattedMessage
                    id="KS_O_FAQ_TOOLBAR_BTN_PUBLISH"
                    defaultMessage="Zveřejnit"
                  />
                }
                tooltip={
                  <FormattedMessage
                    id="KS_O_FAQ_TOOLBAR_TOOLTIP_PUBLISH"
                    defaultMessage="FAQ bude dostupné tazatelům"
                  />
                }
                onClick={openPublishDialog}
              />
            ) : (
              <DetailToolbarButton
                label={
                  <FormattedMessage
                    id="KS_O_FAQ_TOOLBAR_BTN_UNPUBLISH"
                    defaultMessage="Zrušit zveřejnění"
                  />
                }
                tooltip={
                  <FormattedMessage
                    id="KS_O_FAQ_TOOLBAR_TOOLTIP_UNPUBLISH"
                    defaultMessage="FAQ nebude dostupne tazatelům"
                  />
                }
                onClick={openUnpublishDialog}
              />
            )}
          </ButtonGroup>
          <ConfirmDialog
            ref={publishDialog}
            title={
              <FormattedMessage
                id="KS_O_FAQ_TOOLBAR_PUBLISH_DIALOG_TITLE"
                defaultMessage="Zveřejnění FAQ"
              />
            }
            text={
              <FormattedMessage
                id="KS_O_FAQ_TOOLBAR_PUBLISH_DIALOG_TEXT"
                defaultMessage="Potvrzením se stane vybrané FAQ viditelné pro veřejnost!"
              />
            }
            onConfirm={publish}
          />
          <ConfirmDialog
            ref={unpublishDialog}
            title={
              <FormattedMessage
                id="KS_O_FAQ_TOOLBAR_UNPUBLISH_DIALOG_TITLE"
                defaultMessage="Zrušit zveřejnění FAQ"
              />
            }
            text={
              <FormattedMessage
                id="KS_O_FAQ_TOOLBAR_UNPUBLISH_DIALOG_TEXT"
                defaultMessage="Potvrzením se stane vybrané FAQ neviditelné pro veřejnost!"
              />
            }
            onConfirm={unpublish}
          />
        </>
      )}
    </>
  );
}
