import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { LoginOperatorExternal } from './login-operator-external';

export function LoginOperatorExternalWrapper() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LeLOd0ZAAAAAASD3guled-gDeL0gg4YTvqFFQiT"
      language="cs"
    >
      <LoginOperatorExternal />
    </GoogleReCaptchaProvider>
  );
}
