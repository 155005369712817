import { useIntl } from 'react-intl';
import {
  TableColumn,
  TableCells,
  TableFilterCells,
  PersonalEvent,
  useStaticListSource,
  DictionaryAutocomplete,
} from '@eas/common-web';

export function usePersonalEventTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'LOGIN_SUCCESSFUL', name: 'Uspěšné přihlášení' },
    { id: 'LOGIN_FAILED', name: 'Neuspěšné přihlášení' },
    { id: 'LOGOUT', name: 'Odhlášení' },
    { id: 'LOGOUT_AUTOMATIC', name: 'Automatické odhlášení' },
  ]);
}

export function useColumns(): TableColumn<PersonalEvent>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'KS_O_PERSONAL_EVENT_COLUMN_CREATED',
        defaultMessage: 'Vytvoření',
      }),
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: intl.formatMessage({
        id: 'KS_O_PERSONAL_EVENT_COLUMN_TYPE',
        defaultMessage: 'Událost',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        usePersonalEventTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(usePersonalEventTypes),
      sortable: true,
      filterable: true,
    },
  ];
}
