import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RegistrationComplete } from './registration/registration-complete';
import { PasswordReset } from './password-reset/password-reset';
import { PasswordResetComplete } from './password-reset-complete/password-reset-complete';
import { PasswordResetRequestComplete } from './password-reset-request-complete/password-reset-request-complete';
import { RegistrationConfirm } from './registration-confirm/registration-confirm';
import { RegistrationConfirmComplete } from './registration-confirm-complete/registration-confirm-complete';
import { LoginWrapper } from './login/login-wrapper';
import { LoginOperatorExternalWrapper } from './login-operator-external/login-operator-external-wrapper';
import { PasswordResetRequestWrapper } from './password-reset-request/password-reset-request-wrapper';
import { RegistrationWrapper } from './registration/registration-wrapper';
import { OperatorPasswordResetRequestWrapper } from './password-reset-request/operator-password-reset-request-wrapper';

export function PublicApp() {
  return (
    <Switch>
      <Route exact path="/" component={LoginWrapper} />
      <Route exact path="/answers" component={LoginOperatorExternalWrapper} />
      <Route path="/registration" component={RegistrationWrapper} />
      <Route path="/registration-complete" component={RegistrationComplete} />
      <Route
        path="/registration-confirm/:secret"
        component={RegistrationConfirm}
      />
      <Route
        path="/registration-confirm-complete"
        component={RegistrationConfirmComplete}
      />
      <Route path="/password-reset/:secret" component={PasswordReset} />
      <Route
        path="/password-reset-request"
        component={PasswordResetRequestWrapper}
      />
      <Route
        path="/password-reset-request-complete"
        component={PasswordResetRequestComplete}
      />
      <Route
        path="/password-reset-complete"
        component={PasswordResetComplete}
      />
      <Route
        path="/operator-password-reset-request"
        component={OperatorPasswordResetRequestWrapper}
      />
    </Switch>
  );
}
