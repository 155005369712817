import { useIntl } from 'react-intl';
import { TableColumn, TableCells, UserContext } from '@eas/common-web';
import { Requester } from '../../../common/models';
import { useContext } from 'react';
import { Permissions } from '../../../common/permissions';
import { compact } from 'lodash';

export function useColumns(): TableColumn<Requester>[] {
  const { hasPermission } = useContext(UserContext);
  const isAdminOrEditor =
    hasPermission(Permissions.ADMINISTRATOR) ||
    hasPermission(Permissions.EDITOR);

  const intl = useIntl();
  return compact([
    {
      datakey: 'firstName',
      name: intl.formatMessage({
        id: 'KS_O_REQUESTERS_COLUMN_FIRST_NAME',
        defaultMessage: 'Jméno',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'lastName',
      name: intl.formatMessage({
        id: 'KS_O_REQUESTERS_COLUMN_LAST_NAME',
        defaultMessage: 'Příjmení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'organisationName',
      name: intl.formatMessage({
        id: 'KS_O_REQUESTERS_COLUMN_ORGANISATION_NAME',
        defaultMessage: 'Název organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'organisationIc',
      name: intl.formatMessage({
        id: 'KS_O_REQUESTERS_COLUMN_ORGANISATION_IC',
        defaultMessage: 'IČ organizace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'email',
      name: intl.formatMessage({
        id: 'KS_O_REQUESTERS_COLUMN_EMAIL',
        defaultMessage: 'Email',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    isAdminOrEditor
      ? {
          datakey: 'phone',
          name: intl.formatMessage({
            id: 'KS_O_REQUESTERS_COLUMN_PHONE',
            defaultMessage: 'Telefon',
          }),
          width: 200,
          CellComponent: TableCells.TextCell,
          sortable: true,
          filterable: true,
        }
      : undefined,
    {
      datakey: 'flag',
      name: intl.formatMessage({
        id: 'KS_O_REQUESTERS_COLUMN_FLAG',
        defaultMessage: 'Příznak',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'active',
      name: intl.formatMessage({
        id: 'KS_O_REQUESTERS_COLUMN_ACTIVE',
        defaultMessage: 'Aktivní',
      }),
      width: 100,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
  ]);
}
