import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Registration } from './registration';

export function RegistrationWrapper() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LeLOd0ZAAAAAASD3guled-gDeL0gg4YTvqFFQiT"
      language="cs"
    >
      <Registration />
    </GoogleReCaptchaProvider>
  );
}
