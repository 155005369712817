import React, { useContext } from 'react';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { LocaleContext, LocaleName, useEventCallback } from '@eas/common-web';
import { useStyles } from './langbar-styles';

export function Langbar() {
  const { locale, switchLocale } = useContext(LocaleContext);

  const handleCzClick = useEventCallback(() => {
    switchLocale(LocaleName.cs);
  });

  const handleEnClick = useEventCallback(() => {
    switchLocale(LocaleName.en);
  });

  const classes = useStyles();

  return (
    <Box display="flex" className={classes.root}>
      <Button
        size="small"
        disableElevation
        variant={locale.name === LocaleName.cs ? 'contained' : undefined}
        color={locale.name === LocaleName.cs ? 'primary' : undefined}
        className={clsx(classes.language, {
          [classes.selected]: locale.name === LocaleName.cs,
        })}
        onClick={handleCzClick}
      >
        CS
      </Button>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Button
        size="small"
        disableElevation
        variant={locale.name === LocaleName.en ? 'contained' : undefined}
        color={locale.name === LocaleName.en ? 'primary' : undefined}
        className={clsx(classes.language, {
          [classes.selected]: locale.name === LocaleName.en,
        })}
        onClick={handleEnClick}
      >
        EN
      </Button>
    </Box>
  );
}
