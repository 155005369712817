import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useDictionaryEvidence } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { Notice } from '../../../common/models';
import { initNewDisabledItem } from '../../../utils/dictionary-item';
import { NoticesFields } from './notices-fields';
import { useColumns } from './notices-columns';
import { useValidationSchema } from './notices-schema';

export function OperatorNotices() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<Notice>({
    identifier: 'NOTICES',
    apiProps: {
      url: '/api/ks/notices',
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'KS_O_NOTICES_TABLE_TITLE',
        defaultMessage: 'Výzvy',
      }),
      reportTag: ExportTags.NOTICE_GRID,
    },
    detailProps: {
      FieldsComponent: NoticesFields,
      validationSchema,
      initNewItem: initNewDisabledItem,
    },
  });

  return <Evidence {...evidence} />;
}
