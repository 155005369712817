import { useListSource, DetailContext } from '@eas/common-web';
import { TicketComment } from '../../../../common/models';
import { useContext, useEffect } from 'react';

export function useComments(id: string) {
  const source = useListSource<TicketComment>({
    url: `/api/ks/tickets/${id}/comments/list`,
  });

  const { addRefreshListener, removeRefreshListener } = useContext(
    DetailContext
  );

  useEffect(() => {
    addRefreshListener(source.reset);

    return () => {
      removeRefreshListener(source.reset);
    };
  });

  return {
    source,
  };
}
