import React, { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { DialogHandle, Dialog, Form, FormTextField } from '@eas/common-web';
import { PasswordChange } from './profile-types';
import { useStyles } from './profile-styles';

import { usePasswordChangeDialog } from './password-change-dialog-hook';

export const PasswordChangeDialog = forwardRef<DialogHandle, {}>(
  function PasswordChangeDialog({}, dialogRef) {
    const classes = useStyles();
    const {
      ref,
      loading,
      validationSchema,
      handleConfirm,
      handleSubmit,
    } = usePasswordChangeDialog();

    const intl = useIntl();

    return (
      <Dialog
        ref={dialogRef}
        loading={loading}
        title={
          <FormattedMessage
            id="KS_R_PROFILE_PW_CHANGE_DIALOG_TITLE"
            defaultMessage="Změna hesla"
          />
        }
        onConfirm={handleConfirm}
      >
        {() => (
          <Form<PasswordChange>
            editing={!loading}
            initialValues={{
              oldPassword: '',
              newPassword: '',
              newPasswordAgain: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            ref={ref}
          >
            <Grid container className={classes.dialogWrapper}>
              <Grid item xs={12} sm={12}>
                <FormTextField
                  type="password"
                  required
                  name="oldPassword"
                  autocomplete="current-password"
                  label={
                    <FormattedMessage
                      id="KS_R_PROFILE_PW_CHANGE_DIALOG_FIELD_LABEL_OLD_PASSWORD"
                      defaultMessage="Staré heslo"
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormTextField
                  type="password"
                  required
                  name="newPassword"
                  autocomplete="new-password"
                  label={
                    <FormattedMessage
                      id="KS_R_PROFILE_PW_CHANGE_DIALOG_FIELD_LABEL_NEW_PASSWORD"
                      defaultMessage="Nové heslo"
                    />
                  }
                  helpLabel={intl.formatMessage({
                    id: 'KS_R_PROFILE_PW_CHANGE_DIALOG_FIELD_HELP_NEW_PASSWORD',
                    defaultMessage: 'Heslo musí obsahovat 8-64 znaků',
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormTextField
                  type="password"
                  required
                  name="newPasswordAgain"
                  autocomplete="new-password"
                  label={
                    <FormattedMessage
                      id="KS_R_PROFILE_PW_CHANGE_DIALOG_FIELD_LABEL_NEW_PASSWORD_AGAIN"
                      defaultMessage="Nové heslo znovu"
                    />
                  }
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Dialog>
    );
  }
);
