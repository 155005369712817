import React, { useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import {
  DetailContext,
  DetailMode,
  DialogHandle,
  DetailToolbarButtonType,
  DetailToolbarButtonMenuItem,
  DetailToolbarButton,
  DetailToolbarButtonMenu,
  useEventCallback,
  SnackbarContext,
  SnackbarVariant,
  NavigationContext,
  EvidenceStateAction,
  UserContext,
} from '@eas/common-web';
import { Ticket, TicketState, Faq } from '../../../common/models';
import { useStyles } from './tickets-styles';
import { ResolveDialog } from './tickets-resolve-dialog';
import { startSolving } from './tickets-api';
import { RequestAmendDialog } from './tickets-amend-dialog';
import { ConsultDialog } from './tickets-consult-dialog';
import { RemoveConsultantDialog } from './tickets-remove-consultant-dialog';
import { CommentDialog } from './tickets-comment-dialog';
import { ChangeOwnerDialog } from './tickets-change-owner-dialog';
import { Permissions } from '../../../common/permissions';
import { ResolveAgainDialog } from './tickets-resolve-again-dialog';

export function TicketsToolbar() {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);
  const { mode, source, refreshAll } = useContext(DetailContext);
  const { navigate } = useContext(NavigationContext);
  const { hasPermission } = useContext(UserContext);

  const classes = useStyles();
  const resolveDialog = useRef<DialogHandle>(null);
  const resolveAgainDialog = useRef<DialogHandle>(null);
  const consultDialog = useRef<DialogHandle>(null);
  const removeConsultantDialog = useRef<DialogHandle>(null);
  const requestAmendDialog = useRef<DialogHandle>(null);
  const commentDialog = useRef<DialogHandle>(null);
  const changeOwnerDialog = useRef<DialogHandle>(null);

  const ticket: Ticket | null = source.data;

  const isEditor = hasPermission(Permissions.EDITOR);

  const handleNewFaq = useEventCallback(() => {
    if (ticket !== null) {
      const faq: Faq = {
        id: undefined as any,
        question: ticket.question ?? '',
        answer: ticket.answer ?? '',
        projectPhases:
          ticket.projectPhase !== undefined ? [ticket.projectPhase] : [],
        regions: ticket.region !== undefined ? [ticket.region] : [],
        specificTargets:
          ticket.specificTarget !== undefined ? [ticket.specificTarget] : [],
        notices: ticket.notice !== undefined ? [ticket.notice] : [],
        activities: ticket.activity !== undefined ? [ticket.activity] : [],
        tags: ticket.tags,
        source: ticket,
      };

      navigate('/operator/faqs', false, {
        action: EvidenceStateAction.NEW_ITEM,
        data: faq,
      });
    }
  });

  const handleStartSolvingClick = useEventCallback(async () => {
    try {
      source.setLoading(true);

      await startSolving(ticket!.id).none();

      const message = intl.formatMessage({
        id: 'KS_O_TICKETS_TOOLBAR_START_SOLVING_MSG_SUCCESS',
        defaultMessage: 'Byl změněn stav dotazu na v řešení',
      });

      showSnackbar(message, SnackbarVariant.SUCCESS);
      source.setLoading(false);

      refreshAll();
    } catch (err) {
      source.setLoading(false);
    }
  });

  const handleResolveClick = useEventCallback(() => {
    resolveDialog.current?.open();
  });

  const handleResolveAgainClick = useEventCallback(() => {
    resolveAgainDialog.current?.open();
  });

  const handleConsultClick = useEventCallback(() => {
    consultDialog.current?.open();
  });

  const handleRemoveConsultantClick = useEventCallback(() => {
    removeConsultantDialog.current?.open();
  });

  const handleRequestAmendClick = useEventCallback(() => {
    requestAmendDialog.current?.open();
  });

  const handleCommentClick = useEventCallback(() => {
    commentDialog.current?.open();
  });

  const handleChangeOwnerClick = useEventCallback(() => {
    changeOwnerDialog.current?.open();
  });

  const isNew = ticket?.state === TicketState.NEW;
  const isProcessing = ticket?.state === TicketState.PROCESSING;
  const isReturned = ticket?.state === TicketState.RETURNED;
  const isResolved = ticket?.state === TicketState.RESOLVED;
  const isViewMode = mode === DetailMode.VIEW;

  const items: DetailToolbarButtonMenuItem[] = [];
  if (isProcessing) {
    items.push({
      label: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_BTN_CONSULT"
          defaultMessage="Přidat konzultanta"
        />
      ),
      tooltip: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_TOOLTIP_CONSULT"
          defaultMessage="Otevře okno pro žádost o konzultaci"
        />
      ),
      onClick: handleConsultClick,
    });

    items.push({
      label: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_BTN_REMOVE_CONSULTANT"
          defaultMessage="Odebrat konzultanta"
        />
      ),
      tooltip: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_TOOLTIP_REMOVE_CONSULTANT"
          defaultMessage="Otevře okno pro zrušení konzultace"
        />
      ),
      onClick: handleRemoveConsultantClick,
    });

    items.push({
      label: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_BTN_REQUEST_AMEND"
          defaultMessage="Vyžádat doplnění"
        />
      ),
      tooltip: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_TOOLTIP_REQUEST_AMEND"
          defaultMessage="Otevře okno pro žádost o doplnění"
        />
      ),
      onClick: handleRequestAmendClick,
    });
  }

  if (isNew || isProcessing) {
    items.push({
      label: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_BTN_CHANGE_OWNER"
          defaultMessage="Změnit řešitele"
        />
      ),
      tooltip: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_TOOLTIP_CHANGE_OWNER"
          defaultMessage="Otevře okno pro změnu řešitele"
        />
      ),
      onClick: handleChangeOwnerClick,
    });
  }

  if (isViewMode) {
    items.push({
      label: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_BTN_FAQ"
          defaultMessage="Vytvořit FAQ"
        />
      ),
      tooltip: (
        <FormattedMessage
          id="KS_O_TICKETS_TOOLBAR_TOOLTIP_FAQ"
          defaultMessage="Připraví nové FAQ z vybraného dotazu"
        />
      ),
      onClick: handleNewFaq,
    });
  }

  return (
    <>
      {isEditor && isViewMode && (
        <ButtonGroup
          size="small"
          variant="outlined"
          className={classes.toolbarIndentLeft}
        >
          {isNew && (
            <DetailToolbarButton
              label={
                <FormattedMessage
                  id="KS_O_TICKETS_TOOLBAR_BTN_START_SOLVING"
                  defaultMessage="Řešit"
                />
              }
              tooltip={
                <FormattedMessage
                  id="KS_O_TICKETS_TOOLBAR_TOOLTIP_START_SOLVING"
                  defaultMessage="Přepne stav dotazu"
                />
              }
              type={DetailToolbarButtonType.PRIMARY}
              onClick={handleStartSolvingClick}
            />
          )}

          {isProcessing && (
            <DetailToolbarButton
              label={
                <FormattedMessage
                  id="KS_O_TICKETS_TOOLBAR_BTN_RESOLVE"
                  defaultMessage="Odpovědět"
                />
              }
              tooltip={
                <FormattedMessage
                  id="KS_O_TICKETS_TOOLBAR_TOOLTIP_RESOLVE"
                  defaultMessage="Otevře okno pro odpověď"
                />
              }
              type={DetailToolbarButtonType.PRIMARY}
              onClick={handleResolveClick}
            />
          )}
          {(isProcessing || isReturned) && (
            <DetailToolbarButton
              label={
                <FormattedMessage
                  id="KS_O_TICKETS_TOOLBAR_BTN_COMMENT"
                  defaultMessage="Komentovat"
                />
              }
              tooltip={
                <FormattedMessage
                  id="KS_O_TICKETS_TOOLBAR_TOOLTIP_COMMENT"
                  defaultMessage="Otevře okno pro komentář"
                />
              }
              type={DetailToolbarButtonType.PRIMARY}
              onClick={handleCommentClick}
            />
          )}
          {isResolved && (
            <DetailToolbarButton
              label={
                <FormattedMessage
                  id="KS_O_TICKETS_TOOLBAR_BTN_RESOLVE_AGAIN"
                  defaultMessage="Upravit Odpověď"
                />
              }
              tooltip={
                <FormattedMessage
                  id="KS_O_TICKETS_TOOLBAR_TOOLTIP_RESOLVE_AGAIN"
                  defaultMessage="Otevře okno pro novou odpověď"
                />
              }
              type={DetailToolbarButtonType.PRIMARY}
              onClick={handleResolveAgainClick}
            />
          )}

          <DetailToolbarButtonMenu
            label={
              <FormattedMessage
                id="KS_O_TICKETS_TOOLBAR_BTN_MORE_ACTIONS"
                defaultMessage="Další akce"
              />
            }
            type={DetailToolbarButtonType.NORMAL}
            items={items}
          />

          <ResolveDialog ref={resolveDialog} id={ticket?.id} ticket={ticket} />
          <ResolveAgainDialog
            ref={resolveAgainDialog}
            id={ticket?.id}
            ticket={ticket}
          />
          <ConsultDialog ref={consultDialog} id={ticket?.id} ticket={ticket} />
          <RemoveConsultantDialog
            ref={removeConsultantDialog}
            id={ticket?.id}
            ticket={ticket}
          />
          <RequestAmendDialog
            ref={requestAmendDialog}
            id={ticket?.id}
            ticket={ticket}
          />
          <CommentDialog ref={commentDialog} id={ticket?.id} ticket={ticket} />
          <ChangeOwnerDialog
            ref={changeOwnerDialog}
            id={ticket?.id}
            ticket={ticket}
          />
        </ButtonGroup>
      )}
    </>
  );
}
