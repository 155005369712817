import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PublicPage } from '../../../components/public-page/public-page';

export function Logout() {
  return (
    <PublicPage
      title={
        <FormattedMessage id="KS_P_LOGOUT_TITLE" defaultMessage="Odhlášení" />
      }
    >
      <FormattedMessage
        id="KS_P_LOGOUT_SUBTITLE"
        defaultMessage="Byli jste úspěšně odhlášeni."
      />
    </PublicPage>
  );
}
