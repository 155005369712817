import { useIntl } from 'react-intl';
import { TableColumn, TableCells } from '@eas/common-web';
import { PasswordReset } from '../../../common/models';

export function useColumns(): TableColumn<PasswordReset>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'email',
      name: intl.formatMessage({
        id: 'KS_O_PASSWORD_RESETS_COLUMN_EMAIL',
        defaultMessage: 'Email',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'operator',
      name: intl.formatMessage({
        id: 'KS_O_PASSWORD_RESETS_COLUMN_OPERATOR',
        defaultMessage: 'Operátor',
      }),
      width: 120,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'confirmed',
      name: intl.formatMessage({
        id: 'KS_O_PASSWORD_RESETS_COLUMN_CONFIRMED',
        defaultMessage: 'Potvrzena',
      }),
      width: 120,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
  ];
}
