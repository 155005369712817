import {
  useListSource,
  DictionaryAutocomplete,
  useAutocompleteSource,
  useStaticListSource,
} from '@eas/common-web';
import { Notice, Ticket, Operator, Activity } from '../../common/models';
import { ApiFilterOperation } from '@eas/common-web';
import { Permissions } from '../../common/permissions';

export function useOrganisationTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/organisation-types/autocomplete/full',
  });
}

export function useOrganisationTypesAll() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/organisation-types/autocomplete/full/all',
  });
}

export function useProjectPhases() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/project-phases/autocomplete/full',
  });
}

export function useProjectPhasesAll() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/project-phases/autocomplete/full/all',
  });
}

export function useRegions() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/regions/autocomplete/full',
  });
}

export function useRegionsAll() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/regions/autocomplete/full/all',
  });
}

export function useRegionsAllAuto() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/regions/autocomplete/all',
  });
}

export function usePriorityTargets() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/priority-targets/autocomplete/full',
  });
}

export function usePriorityTargetsAll() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/priority-targets/autocomplete/full/all',
  });
}

export function useSpecificTargets() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/specific-targets/autocomplete/full',
  });
}

export function useSpecificTargetsAll() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/specific-targets/autocomplete/full/all',
  });
}

export function useSpecificTargetsAllAuto() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/specific-targets/autocomplete/all',
  });
}

export function useNotices(activity?: Activity) {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/notices/autocomplete/full',
    apiUrl: '/api/ks/notices',
    params:
      activity != undefined
        ? {
            filters: [
              {
                field: 'activities.id',
                operation: ApiFilterOperation.EQ,
                value: activity.id,
              },
            ],
          }
        : undefined,
  });
}

export function useNoticesAll() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/notices/autocomplete/full/all',
  });
}

export function useNoticesAllAuto() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/notices/autocomplete/all',
  });
}

export function useActivities() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/activities/autocomplete/full',
  });
}

export function useActivitiesAll(parent?: Notice) {
  return useListSource<DictionaryAutocomplete>({
    url:
      parent != null
        ? `/api/ks/notices/${parent.id}/activities/autocomplete/full/all`
        : '/api/ks/activities/autocomplete/full/all',
  });
}

export function useActivitiesAllAuto(parent?: Notice) {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url:
      parent != null
        ? `/api/ks/notices/${parent.id}/activities/autocomplete/all`
        : '/api/ks/activities/autocomplete/all',
  });
}

export function useTags() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/tags/autocomplete',
  });
}

export function useTagsAll() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/tags/autocomplete/all',
  });
}

export function useActiveEditors() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/operators/autocomplete/editors',
  });
}

export function useOperatorsAll() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/operators/autocomplete/all',
  });
}

export function useRequesters() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/requesters/autocomplete',
  });
}

export function useRequestersAll() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/requesters/autocomplete/all',
  });
}

export function useTicketStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'NEW', name: 'Nový' },
    { id: 'PROCESSING', name: 'V řešení' },
    { id: 'RESOLVED', name: 'Zodpovězen' },
    { id: 'RETURNED', name: 'Vrácen k doplnění' },
  ]);
}

export function useConsultants(ticket: Ticket | null) {
  return useStaticListSource<Operator>(ticket?.consultants ?? []);
}

export function useTicketCommentInternal() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'internal', name: 'Interní' },
    { id: 'external', name: 'Externí' },
  ]);
}

export function usePermissions() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: Permissions.ADMINISTRATOR, name: 'Administrátor' },
    { id: Permissions.EDITOR, name: 'Editor' },
    { id: Permissions.OPERATOR, name: 'Operátor' },
    { id: Permissions.REQUESTER, name: 'Tazatel' },
  ]);
}

export function useExportTemplates() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/export/templates/autocomplete',
  });
}

export function useExportTemplatesAll() {
  return useAutocompleteSource<DictionaryAutocomplete>({
    url: '/api/ks/export/templates/autocomplete/all',
  });
}
