import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSelect, FormPanel } from '@eas/common-web';
import { useSpecificTargets, useActivities } from '../operator-api';

export function NoticesFields() {
  const intl = useIntl();
  const specificTargets = useSpecificTargets();
  const activities = useActivities();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="KS_O_NOTICES_FIELD_PANEL_TITLE"
          defaultMessage="Výzva"
        />
      }
    >
      <FormSelect
        name="parent"
        required
        label={
          <FormattedMessage
            id="KS_O_NOTICES_FIELD_LABEL_PARENT"
            defaultMessage="Specifický cíl"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_NOTICES_FIELD_HELP_PARENT',
          defaultMessage: ' ',
        })}
        source={specificTargets}
        tooltipMapper={(o) => o.name}
      />
      <FormSelect
        name="activities"
        label={
          <FormattedMessage
            id="KS_O_NOTICES_FIELD_LABEL_ACTIVITIES"
            defaultMessage="Aktivity"
          />
        }
        helpLabel={intl.formatMessage({
          id: 'KS_O_NOTICES_FIELD_HELP_PARENT',
          defaultMessage: ' ',
        })}
        source={activities}
        tooltipMapper={(o) => o.name}
        multiple={true}
      />
    </FormPanel>
  );
}
