import React, { useContext, Context } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AddBoxSharpIcon from '@material-ui/icons/AddBoxSharp';
import {
  FormFileTable,
  FormTextArea,
  FormSelect,
  FormTextField,
  FormCheckbox,
  FormAutocomplete,
  FormPanel,
  DetailContext,
  Button,
  DetailMode,
  useFormSelector,
  FormContext,
  UserContext,
  AppSettingsContext,
  ContextHelp,
  FormDateTimeField,
  HistoryTable,
  FormRichEditor,
} from '@eas/common-web';
import { Ticket, KsAppSettings } from '../../../common/models';
import { Permissions } from '../../../common/permissions';
import {
  useProjectPhases,
  useRegions,
  useSpecificTargets,
  useActivities,
  useNotices,
  useTags,
  useOrganisationTypes,
  useRequesters,
  useTicketStates,
  useActiveEditors,
} from '../operator-api';
import { TicketComments } from './comments/ticket-comments';
import { NewRequesterDialog } from './tickets-new-requester-dialog';
import { useTicketFields } from './tickets-fields-hook';

export function TicketFields() {
  const intl = useIntl();
  const { newRequesterDialog, handleNewRequester } = useTicketFields();
  const { source, mode } = useContext(DetailContext);
  const { setFieldValue, getFieldValues } = useContext(FormContext);
  const { hasPermission } = useContext(UserContext);
  const { settings } = useContext(
    (AppSettingsContext as unknown) as Context<
      AppSettingsContext<KsAppSettings>
    >
  );
  const fields = settings.fields ?? [];
  const ticket: Ticket | null = source.data;

  const activity = useFormSelector((data: Ticket) => data.activity);
  const parent = useFormSelector((data: Ticket) => data.parent);
  const formatting = useFormSelector((data: Ticket) => data.formatting);

  const organisationTypes = useOrganisationTypes();
  const projectPhases = useProjectPhases();
  const regions = useRegions();
  const specificTargets = useSpecificTargets();
  const notices = useNotices(activity);
  const activities = useActivities();
  const tags = useTags();
  const operators = useActiveEditors();
  const requesters = useRequesters();
  const states = useTicketStates();

  const isAdministrator = hasPermission(Permissions.ADMINISTRATOR);
  const isEditor = hasPermission(Permissions.EDITOR);

  const allowEdit = isAdministrator || (isEditor && mode === DetailMode.NEW);

  return (
    <>
      {parent != null && (
        <FormPanel
          label={
            <FormattedMessage
              id="KS_O_TICKETS_PARENT_PANEL_TITLE"
              defaultMessage="Původní Dotaz"
            />
          }
        >
          <FormTextField
            name="parent.identifier"
            disabled
            label={
              <FormattedMessage
                id="KS_O_TICKETS_FIELD_LABEL_PARENT_IDENTIFIER"
                defaultMessage="Číslo"
              />
            }
            helpLabel={intl.formatMessage({
              id: 'KS_O_TICKETS_FIELD_HELP_PARENT_IDENTIFIER',
              defaultMessage: ' ',
            })}
          />
          <FormTextArea
            name="parent.question"
            disabled
            maxRows={25}
            label={
              <FormattedMessage
                id="KS_O_TICKETS_FIELD_LABEL_PARENT_QUESTION"
                defaultMessage="Otázka"
              />
            }
            helpLabel={intl.formatMessage({
              id: 'KS_O_TICKETS_FIELD_HELP_PARENT_QUESTION',
              defaultMessage: ' ',
            })}
          />
          {parent.formatting ? (
            <FormRichEditor
              name="parent.answer"
              disabled
              label={
                <FormattedMessage
                  id="KS_O_TICKETS_FIELD_LABEL_PARENT_ANSWER"
                  defaultMessage="Odpověď"
                />
              }
              helpLabel={intl.formatMessage({
                id: 'KS_O_TICKETS_FIELD_HELP_PARENT_ANSWER',
                defaultMessage: ' ',
              })}
            />
          ) : (
            <FormTextArea
              name="parent.answer"
              disabled
              maxRows={25}
              label={
                <FormattedMessage
                  id="KS_O_TICKETS_FIELD_LABEL_PARENT_ANSWER"
                  defaultMessage="Odpověď"
                />
              }
              helpLabel={intl.formatMessage({
                id: 'KS_O_TICKETS_FIELD_HELP_PARENT_ANSWER',
                defaultMessage: ' ',
              })}
            />
          )}
        </FormPanel>
      )}
      <FormPanel
        label={
          <FormattedMessage
            id="KS_O_TICKETS_FIELD_PANEL_TITLE"
            defaultMessage="Dotaz"
          />
        }
      >
        <FormTextField
          name="identifier"
          disabled
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_IDENTIFIER"
              defaultMessage="Číslo"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_IDENTIFIER',
            defaultMessage: ' ',
          })}
        />
        <FormTextArea
          name="question"
          required
          disabled={!allowEdit}
          maxRows={25}
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_QUESTION"
              defaultMessage="Otázka"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_QUESTION',
            defaultMessage: ' ',
          })}
        />
        {formatting ? (
          <FormRichEditor
            name="answer"
            disabled={!allowEdit}
            label={
              <FormattedMessage
                id="KS_O_TICKETS_FIELD_LABEL_ANSWER"
                defaultMessage="Odpověď"
              />
            }
            helpLabel={intl.formatMessage({
              id: 'KS_O_TICKETS_FIELD_HELP_ANSWER',
              defaultMessage: ' ',
            })}
          />
        ) : (
          <FormTextArea
            name="answer"
            disabled={!allowEdit}
            maxRows={25}
            label={
              <FormattedMessage
                id="KS_O_TICKETS_FIELD_LABEL_ANSWER"
                defaultMessage="Odpověď"
              />
            }
            helpLabel={intl.formatMessage({
              id: 'KS_O_TICKETS_FIELD_HELP_ANSWER',
              defaultMessage: ' ',
            })}
          />
        )}

        {formatting ? (
          <FormRichEditor
            name="note"
            label={
              <FormattedMessage
                id="KS_O_TICKETS_FIELD_LABEL_NOTE"
                defaultMessage="Poznámka"
              />
            }
            helpLabel={intl.formatMessage({
              id: 'KS_O_TICKETS_FIELD_HELP_NOTE',
              defaultMessage: ' ',
            })}
          />
        ) : (
          <FormTextArea
            name="note"
            maxRows={25}
            label={
              <FormattedMessage
                id="KS_O_TICKETS_FIELD_LABEL_NOTE"
                defaultMessage="Poznámka"
              />
            }
            helpLabel={intl.formatMessage({
              id: 'KS_O_TICKETS_FIELD_HELP_NOTE',
              defaultMessage: ' ',
            })}
          />
        )}

        <FormSelect
          name="state"
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_STATE"
              defaultMessage="Stav"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_STATE',
            defaultMessage: ' ',
          })}
          source={states}
          tooltipMapper={(o) => o.name}
          valueIsId={true}
          disabled={!isAdministrator}
        />
        <FormDateTimeField
          disabled
          name="resolveDate"
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_RESOLVE_DATE"
              defaultMessage="Čas odpovědi"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKET_FIELD_HELP_RESOLVE_DATE',
            defaultMessage: ' ',
          })}
        />
        <FormTextField
          name="resolveName"
          disabled={!isAdministrator}
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_RESOLVE_NAME"
              defaultMessage="Autor odpovědi"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_RESOLVE_NAME',
            defaultMessage: ' ',
          })}
        />
        <FormSelect
          name="organisationType"
          disabled={!allowEdit}
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_ORGANISATION_TYPE"
              defaultMessage="Typ organizace"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_ORGANISATION_TYPE',
            defaultMessage: ' ',
          })}
          source={organisationTypes}
          tooltipMapper={(o) => o.name}
        />
        <FormAutocomplete
          name="requester"
          disabled={!allowEdit}
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_REQUESTER"
              defaultMessage="Tazatel"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_REQUESTER',
            defaultMessage: ' ',
          })}
          source={requesters}
          tooltipMapper={(o) => o.name}
          after={
            <>
              {(mode === DetailMode.NEW || mode === DetailMode.EDIT) && (
                <Button
                  label={<AddBoxSharpIcon />}
                  onClick={handleNewRequester}
                  tooltip={
                    <FormattedMessage
                      id="KS_O_TICKETS_TOOLTIP_NEW_REQUESTER"
                      defaultMessage="Otevře okno pro založení nového tazatele"
                    />
                  }
                />
              )}
            </>
          }
        />
        <FormTextField
          name="requester.phone"
          disabled={true}
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_REQUESTER_PHONE"
              defaultMessage="Telefon tazatele"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_REQUESTER_PHONE',
            defaultMessage: ' ',
          })}
        />
        <FormTextField
          name="applicantName"
          disabled={!allowEdit}
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_APPLICANT_NAME"
              defaultMessage="Název žadatele"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_APPLICANT_NAME',
            defaultMessage: ' ',
          })}
        />
        <FormTextField
          name="applicantIc"
          disabled={!allowEdit}
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_APPLICANT_IC"
              defaultMessage="IČ žadatele"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_APPLICANT_IC',
            defaultMessage: ' ',
          })}
        />
        <FormAutocomplete
          name="owner"
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_OWNER"
              defaultMessage="Řešitel"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_OWNER',
            defaultMessage: ' ',
          })}
          disabled={!isAdministrator}
          source={operators}
          tooltipMapper={(o) => o.name}
        />
        <FormCheckbox
          name="consulted"
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_CONSULTED"
              defaultMessage="Konzultován"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_CONSULTED',
            defaultMessage: ' ',
          })}
          disabled={!isAdministrator}
        />
        <FormAutocomplete
          name="consultants"
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_CONSULTANTS"
              defaultMessage="Konzultanti"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_CONSULTANTS',
            defaultMessage: ' ',
          })}
          multiple={true}
          source={operators}
          tooltipMapper={(o) => o.name}
          disabled={!isAdministrator}
        />
        <FormTextField
          name="projectName"
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_PROJECT_NAME"
              defaultMessage="Název projektu"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_PROJECT_NAME',
            defaultMessage: ' ',
          })}
        />
        <FormTextField
          name="projectNumber"
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_PROJECT_NUMBER"
              defaultMessage="Číslo projektu"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_PROJECT_NUMBER',
            defaultMessage: ' ',
          })}
        />
        <FormSelect
          name="projectPhase"
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_PROJECT_PHASE"
              defaultMessage="Dotaz k fázi projektu"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_PROJECT_PHASE',
            defaultMessage: ' ',
          })}
          source={projectPhases}
          tooltipMapper={(o) => o.name}
        />
        <FormSelect
          name="region"
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_REGION"
              defaultMessage="Kraj realizace"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_REGION',
            defaultMessage: ' ',
          })}
          source={regions}
          tooltipMapper={(o) => o.name}
        />
        <FormTextField
          name="place"
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_PLACE"
              defaultMessage="Místo realizace"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_PLACE',
            defaultMessage: ' ',
          })}
        />
        <FormSelect
          name="activity"
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_ACTIVITY"
              defaultMessage="Aktivita"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_ACTIVITY',
            defaultMessage: ' ',
          })}
          source={activities}
          tooltipMapper={(o) => o.name}
          notifyChange={() => {
            setFieldValue('notice', undefined);
            setFieldValue('specificTarget', undefined);
          }}
        />

        <FormSelect
          name="notice"
          disabled={activity == null}
          required
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_NOTICE"
              defaultMessage="Výzva"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_NOTICE',
            defaultMessage: ' ',
          })}
          source={notices}
          tooltipMapper={(o) => o.name}
          notifyChange={() => {
            requestAnimationFrame(() => {
              const notice = getFieldValues()['notice'];
              setFieldValue('specificTarget', notice?.parent);
            });
          }}
        />
        <FormSelect
          name="specificTarget"
          disabled
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_SPECIFIC_TARGET"
              defaultMessage="Specifický cíl"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_SPECIFIC_TARGET',
            defaultMessage: ' ',
          })}
          source={specificTargets}
          tooltipMapper={(o) => o.name}
        />
        <FormAutocomplete
          name="tags"
          label={
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_TAGS"
              defaultMessage="Klíčová slova"
            />
          }
          helpLabel={intl.formatMessage({
            id: 'KS_O_TICKETS_FIELD_HELP_TAGS',
            defaultMessage: ' ',
          })}
          source={tags}
          tooltipMapper={(o) => o.name}
          multiple={true}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="KS_O_TICKETS_FIELD_LABEL_CUSTOM_FIELDS"
            defaultMessage="Volitelné pole"
          />
        }
      >
        {fields.map(
          (field, index) =>
            field != null &&
            field.enabled && (
              <FormTextField
                key={`field${index + 1}`}
                name={`field${index + 1}`}
                required={field.required}
                label={
                  field.labelId && (
                    <FormattedMessage
                      id={field.labelId}
                      defaultMessage={field.labelDefault}
                    />
                  )
                }
                helpLabel={
                  field.helpId &&
                  intl.formatMessage({
                    id: field.helpId,
                    defaultMessage: field.helpDefault,
                  })
                }
              />
            )
        )}
      </FormPanel>
      <FormPanel
        label={
          <>
            <FormattedMessage
              id="KS_O_TICKETS_FIELD_LABEL_FILES"
              defaultMessage="Soubory"
            />
            <ContextHelp
              type="HOVER"
              label={intl.formatMessage({
                id: 'KS_O_TICKETS_FIELD_HELP_FILES',
                defaultMessage:
                  'Maximální velikost přílohy je 5MB a podporované soubory jsou *.docx, *.xlsx, *.pdf, *.png a *.jpg',
              })}
            />
          </>
        }
      >
        <FormFileTable name="files" labelOptions={{ hide: true }} />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="KS_O_TICKETS_HISTORY_PANEL_TITLE"
            defaultMessage="Historie"
          />
        }
      >
        {ticket !== null && <HistoryTable id={ticket.id} />}
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="KS_O_TICKETS_COMMENTS_PANEL_TITLE"
            defaultMessage="Komentáře"
          />
        }
      >
        {ticket !== null && <TicketComments id={ticket.id} ticket={ticket} />}
      </FormPanel>
      <NewRequesterDialog ref={newRequesterDialog} />
    </>
  );
}
