import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  FilesProvider,
  LocaleProvider,
  SnackbarProvider,
  NavigationProvider,
  UserProvider,
  LocaleName,
  InactivityProvider,
  ExportProvider,
  FormAnchorProvider,
  AppSettingsProvider,
  Authorized,
  ValidationProvider,
  HelpProvider,
  HistoryProvider,
  PromptProvider,
  ScheduleProvider,
} from '@eas/common-web';
import { OperatorApp } from './operator/operator-app';
import { PublicApp } from './public/public-app';
import { RequesterApp } from './requester/requester-app';
import { Faq } from './public/faq/faq';
import { Logout } from './public/logout/logout';
import { useExportTags } from './operator/export/export-api';
import { ThemeProvider } from '../common/theme/theme-provider';

export function App() {
  const primary = {
    light: '#577dc1',
    main: '#1F5190',
    dark: '#002962',
  };

  /*const secondary = {
    light: '#D54945',
    main: '#D54945',
    dark: '#D54945',
  };*/
  const secondary = {
    light: '#F11B26',
    main: '#F11B26',
    dark: '#F11B26',
  };

  const editing = '#FEFFB2';
  const highlight = '#fefeb28a';

  const exportTags = useExportTags();

  return (
    <HelpProvider formContextHelpType="HOVER">
      <ThemeProvider
        primary={primary}
        secondary={secondary}
        editing={editing}
        highlight={highlight}
        radius={5}
      >
        <LocaleProvider
          defaultLocale={LocaleName.cs}
          translationsUrl="/api/ks/translations"
        >
          <PromptProvider>
            <ValidationProvider>
              <SnackbarProvider timeout={3000}>
                <AppSettingsProvider url="/api/ks/app-settings">
                  <FilesProvider
                    url="/api/ks/files"
                    maxUploadSize={5 * 1024 * 1024}
                  >
                    <HistoryProvider url="/api/ks/history">
                      <FormAnchorProvider>
                        <ExportProvider url="/api/ks/export" tags={exportTags}>
                          <ScheduleProvider
                            jobUrl="/api/ks/schedule/jobs"
                            runUrl="/api/ks/schedule/runs"
                          >
                            <UserProvider
                              meUrl="/api/ks/me"
                              logoutUrl="/api/ks/logout"
                            >
                              <InactivityProvider timeout={3600 * 8}>
                                <BrowserRouter>
                                  <NavigationProvider>
                                    <Switch>
                                      <Route path="/operator">
                                        <OperatorApp />
                                      </Route>
                                      <Route exact path="/operator-external">
                                        <Redirect to="/operator/tickets" />
                                      </Route>
                                      <Route path="/requester">
                                        <RequesterApp />
                                      </Route>
                                      <Route
                                        exact
                                        path="/logout"
                                        component={Logout}
                                      />
                                      <Route
                                        exact
                                        path="/faq"
                                        component={Faq}
                                      />
                                      <Route path="/">
                                        <Authorized permission="OPERATOR">
                                          <Redirect to="/operator" />
                                        </Authorized>
                                        <Authorized permission="REQUESTER">
                                          <Redirect to="/requester" />
                                        </Authorized>
                                        <PublicApp />
                                      </Route>
                                    </Switch>
                                  </NavigationProvider>
                                </BrowserRouter>
                              </InactivityProvider>
                            </UserProvider>
                          </ScheduleProvider>
                        </ExportProvider>
                      </FormAnchorProvider>
                    </HistoryProvider>
                  </FilesProvider>
                </AppSettingsProvider>
              </SnackbarProvider>
            </ValidationProvider>
          </PromptProvider>
        </LocaleProvider>
      </ThemeProvider>
    </HelpProvider>
  );
}
