import { useIntl } from 'react-intl';
import { TableColumn, TableCells, TableFilterCells } from '@eas/common-web';
import { SpecificTarget } from '../../../common/models';
import { usePriorityTargetsAll } from '../operator-api';

export function useColumns(): TableColumn<SpecificTarget>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'parent',
      sortkey: 'parent.name',
      filterkey: 'parent.id',
      name: intl.formatMessage({
        id: 'KS_O_SPECIFIC_TARGETS_COLUMN_PARENT',
        defaultMessage: 'Prioritní osa',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        usePriorityTargetsAll
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
  ];
}
