import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Form, FormTextField, FormSubmitButton } from '@eas/common-web';
import { PublicPage } from '../../../components/public-page/public-page';
import { PasswordResetRequest } from '../../../common/models';
import { usePasswordResetRequest } from './password-reset-request-hook';
import { KsPublicFormItem } from '../components/ks-public-form-item/ks-public-form-item';

export function PasswordResetRequestPage({
  isOperator = false,
}: {
  isOperator?: boolean;
}) {
  const intl = useIntl();
  const {
    loading,
    formRef,
    validationSchema,
    handleSubmit,
  } = usePasswordResetRequest(isOperator);

  return (
    <PublicPage
      title={
        <FormattedMessage
          id="KS_P_PW_RESET_REQUEST_TITLE"
          defaultMessage="Obnova hesla"
        />
      }
    >
      <Typography>
        <FormattedMessage
          id="KS_P_PW_RESET_REQUEST_SUBTITLE"
          defaultMessage="Zadejte email, který jste použili při registraci:"
        />
      </Typography>
      <Box height={50} />
      <Form<PasswordResetRequest>
        editing={!loading}
        initialValues={{} as any}
        onSubmit={handleSubmit}
        ref={formRef}
        validationSchema={validationSchema as any}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <KsPublicFormItem
              required
              name="email"
              helpLabel={intl.formatMessage({
                id: 'KS_P_PW_RESET_REQUEST_FIELD_HELP_EMAIL',
                defaultMessage: ' ',
              })}
              label={
                <FormattedMessage
                  id="KS_P_PW_RESET_REQUEST_FIELD_LABEL_EMAIL"
                  defaultMessage="Email"
                />
              }
            >
              <FormTextField required name="email" />
            </KsPublicFormItem>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box display="flex" flexDirection="row-reverse">
              <FormSubmitButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                disableElevation
                startIcon={
                  loading && <CircularProgress size="20px" color="inherit" />
                }
              >
                <FormattedMessage
                  id="KS_P_PW_RESET_REQUEST_BTN_SEND"
                  defaultMessage="Poslat"
                />
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </PublicPage>
  );
}
