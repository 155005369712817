import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { PublicPage } from '../../../components/public-page/public-page';
import { useRegistrationConfirm } from './registration-confirm-hook';

export function RegistrationConfirm() {
  const { secret, overdue, resend } = useRegistrationConfirm();

  return (
    <PublicPage
      title={
        <FormattedMessage
          id="KS_P_REGISTRATION_CONFIRM_TITLE"
          defaultMessage="Registrace"
        />
      }
    >
      {secret !== undefined ? (
        <>
          {overdue ? (
            <Typography>
              <FormattedMessage
                id="KS_P_REGISTRATION_CONFIRM_SUBTITLE_OVERDUE"
                defaultMessage="Registrace nebyla potvrzena včas. Kliknutím na následující odkaz Vám bude opětovně zaslán potvrzující email: "
              />
              <a href="#" onClick={resend}>
                <FormattedMessage
                  id="KS_P_REGISTRATION_CONFIRM_SUBTITLE_OVERDUE_LINK"
                  defaultMessage="Odkaz"
                />
              </a>
            </Typography>
          ) : (
            <Typography>
              <FormattedMessage
                id="KS_P_REGISTRATION_CONFIRM_SUBTITLE"
                defaultMessage="Potvrzuji registraci..."
              />
            </Typography>
          )}
        </>
      ) : (
        <Typography>
          <FormattedMessage
            id="KS_P_REGISTRATION_CONFIRM_SUBTITLE_ERROR"
            defaultMessage="Chyba registrace"
          />
        </Typography>
      )}
    </PublicPage>
  );
}
