import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 114,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  name: {
    fontSize: '1.5rem',
    marginBottom: 5,
    textAlign: 'right',
    marginRight: 10,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 18,
    color: theme.palette.primary.main,

    '&:hover': {},
  },
}));
