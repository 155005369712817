import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence, useDictionaryEvidence } from '@eas/common-web';
import { ExportTags } from '../../../common/export-tags';
import { Activity } from '../../../common/models';
import { initNewDisabledItem } from '../../../utils/dictionary-item';
import { useValidationSchema } from './activities-schema';

export function OperatorActivities() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const evidence = useDictionaryEvidence<Activity>({
    identifier: 'ACTIVITIES',
    apiProps: {
      url: '/api/ks/activities',
    },
    tableProps: {
      tableName: intl.formatMessage({
        id: 'KS_O_ACTIVITIES_TABLE_TITLE',
        defaultMessage: 'Aktivity',
      }),
      reportTag: ExportTags.ACTIVITY_GRID,
    },
    detailProps: {
      validationSchema,
      initNewItem: initNewDisabledItem,
    },
  });

  return <Evidence {...evidence} />;
}
