import * as Yup from 'yup';
import { Registration } from '../../../common/models';
import { useDefaultValidationMessages } from '@eas/common-web';

export function useValidationSchema() {
  const { meetsFormat } = useDefaultValidationMessages();

  return Yup.object<Registration>().shape({
    firstName: Yup.string().nullable().required(),
    lastName: Yup.string().nullable().required(),
    email: Yup.string().nullable().required(),
    organisationName: Yup.string().nullable().required(),
    organisationIc: Yup.string().nullable().required(),
    phone: Yup.string()
      .nullable()
      .required()
      .matches(
        /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
        meetsFormat
      ),
  });
}
