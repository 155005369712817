import { abortableFetch } from '@eas/common-web';

/**
 * Calls password reset API.
 *
 */
export function sendPasswordReset(secret: string, password: string) {
  return abortableFetch('/api/ks/public/password-reset/confirm', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      secret,
      password,
    }),
  });
}
