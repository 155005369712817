import { useScrollableSource, PersonalEvent } from '@eas/common-web';
import { useColumns } from './personal-events-columns';

export function usePersonalEvents() {
  const source = useScrollableSource<PersonalEvent>({
    url: '/api/ks/personal-events/list/mine',
  });
  const columns = useColumns();

  return {
    source,
    columns,
  };
}
