import {
  useListSource,
  DictionaryAutocomplete,
  useStaticListSource,
} from '@eas/common-web';
import { Activity } from '../../common/models';

export function useOrganisationTypes() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/organisation-types/all',
  });
}

export function useProjectPhases() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/project-phases/all',
  });
}

export function useRegions() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/regions/all',
  });
}

export function usePriorityTargets() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/priority-targets/all',
  });
}

export function useSpecificTargets() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/specific-targets/all',
  });
}

export function useNotices(activity?: Activity) {
  return useListSource<DictionaryAutocomplete>({
    url:
      activity != null
        ? `/api/ks/public/dictionary/notices/all/activity/${activity.id}`
        : '/api/ks/public/dictionary/notices/all',
    apiUrl: '/api/ks/public/dictionary/notices',
  });
}

export function useActivities() {
  return useListSource<DictionaryAutocomplete>({
    url: '/api/ks/public/dictionary/activities/all',
  });
}

export function useTicketStates() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'NEW', name: 'Nový' },
    { id: 'PROCESSING', name: 'V řešení' },
    { id: 'RESOLVED', name: 'Zodpovězen' },
    { id: 'RETURNED', name: 'Vrácen k doplnění' },
  ]);
}
