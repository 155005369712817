import { useIntl } from 'react-intl';
import { TableColumn, TableCells, TableFilterCells } from '@eas/common-web';
import { Notice } from '../../../common/models';
import { useSpecificTargetsAll, useActivitiesAll } from '../operator-api';

export function useColumns(): TableColumn<Notice>[] {
  const intl = useIntl();
  return [
    {
      datakey: 'parent',
      sortkey: 'parent.name',
      filterkey: 'parent.id',
      name: intl.formatMessage({
        id: 'KS_O_NOTICES_COLUMN_SPECIFIC_TARGET',
        defaultMessage: 'Specifický cíl',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useSpecificTargetsAll
      ),
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'activities',
      sortkey: 'activities.concatenated',
      filterkey: 'activities.id',
      name: intl.formatMessage({
        id: 'KS_O_NOTICES_COLUMN_ACTIVITIES',
        defaultMessage: 'Aktivity',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useActivitiesAll
      ),
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      sortable: true,
      filterable: true,
    },
  ];
}
