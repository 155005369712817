import { useParams } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';
import { useIntl } from 'react-intl';
import { useState, useRef, useContext } from 'react';
import * as Yup from 'yup';
import {
  AbortableFetch,
  FormHandle,
  abortableFetch,
  useEventCallback,
  SnackbarContext,
  NavigationContext,
  SnackbarVariant,
  useDefaultValidationMessages,
} from '@eas/common-web';
import { PasswordResetComplete } from '../../../common/models';
import { sendPasswordReset } from './password-reset-api';

export function usePasswordReset() {
  const intl = useIntl();
  const { isTheSame, meetsRules } = useDefaultValidationMessages();
  const { showSnackbar } = useContext(SnackbarContext);
  const { navigate } = useContext(NavigationContext);

  const [loading, setLoading] = useState(false);
  const fetch = useRef<AbortableFetch | null>(null);
  const formRef = useRef<FormHandle<PasswordResetComplete>>(null);

  const { secret } = useParams();

  const validationSchema = Yup.object<PasswordResetComplete>().shape({
    newPassword: Yup.string()
      .nullable()
      .required()
      .test('password_test', meetsRules, async function (value) {
        if (Yup.string().nullable().required().validateSync(value)) {
          const fetch = validatePassword(value!);
          return await fetch.json();
        } else {
          return true;
        }
      }),
    newPasswordAgain: Yup.string()
      .nullable()
      .required()
      .oneOf([Yup.ref('newPassword')], isTheSame),
  });

  const submitPasswordReset = useEventCallback(
    async (item: PasswordResetComplete) => {
      try {
        setLoading(true);
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = sendPasswordReset(secret, item.newPassword);

        await fetch.current.none();

        unstable_batchedUpdates(() => {
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);

        if (err.name !== 'AbortError') {
          const message = intl.formatMessage(
            {
              id: 'KS_P_PW_RESET_MSG_ERROR',
              defaultMessage: 'Chyba resetovani hesla: {detail}',
            },
            { detail: err.message }
          );

          showSnackbar(message, SnackbarVariant.ERROR);

          throw err;
        }
        return undefined;
      }
    }
  );

  const handleSubmit = useEventCallback(async (data: PasswordResetComplete) => {
    if (formRef.current != undefined) {
      const errors = await formRef.current.validateForm();

      if (errors.length > 0) {
        const errorMsg = intl.formatMessage({
          id: 'KS_P_PW_RESET_MSG_VALIDATION_ERROR',
          defaultMessage: 'Ve formuláři se nacházejí chyby',
        });

        showSnackbar(errorMsg, SnackbarVariant.ERROR);
        return;
      }
    }

    await submitPasswordReset(data);

    navigate('/password-reset-complete', true);
  });

  return {
    secret,
    loading,
    formRef,
    validationSchema,
    handleSubmit,
  };
}

export function validatePassword(password: string) {
  return abortableFetch('/api/ks/public/registrations/validate-password', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: password,
  });
}
