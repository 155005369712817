import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Panel,
  FormTextArea,
  useFormSelector,
  FormTextField,
  FormRichEditor,
} from '@eas/common-web';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { KsPublicFormItem } from '../../../public/components/ks-public-form-item/ks-public-form-item';
import { TicketExternalCreate } from '../../../../common/models';

export function ParentPanel() {
  const parent = useFormSelector((data: TicketExternalCreate) => data.parent);

  return (
    <>
      {parent != null && (
        <Panel
          label={
            <FormattedMessage
              id="KS_P_TICKET_PARENT_TITLE"
              defaultMessage="Původní dotaz"
            />
          }
          sideBorder={true}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            padding="16px"
          >
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <KsPublicFormItem
                  name="parent.identifier"
                  label={
                    <FormattedMessage
                      id="KS_R_TICKETS_FIELD_LABEL_PARENT_IDENTIFIER"
                      defaultMessage="Číslo"
                    />
                  }
                >
                  <FormTextField disabled name="parent.identifier" />
                </KsPublicFormItem>
              </Grid>
              <Grid item xs={12} sm={12}>
                <KsPublicFormItem
                  name="parent.question"
                  label={
                    <FormattedMessage
                      id="KS_R_TICKETS_FIELD_LABEL_PARENT_QUESTION"
                      defaultMessage="Dotaz"
                    />
                  }
                >
                  <FormTextArea
                    disabled
                    maxRows={25}
                    required
                    name="parent.question"
                  />
                </KsPublicFormItem>
              </Grid>
              <Grid item xs={12} sm={12}>
                <KsPublicFormItem
                  name="parent.answer"
                  label={
                    <FormattedMessage
                      id="KS_R_TICKETS_FIELD_LABEL_PARENT_ANSWER"
                      defaultMessage="Odpověď"
                    />
                  }
                >
                  {parent.formatting ? (
                    <FormRichEditor disabled required name="parent.answer" />
                  ) : (
                    <FormTextArea
                      disabled
                      maxRows={25}
                      required
                      name="parent.answer"
                    />
                  )}
                </KsPublicFormItem>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      )}
    </>
  );
}
