import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Form } from '@eas/common-web';
import { PublicPage } from '../../../../components/public-page/public-page';
import { useTicketsEdit } from './tickets-edit-hook';
import { TicketExternalDetail } from '../../../../common/models';
import { AmendDialog } from '../panels/comments/ticket-amend-dialog';
import { RequesterTicketsEditFields } from './tickets-edit-fields';

export function RequesterTicketsEdit() {
  const { id } = useParams();

  const {
    loading,
    editing,
    handleSubmit,
    handleCommentClick,
    handleEditClick,
    handleAmendClick,
    handleFollowupClick,
    handleOrgTypeChange,
    formRef,
    validationSchema,
    source,
    commentsSource,
    commentDialog,
    amendDialog,
  } = useTicketsEdit(id);

  return (
    <PublicPage
      editHightlight={true}
      title={
        <FormattedMessage id="KS_R_TICKETS_EDIT_TITLE" defaultMessage="Dotaz" />
      }
      loading={loading}
    >
      <Form<TicketExternalDetail>
        editing={editing && !loading}
        initialValues={{} as any}
        onSubmit={handleSubmit}
        ref={formRef}
        validationSchema={validationSchema as any}
      >
        <RequesterTicketsEditFields
          id={id}
          loading={loading}
          editing={editing}
          source={source}
          commentDialog={commentDialog}
          commentsSource={commentsSource}
          handleOrgTypeChange={handleOrgTypeChange}
          handleCommentClick={handleCommentClick}
          handleEditClick={handleEditClick}
          handleAmendClick={handleAmendClick}
          handleFollowupClick={handleFollowupClick}
        />
      </Form>
      <AmendDialog
        ref={amendDialog}
        id={id}
        source={source}
        commentsSource={commentsSource}
      />
    </PublicPage>
  );
}
