import { ChangeEvent, useEffect, useRef, useContext } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import {
  useEventCallback,
  FormHandle,
  useLocalStorage,
  useScrollableSource,
  Params,
  Filter,
  DictionaryObject,
  ApiFilterOperation,
  UserContext,
} from '@eas/common-web';
import { FaqExternalDetail } from '../../../common/models';
import { FiltersDto } from './faq-types';
import { usePrintHook } from '../../requester/faqs/faqs-print-hook';
import { compact } from 'lodash';

export function useFaq() {
  const { user } = useContext(UserContext);

  const source = useScrollableSource<FaqExternalDetail>({
    url: '/api/ks/public/faq/list',
  });

  const [searchText, setSearchTextChange] = useLocalStorage(
    'faq-search-text',
    ''
  );
  const [searchQuery, setSearchQueryInternal] = useLocalStorage(
    'faq-search-query',
    ''
  );

  const [savedFiltersDto, saveFiltersDto] = useLocalStorage<FiltersDto>(
    'faq-search-filters-2',
    {}
  );
  const [setSearchQuery] = useDebouncedCallback(setSearchQueryInternal, 500);
  const formRef = useRef<FormHandle<FiltersDto>>(null);

  const handleSearchTextChange = useEventCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      setSearchTextChange(value);
      setSearchQuery(value);
    }
  );

  const handleClear = useEventCallback(() => {
    setSearchTextChange('');
    setSearchQuery('');
  });

  const handleClearFilters = useEventCallback(() => {
    formRef.current?.setFieldValue('specificTargets', []);
    formRef.current?.setFieldValue('notices', []);
    formRef.current?.setFieldValue('projectPhases', []);
    formRef.current?.setFieldValue('activities', []);
    formRef.current?.setFieldValue('tags', []);

    requestAnimationFrame(() => {
      handleChange();
    });
  });

  const updateSource = useEventCallback((formDto: FiltersDto) => {
    const params = constructParams(formDto, searchQuery);
    source.setParams(params);

    source.reset();
    source.loadMore();
  });

  const handleChange = useEventCallback(() => {
    if (formRef.current != null) {
      const formDto = formRef.current.getFieldValues();
      saveFiltersDto(formDto);
      updateSource(formDto);
    }
  });

  const { print, printing } = usePrintHook(
    constructParams(savedFiltersDto, searchQuery)
  );

  useEffect(() => {
    updateSource(savedFiltersDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    try {
      // load filters from localstorage on mount
      formRef.current?.setFieldValues(savedFiltersDto);
      updateSource(savedFiltersDto);
    } catch (e) {
      console.warn('Wrong filters state found, reseting');
      formRef.current?.setFieldValues({});
      updateSource({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    searchText,
    handleSearchTextChange,
    handleChange,
    handleClear,
    handleClearFilters,
    handleClickPrint: print,
    printing,
    showPrintButton: user !== undefined,
    source,
    formRef,
  };
}

function constructCombinedFilter(
  field: string,
  objects?: DictionaryObject[]
): Filter | undefined {
  const objectFilters: Filter[] = (objects ?? [])
    .map((o) => o.id)
    .map((id) => ({
      field: `${field}.id`,
      value: id,
      operation: ApiFilterOperation.EQ,
    }));

  return objectFilters.length > 0
    ? { operation: ApiFilterOperation.OR, filters: objectFilters }
    : undefined;
}

function constructParams(formDto: FiltersDto, searchQuery: string): Params {
  const projectPhasesFilter = constructCombinedFilter(
    'projectPhases',
    formDto.projectPhases
  );

  const specificTargetsFilter = constructCombinedFilter(
    'specificTargets',
    formDto.specificTargets
  );

  const activitiesFilter = constructCombinedFilter(
    'activities',
    formDto.activities
  );

  const noticesFilter = constructCombinedFilter('notices', formDto.notices);

  const tagsFilter = constructCombinedFilter('tags', formDto.tags);

  const queryFilter: Filter | undefined =
    searchQuery != null && searchQuery.trim().length > 0
      ? { operation: ApiFilterOperation.FTX, value: searchQuery }
      : undefined;

  return {
    filters: compact([
      projectPhasesFilter,
      specificTargetsFilter,
      activitiesFilter,
      noticesFilter,
      tagsFilter,
      queryFilter,
    ]),
  };
}
