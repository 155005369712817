import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    overflow: 'auto' as any,
    overflowY: 'overlay' as any,
    backgroundColor: 'white',
    fontFamily: '"Open Sans"',
    fontSize: 16,

    '& .MuiTypography-root': {
      fontFamily: '"Open Sans"',
    },
    '& .MuiInput-root': {
      fontSize: 16,
    },
    '& .MuiButton-root': {
      borderRadius: 5,
      fontSize: '1.125rem',
      textTransform: 'none',
    },
    '& .MuiButton-containedPrimary': {
      '&:hover,&:focus': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '& .EasInput-root': {},
    '& h1': {
      fontSize: '2.25rem',
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    '& h2': {
      fontSize: '1.5rem',
      color: theme.palette.primary.main,
      fontWeight: 400,
    },
    '& h3': {
      fontSize: '1.125rem',
      color: theme.palette.primary.main,
      fontWeight: 400,
    },
  },
  title: {
    marginTop: 30,
    marginBottom: 60,
  },
  editNotHightlight: {
    '& .MuiInput-root': {
      backgroundColor: 'inherit',
    },
    '& .EasInput-root': {
      backgroundColor: 'inherit',
    },
  },
  selectButton: {
    minWidth: 'auto',
    width: 20,
    paddingLeft: 12,
    paddingRight: 12,
  },
  header: {
    flexGrow: 0,
  },
  body: {
    flexGrow: 1,
    marginBottom: 20,
  },
  footer: {
    flexGrow: 0,
  },
  loadingGrid: {
    position: 'relative',
  },
  loaderWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#e1e2e340',
    zIndex: 1000,
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
}));
